import { useState } from "react";
import { Modal, Button, Image } from "react-bootstrap";

import parse from "html-react-parser";

import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";
import useCookie from "@/hooks/useCookie";

const I18N_SCOPE = "views.dashboard.integrate_reportei.trials_ends_modal";
const TRIAL_MODAL_COOKIE_KEY = "reportei_trials_ends_modal";

const TrialsEndsModalReportei = ({ reporteiMetric, shouldShowModal }) => {
  const [modalStep, setModalStep] = useState(1);
  const [step2Type, setStep2Type] = useState("not_now");
  const [showModal, setShowModal] = useState(true);

  const { value: trialModalCookie, setValue: setTrialModalCookie } = useCookie(TRIAL_MODAL_COOKIE_KEY);

  if (!shouldShowModal || trialModalCookie) return null;

  const handleShowModal = () => {
    setShowModal(false);
    setTrialModalCookie(true);
  };

  const step1 = () => {
    return (
      <Modal
        show={showModal}
        className="modal-blur"
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Body className="p-4 text-center">
          <Image
            src="https://cdn.leadster.com.br/leadster/images/dashboard/reportei/end_trial.png"
            alt="Google Ads"
            rounded
          />

          <h2 className="my-3">{I18n.t(`${I18N_SCOPE}.step_1.title`)}</h2>

          <div className="mb-4 d-flex row justify-content-center gap-1">
            <p className="p-0 m-0 fs-3">
              {parse(I18n.t(`${I18N_SCOPE}.step_1.description_1_html`))}
            </p>
            <p className="p-0 m-0 fs-3">
              {parse(I18n.t(`${I18N_SCOPE}.step_1.description_2_html`))}
            </p>
          </div>

          <div className="d-flex justify-content-between gap-3">
            <Button
              variant="outline-secondary"
              onClick={() => {
                setStep2Type("not_now");
                setModalStep(2);
              }}
            >
              {I18n.t(`${I18N_SCOPE}.step_1.button.not_now`)}
            </Button>
            <a
              href={buildWhatsAppLink({
                number: getCustomVariable("whatsapp_number_sales_1"),
                message: I18n.t("views.leadster_ai.page.trial_alerts.contact_button.chat_default_message"),
              })}
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setStep2Type("contact_us");
                setModalStep(2);
              }}
            >
              <i className="ti ti-brand-whatsapp icon me-2" />
              {I18n.t(`${I18N_SCOPE}.step_1.button.contact_us`)}
            </a>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const step2 = () => {
    return (
      <Modal
        show={true}
        className="modal-blur"
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Body className="p-4 text-center">
          <Image
            src="https://cdn.leadster.com.br/leadster/images/dashboard/reportei/end_trial_2.png"
            alt="Super Dashboard"
            rounded
          />

          <h2 className="my-3">{I18n.t(`${I18N_SCOPE}.step_2.${step2Type === "contact_us" ? 'title_1' : 'title_2'}`)}</h2>

          <div className="mb-4 d-flex row justify-content-center gap-1">
            <p className="p-0 m-0 fs-3">
              {parse(I18n.t(`${I18N_SCOPE}.step_2.${step2Type === "contact_us" ? 'description_1_html' : 'description_2_html'}`))}
            </p>
            <p className="p-0 m-0 fs-3">
              {parse(I18n.t(`${I18N_SCOPE}.step_2.${step2Type === "contact_us" ? 'description_2_html' : 'description_3'}`))}
            </p>
          </div>

          <div
            className={`d-flex gap-3 ${step2Type === "contact_us" ? "justify-content-center" : "justify-content-between"
              }`}
          >
            {step2Type === "not_now" && (
              <Button variant="outline-secondary" onClick={() => setModalStep(1)}>
                {I18n.t(`${I18N_SCOPE}.step_2.button.back`)}
              </Button>
            )}
            <Button variant="primary" onClick={() => handleShowModal()}>
              {I18n.t(`${I18N_SCOPE}.step_2.button.ok`)}
            </Button>
          </div>

        </Modal.Body>
      </Modal>
    );
  };

  return modalStep === 1 ? step1() : step2();
}

export default TrialsEndsModalReportei;
