import { useCallback } from "react";

import TrialingAlert from "./Alerts/TrialingAlert";
import AllowedTrialAlert from "./Alerts/AllowedTrialAlert";
import TrialEndedAlert from "./Alerts/TrialEndedAlert";
import NotAllowedAlert from "./Alerts/NotAllowedAlert";
import AssistantPaused from "./Alerts/AssistantPaused";

import useCurrentUserStore from "@/store/currentUserStore";

const AlertHandler = ({ onDelete }) => {
  const leadsterAiPlugin = useCurrentUserStore((state) => state.currentUser.plugins.leadsterAi);

  const chooseAlert = useCallback(() => {
    if (!leadsterAiPlugin.hasResponseBalance && (leadsterAiPlugin.isPurchased || leadsterAiPlugin.isTrialing)) {
      return <AssistantPaused onDelete={onDelete} />;
    }

    if (leadsterAiPlugin.isPurchased) {
      return <></>;
    }

    if (leadsterAiPlugin.isSignatureEnded) {
      return <NotAllowedAlert onDelete={onDelete} />;
    }

    if (!leadsterAiPlugin.isTrialStarted) {
      return <AllowedTrialAlert onDelete={onDelete} />;
    }

    if (leadsterAiPlugin.isTrialing) {
      return <TrialingAlert onDelete={onDelete} />;
    }

    if (leadsterAiPlugin.isTrialEnded) {
      return <TrialEndedAlert onDelete={onDelete} />;
    }

    return <></>;
  }, []);

  return chooseAlert();
};

export default AlertHandler;
