import { Modal, Button, Alert } from "react-bootstrap";

import HelpAiError from "@/components/integrations/HelpAiError";

const IntegrationEventModal = ({ event, isAdmin, show, onClose }) => {
  if (!show) return null;

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t("views.integrations.modal.view_event.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {event.status === 2 ? (
          <Alert variant="danger">
            <h4>{I18n.t("views.integrations.modal.view_event.text01")}</h4>
            <p style={{ whiteSpace: "normal" }}>
              {event.status_message || I18n.t("views.integrations.modal.view_event.text02")}
            </p>
            <div className="btn-list">
              <Button variant="outline-success" href={`/integrations/resend_event/${event.id}`}>
                <i className="ti ti-send icon me-2"/> {I18n.t("shared.actions.resend")}
              </Button>
            </div>
          </Alert>
        ) : event.status_message ? (
          <Alert variant="info">
            <h4>{I18n.t("views.integrations.modal.view_event.text03")}</h4>
            <p style={{ whiteSpace: "normal" }}>{event.status_message}</p>
          </Alert>
        ) : null}

        {event.status === 2 && <HelpAiError event={event}/>}

        {isAdmin ? (
          <Alert variant="primary" className="alert-important mb-0">
            <div className="d-flex">
              <i className="ti ti-alert-triangle icon me-2"/>
              <div dangerouslySetInnerHTML={{__html: I18n.t("views.integrations.description_lgpd_html")}}></div>
            </div>
          </Alert>
        ) : (
          <pre>
            <code>{JSON.stringify(event.payload_sended || event.payload, null, 2)}</code>
          </pre>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {I18n.t("shared.actions.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IntegrationEventModal;
