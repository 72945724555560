import { useState } from "react";

import { Card } from "react-bootstrap";

import HelpPopover from "@/components/shared/HelpPopover";

import axios from "@/lib/axios";

import * as S from "./styles";

const EvaluationConversation = ({ message }) => {
  const [isLoading, setIsLoading] = useState(false);

  const evaluateConversation = (isLike) => {
    if (isLoading || typeof variable == "boolean") return;

    setIsLoading(true);

    axios
      .post("/ai/evaluation_conversation", {
        script_id: message.script_id,
        lead_id: message.lead_id,
        ai_assistant_id: message.ai_assistant_id,
        is_like: isLike,
      })
      .then(() => {
        message.like = isLike;
      })
      .catch(() => {
        swal(
          I18n.t("request_feedback_alert.error.title"),
          I18n.t("request_feedback_alert.error.message"),
          "error"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card.Footer className="bg-white d-flex justify-content-between">
      <div className="d-flex justify-content-start">
        <HelpPopover
          className="m-2"
          content={I18n.t("views.leadster_ai.page.analytics.history.help_popover")}
          placement="top"
        />
        <S.Title className="my-2">
          {I18n.t("views.leadster_ai.page.analytics.history.evaluate_responses")}
        </S.Title>
      </div>

      <div className="d-flex justify-content-end">
        <S.IconUp like={message.like} onClick={() => evaluateConversation(true)} />
        <S.IconDown like={message.like} onClick={() => evaluateConversation(false)} />
      </div>
    </Card.Footer>
  );
};

export default EvaluationConversation;
