import { useEffect } from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import NavBar from "@/components/leadster_ia/NavBar/NavBar";
import Layout from "@/components/leadster_ia/cards/theme/Layout";
import ResponseLimit from '@/components/leadster_ia/subpages/CreateYourAiAssistant/ResponseLimit';
import RedirectButton from "@/components/leadster_ia/shared/RedirectButton";
import SkeletonLoader from "@/components/leadster_ia_pages/AiAssistant/subpages/CreateYourAiAssistant/SkeletonLoader";

import { Stack } from "react-bootstrap";
import { ROUTES, getRoutesWithoutErrorPage } from "./routes";

import { useAssistantContext } from "@/contexts/AssistantContext";
import useSetEditAssistant
  from "@/components/leadster_ia_pages/AiAssistant/subpages/CreateYourAiAssistant/hooks/useSetEditAssistant";

const ANALYTICS_ROUTES = ROUTES.analytics;

const analyticsNavbarIcons = [
  <i className="ti ti-report-analytics me-2 text-primary" />,
  <i className="ti ti-clock-hour-9 me-2 text-primary" />
];

const Analytics = () => {
  let { path } = useRouteMatch();
  const { isLoadingAssistant } = useSetEditAssistant();
  const { assistant, settings, updateSettings } = useAssistantContext();

  const { assistantId } = useParams();

  const subpagesSettings = settings.pages.assistant.subpages;
  const routesWithoutErrorPage = getRoutesWithoutErrorPage(ANALYTICS_ROUTES);

  useEffect(() => {
    updateSettings({
      title: I18n.t("views.leadster_ai.page.analytics.title", { assistant_name: assistant?.name || "" }),
      description: "",
      breadcrumb: {
        to: "/ai/leadster",
        label: I18n.t('views.leadster_ai.page.back_button.label', {
          page: I18n.t('views.leadster_ai.page.back_button.assistant_page')
        })
      }
    });
  }, [assistant]);

  return (
    <div className="w-100 pt-2">
      <Stack className="w-100 align-items-start" direction="horizontal" gap={3}>
        {isLoadingAssistant ? (
          <SkeletonLoader />
        ) : (
          <>
            <Stack gap={3} style={{ maxWidth: 342 }}>
              <NavBar
                routes={routesWithoutErrorPage}
                icons={analyticsNavbarIcons}
              />

              <RedirectButton
                to={`/ai/leadster/assistants/${assistantId}`}
                label={I18n.t("views.leadster_ai.page.redirect_button.assistant")}
                icon="ti ti-user-edit icon"
              />

              <ResponseLimit />
            </Stack>

            <div
              className="d-flex align-items-center justify-content-center w-100"
              style={{ maxWidth: 944 }}
            >
              <Layout title={subpagesSettings.title} description={subpagesSettings.description}>
                <Switch>
                  {ANALYTICS_ROUTES.map((route, index) => (
                    <Route
                      key={index}
                      exact={index === 0 && route.path === ""}
                      path={`${path}${route.path}`}
                      component={route.component}
                    />
                  ))}
                </Switch>
              </Layout>
            </div>
          </>
        )}
      </Stack>
    </div>
  );
}

export default Analytics;
