import styled from "styled-components";

import { Alert } from "react-bootstrap";

export const BannerWrapper = styled(Alert).attrs({
  className: "mb-0 p-0 border-0",
})`
  display: none;

  button.btn-close {
    padding: 10px;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  height: auto;
  min-height: 45px;
  display: block;
`;
