import { Card } from "react-bootstrap";

const I18N_SCOPE = "views.users.edit.company_form";

const Footer = () => {
  const background = "linear-gradient(90deg, #C1E0FE, #E5F0FD)";

  return (
    <div className="d-flex mt-4">
      <Card
        className="rounded d-flex align-items-center p-3 w-100"
        style={{background}}
      >
        <Card.Text
          className="d-flex align-items-center fw-medium m-0 p-0 gap-1"
        >
          <a
            href={I18n.t("shared.helpscout.leadster_reportei_partnership")}
            target="_blank"
            rel="noreferrer"
            >
            {I18n.t(`${I18N_SCOPE}.footer.link`)}
          </a>
          {I18n.t(`${I18N_SCOPE}.footer.message`)}
        </Card.Text>
      </Card>
    </div>
  );
};

export default Footer;
