import { useState } from "react";

import CreatableSelect from "react-select/creatable";

const EmailCreatableSelect = ({onBlur}) => {
  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label: label,
    value: label,
  });

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const isEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return !!regex.test(value);
  };

  const addOption = () => {
    setValue((prev) => [...prev, createOption(inputValue)]);
    setInputValue("");
  };

  const handleKeyDown = (event) => {
    if (!isEmail(inputValue)) return;

    switch (event.key) {
      case "ArrowRight":
      case " ":
      case "Enter":
      case "Tab":
        event.preventDefault();
        addOption();
    }
  };

  const handleBlur = () => {
    let mails = value.map(item => item.value);

    if (isEmail(inputValue)) {
      mails.push(inputValue);
      addOption();
    }

    onBlur(mails);
  };

  const handleInputChange = (newValue)  => {
    newValue = _.toLower(newValue);

    setInputValue(newValue);
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={(newValue) => setValue(newValue)}
      onInputChange={(newValue) => handleInputChange(newValue)}
      onKeyDown={handleKeyDown}
      placeholder="E-mails..."
      onBlur={handleBlur}
      value={value}
    />
  );
};

export default EmailCreatableSelect;
