import { useEffect, useState } from "react";
import { Button, Tab } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { useAssistantContext } from "@/contexts/AssistantContext";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import AddUrls from "./components/AddUrls";
import DocumentUpload from "./components/DocumentUpload";
import ContentList from "./components/ContentList";
import AddIndividualPages from "./components/AddIndividualPages";

import { StyledTabs } from "./styles";

const AddContent = () => {
  useCreateYourAiAssistant("/content");

  const history = useHistory();
  const { assistant } = useAssistantContext();
  const typeOfAssistant = assistant.type_of_assistant;

  const [contentListTotal, setContentListTotal] = useState(0);
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const initialTab = typeOfAssistant === 'e_commerce' ? (query.get("tab") || "addUrls") : (query.get("tab") || "addSingleUrl");
    setActiveTab(initialTab);
  }, [location.search]);

  const handleSelect = (tab) => {
    setActiveTab(tab);
    const query = new URLSearchParams(location.search);
    query.set("tab", tab);
    history.replace({ search: query.toString() });
  };

  return (
    <>
      <StyledTabs activeKey={activeTab}
                  onSelect={handleSelect}
                  id="add-content-tabs"
                  className="mb-3 border border-0"
      >
        {typeOfAssistant === 'e_commerce' && <Tab
          eventKey="addUrls"
          title={
            <span>
              <i className="icon ti ti-world-www text-primary me-2" />
              {I18n.t("views.leadster_ai.page.add_content.add_website.tab_name")}
            </span>
          }
        >
          <div className="pt-3">
            <AddUrls />
          </div>
        </Tab>}

        <Tab
          eventKey="addSingleUrl"
          title={
            <span>
              <i className="icon ti ti-link-plus text-primary me-2" />
              {I18n.t("views.leadster_ai.page.add_content.add_links.tab_name")}
            </span>
          }
        >
          <div className="pt-3">
            <AddIndividualPages />
          </div>
        </Tab>

        <Tab
          eventKey="uploadFiles"
          title={
            <span>
              <i className="icon ti ti-file-upload text-primary me-2" />
              {I18n.t("views.leadster_ai.page.add_content.add_file.tab_name")}
            </span>
          }
        >
          <div className="pt-3">
            <DocumentUpload />
          </div>
        </Tab>

        <Tab
          eventKey="contentList"
          title={
            <span>
              <i className="icon ti ti-list text-primary me-2" />
              {I18n.t("views.leadster_ai.page.add_content.content_list.tab_name", { total: contentListTotal })}
            </span>
          }
        >
          <div className="pt-3">
            <ContentList onTotalCountChange={setContentListTotal} />
          </div>
        </Tab>
      </StyledTabs>

      <div className="mt-3 pt-3 text-end">
        <Button
          variant="primary"
          type="button"
          onClick={() => history.push(`/ai/leadster/assistants/${assistant.id}/test`)}
        >
          {I18n.t("shared.actions.advance")}
        </Button>
      </div>
    </>
  );
};

export default AddContent;
