const ItemList = ({ items, onItemClick, emptyMessage, itemClass, iconName, iconClass, showCloseIcon }) => (
  <div className="form-group">
    <div className="d-flex flex-wrap">
      {items.length === 0 ? (
        <div className="text-muted">{emptyMessage}</div>
      ) : (
        items.map((item) => (
          <div
            key={item.value}
            className={`ms-2 mb-2 border px-1 py-0 rounded-1 text-decoration-none cursor-pointer ${itemClass}`}
            onClick={() => onItemClick(item)}
          >
            <i className={`me-1 ti ${iconName} ${iconClass}`}></i>
            {item.label}
            {showCloseIcon && <i className="ti ti-x ms-1"></i>}
          </div>
        ))
      )}
    </div>
  </div>
);

export default ItemList;
