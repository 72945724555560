import { ACTIONS } from "./constants";

export const initialState = {
  settings: {
    title: "Title",
    description: "Description",
    pages: {
      assistant: {
        subpages: {
          createYourAiAssistant: {
            title: "Title",
            description: null,
            currentStep: "",
          },
        },
      },
    },
  },

  assistants: [],
  assistant: null,
  selectedAddContentType: null,
  processingAddContentType: null,
  disabledContent: false,
};

export function assistantReducer(state, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_SETTINGS:
      return { ...state, settings: { ...state.settings, ...action.payload } };
    case ACTIONS.RESET_ASSISTANT_DATA:
      return { ...state, assistant: initialState.assistant };
    case ACTIONS.SET_ASSISTANTS_LIST:
      return { ...state, assistants: action.payload };
    case ACTIONS.SET_ASSISTANT:
      return { ...state, assistant: action.payload };
    case ACTIONS.SET_CHARACTERS_USED:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          characters_used: action.payload,
        },
      };
    case ACTIONS.REMOVE_ASSISTANT:
      return {
        ...state,
        assistants: state.assistants.filter((assistant) => assistant.id !== action.payload),
      };
    case ACTIONS.ADD_PENDING_BASE:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          pending_knowledge_base: action.payload,
        },
      };
    case ACTIONS.REMOVE_PENDING_BASE:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          pending_knowledge_base: null,
        },
      };
    case ACTIONS.UPDATE_STATUS_ALERT:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          status_alert: action.payload,
        },
      };
    case ACTIONS.UPDATE_ASSISTANT:
      return {
        ...state,
        assistant: {
          ...state.assistant,
          ...action.payload,
        },
      };
    case ACTIONS.SET_SELECTED_ADD_CONTENT_TYPE:
      return {
        ...state,
        selectedAddContentType: action.payload,
      };
    case ACTIONS.SET_PROCESSING_ADD_CONTENT_TYPE:
      return {
        ...state,
        processingAddContentType: action.payload,
      };
    case ACTIONS.SET_DISABLED_CONTENT:
      return {
        ...state,
        disabledContent: action.payload,
      };
    default:
      return state;
  }
}
