import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const CardBodyScrollable = styled.div`
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 3px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: #fff;
  }
`;

export const TableContainer = styled(CardBodyScrollable)`
  max-height: 400px;
  margin-bottom: 1rem;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #CCE2FB;
  border-bottom: 1px solid #CCE2FB;
  margin-right: -25px;
  margin-left: -24px;
  margin-top: -16px;
`;

export const Table = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  border-radius: 0;
  overflow: visible;
`;

export const Header = styled.div`
  display: flex;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 10;
  font-weight: 600;
  box-shadow: 0 2px 2px -1px #CCE2FB;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #CCE2FB;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(32, 107, 196, 0.05);
  }
`;

export const Cell = styled.div`
  padding: 0.75rem;
  flex: ${props => props.width || 1};
  display: flex;
  align-items: center;
  border-right: 1px solid #CCE2FB;
  position: relative;

  &:last-child {
    justify-content: center;
    width: 200px;
    border-right: none;
  }
`;
