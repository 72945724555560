import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import Modal from "react-bootstrap/Modal";

import ReactPortal from "@/components/ReactPortal";
import ScriptItem from "./ScriptItem";
import SkeletonLoader from "./SkeletonLoader";

import axios from "@/lib/axios";

import DemoModal from "../DemoModal";

const ScriptActivationModal = ({ show = false, onClose, allowedScripts = [] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scripts, setScripts] = useState([]);
  const [showDemo, setShowDemo] = useState(false);
  const [demoScriptId, setDemoScriptId] = useState(null);

  const isIntegrationTest = Array.isArray(allowedScripts) && allowedScripts.length > 0;

  useEffect(() => {
    if (show) {
      fetchScripts();
    }
  }, [show]);

  const handleClose = () => {
    onClose();
  };

  const fetchScripts = () => {
    setIsLoading(true);

    axios.get(`/ai/scripts`)
      .then(({ data }) => {
        let filteredScripts = data.scripts;
        if (allowedScripts && allowedScripts.length > 0) {
          filteredScripts = filteredScripts.filter(script => allowedScripts.includes(script.id));
        }
        setScripts(filteredScripts);
      })
      .catch(() => {
        swal(
          I18n.t("shared.messages.request_feedback_alert.error.title"),
          I18n.t("shared.messages.request_feedback_alert.error.message"),
          "error"
        );
      })
      .then(() => setIsLoading(false));
  };

  const titleKey = isIntegrationTest
    ? "views.integrations.integration_test.title"
    : "views.leadster_ai.page.home.add_to_flow_modal.title";

  const subtitleKey = isIntegrationTest
    ? "views.integrations.integration_test.subtitle"
    : "views.leadster_ai.page.home.add_to_flow_modal.subtitle";

  return (
    <ReactPortal>
      <Modal show={show} className="modal-blur" size="xl" centered onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="py-2">
            <div className="modal-title mb-1">
              {I18n.t(titleKey)}
            </div>

            <div className="text-muted">
              {I18n.t(subtitleKey)}
            </div>
          </div>
        </Modal.Header>
        {isIntegrationTest && <div className="bg-primary-lt py-2 text-center">
          <p className="m-0 text-black">
            {parse(I18n.t("views.integrations.integration_test.info_html"))}
          </p>
        </div>}

        <Modal.Body className="overflow-auto" style={{ maxHeight: 500}}>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            scripts.map((script) => (
              <ScriptItem
              key={script.id}
              script={script}
              isIntegrationTest={isIntegrationTest}
              onTestIntegration={() => {
                setDemoScriptId(script.id);
                setShowDemo(true);
              }}
            />
            ))
          )}
        </Modal.Body>

        <Modal.Footer className="border-top py-2">
          <button type="button" className="btn me-auto" onClick={handleClose}>
            {I18n.t("shared.actions.close")}
          </button>
        </Modal.Footer>
      </Modal>
      <DemoModal
        show={showDemo}
        onClose={() => setShowDemo(false)}
        scriptId={demoScriptId}
      />
    </ReactPortal>
  );
};

PropTypes.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  allowedScripts: PropTypes.arrayOf(PropTypes.number)
};

export default ScriptActivationModal;
