import { useState } from "react";
import useUpdateEffect from "@/hooks/useUpdateEffect";

import HelpPopover from "@/components/shared/HelpPopover";
import MultipleOptionsInput from "./MultipleOptionsInput";

import allowedOperators from "./allowed-operators";
import parse from "html-react-parser";

import * as S from "./styles";

const ConditionItem = ({
  condition,
  mainOperator = "OR",
  scriptFields = [],
  enableUpdateMainOperator = true,
  renderConfigurationButton,
  getSelectorOptions,
  onChangeCondition = () => {},
  onChangeMainOperator = () => {},
}) => {
  const [conditionReadOnly] = useState(condition.readOnly);
  const [conditionField, setConditionField] = useState(condition.field);
  const [conditionFieldLabel] = useState(condition.fieldLabel);
  const [conditionOperator, setConditionOperator] = useState(condition.operator);
  const [conditionValues, setConditionValues] = useState(condition.values);
  const [conditionValueLabels] = useState(condition.valueLabels);
  const [conditionIcon] = useState(conditionReadOnly ? "ti-zoom-scan" : "ti-arrows-split");
  const [conditionColor] = useState(conditionReadOnly ? "bg-cyan" : "bg-success");

  const allowMultiValues = allowedOperators.find((operator) => operator.value === conditionOperator).allowMultiValues;

  useUpdateEffect(() => {
    if (!allowMultiValues && conditionValues.length > 1) {
      setConditionValues([conditionValues[0]]);
    }
  }, [conditionOperator]);

  useUpdateEffect(() => {
    onChangeCondition({
      field: conditionField,
      operator: conditionOperator,
      values: conditionValues,
    });
  }, [conditionField, conditionOperator, conditionValues]);

  const renderMainOperatorSelect = () => (
    <div style={{ width: 145 }} className="d-flex align-items-center justify-content-end">
      <div className="text-end" style={{ width: 85 }}>
        <HelpPopover
          content={I18n.t("views.manage_flows.conditional_block.main_operator_popover_html")}
          className="me-2"
        />
      </div>
      <select
        className="form-select me-2"
        style={{ width: 85 }}
        value={mainOperator}
        onChange={(e) => onChangeMainOperator(e.target.value)}
        disabled={!enableUpdateMainOperator}
      >
        <option value="OR">{I18n.t("views.manage_flows.conditional_block.operators.or")}</option>
        <option value="AND">{I18n.t("views.manage_flows.conditional_block.operators.and")}</option>
      </select>
    </div>
  );

  const renderConditionFieldSelect = () => (
    <select value={conditionField} className="form-select" onChange={(e) => setConditionField(e.target.value)}>
      <option value="">{I18n.t("views.manage_flows.script_editor.select_the_field")}</option>
      {scriptFields.map((field) => (
        <option key={field} value={field}>
          {field}
        </option>
      ))}
    </select>
  );

  const renderConditionOperatorSelect = () => (
    <select value={conditionOperator} className="form-select" onChange={(e) => setConditionOperator(e.target.value)}>
      {allowedOperators.map((operator) => (
        <option key={operator.value} value={operator.value}>
          {parse(operator.label)}
        </option>
      ))}
    </select>
  );

  return (
    <S.Container>
      <div className="row g-2 mb-2">
        <div className={conditionReadOnly ? "col-md-12" : "col-md-4"}>
          <div className="input-group">
            {renderConfigurationButton
              ? renderConfigurationButton(conditionFieldLabel, conditionIcon, conditionColor)
              : renderMainOperatorSelect()}
            {!conditionReadOnly ? (
              renderConditionFieldSelect()
            ) : (
              <input type="text" className="form-control" value={conditionValueLabels} disabled />
            )}
          </div>
        </div>
        {!conditionReadOnly && (
          <>
            <div className="col-md-2">{renderConditionOperatorSelect()}</div>
            <div className="col-md-6">
              <MultipleOptionsInput
                values={conditionValues}
                allowMultiValues={allowMultiValues}
                onUpdateValue={setConditionValues}
                loadDefaultOptions={() => getSelectorOptions(conditionField)}
              />
            </div>
          </>
        )}
      </div>
    </S.Container>
  );
};

export default ConditionItem;
