import React from 'react';
import Chart from "react-apexcharts";
import LineChartOptions from './LineChartOptions';
import Modal from "./Modal";
import { Dropdown } from 'react-bootstrap';

import { classBinding } from "@/utils/helpers";

const LineChartCard = ({
  value,
  label,
  data = [],
  options,
  percentage,
  isLoading = false,
  hasError = false,
  handleEventFilterChange,
  showDropdown = false,
  dropdownItems = []
}) => {
  return (
    <div className="card">
      <div className={classBinding({ "content-loading": isLoading })}>
        {hasError ? (
          <div className="card-body d-flex justify-content-center align-items-center" style={{ minHeight: "156px" }}>
            <p>{I18n.t("views.dashboard.request_error.message")}</p>
          </div>
        ) : (
          <>
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <div className="h1 fw-medium">{value}</div>
                {percentage ? (
                  <div>
                    <span className="text-muted">{label} | {percentage}</span>
                    <Modal />
                  </div>
                ) : (
                  <div className="text-muted">{label}</div>
                )}
              </div>
              {showDropdown && (
                <Dropdown onSelect={handleEventFilterChange}>
                  <Dropdown.Toggle
                    as="div"
                    className="p-0 mb-4 d-flex align-items-center justify-content-center"
                    style={{
                      cursor: "pointer",
                      fontSize: "1.5rem",
                    }}
                  >
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropdownItems.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.eventKey}>
                        {item.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <div className="card-chart-bg">
              <div style={{ height: '100%', maxHeight: '64px', position: 'relative' }}>
                <Chart
                  options={options || LineChartOptions}
                  series={data}
                  type="area"
                  height="64"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LineChartCard;
