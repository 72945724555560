import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import Select from "react-select";

import ChatPreview from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ChatPreview";
import GuideModal from "@/components/shared/GuideModal";

import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";
import { useAssistantContext } from "@/contexts/AssistantContext";

import EventBus from "@/utils/event_bus";
import axios from "@/lib/axios";

import parse from "html-react-parser";

import * as S from "./styles";

import guideContents from "./guideContents";
import folderIcon from "@/../assets/images/ai_assistant/file-folder-animated.gif";

const TestAssistant = () => {
  useCreateYourAiAssistant("/test");

  const history = useHistory();
  const intervalIdRef = useRef();
  const previewAssistantGuideRef = useRef();

  const { assistant } = useAssistantContext();

  const [retailerItemOptions, setRetailerItemOptions] = useState([]);
  const [visibleOptions, setVisibleOptions] = useState(false);

  const goToNextStep = () => history.push("./activation");

  const handleClickAddToFlow = () => {
    if (previewAssistantGuideRef.current.show()) return;

    goToNextStep();
  };

  const handleSelectChange = (selectedOption) => {
    EventBus.dispatch("updateRetailerItemId", selectedOption?.value);
  };

  const handleLoadOptions = () => {
    axios
      .get(`/ai/assistants/${assistant.id}/knowledge-bases/retailer-items`)
      .then(({ data }) => {
        data.map((item) => {
          if (!item[0]) return;
          setRetailerItemOptions((prev) => [...prev, { value: item[0], label: item[1] }]);
        });
      });
  };

  const handleViewedLeadsterAIBlock = () => {
    clearInterval(intervalIdRef.current);

    intervalIdRef.current = setInterval(() => {
      if (document.getElementById("nld-event-bus")) {
        EventBus.on("viewedLeadsterAIBlock", () => setVisibleOptions(true));
        clearInterval(intervalIdRef.current);
      }
    }, 1000);
  };

  useEffect(() => {
    handleLoadOptions();
    handleViewedLeadsterAIBlock();

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  return (
    <>
      <GuideModal
        storageKey="leadster-ai-preview"
        guideContents={guideContents}
        autoShow={false}
        finishButtonText={I18n.t("general.actions.add")}
        finishCallback={goToNextStep}
        ref={previewAssistantGuideRef}
      />

      {assistant.already_populated_bases ? (
        <>
          {(assistant.recent_content_update) && (
            <div className="alert alert-warning alert-dismissible" role="alert">
              <div className="text-dark">
                {parse(I18n.t("views.leadster_ai.page.test_assistant.recent_status_check_alert_html"))}
              </div>
              <a className="btn-close" data-bs-dismiss="alert" aria-label="close"></a>
            </div>
          )}

          {retailerItemOptions.length > 0 && visibleOptions && (
            <div className="card mb-3">
              <div className="card-body">
                <div className="card-title mb-2">
                  {I18n.t("views.leadster_ai.page.test_assistant.select_content.title")}
                </div>
                <div className="card-subtitle mb-3 fs-4">
                  {I18n.t("views.leadster_ai.page.test_assistant.select_content.subtitle")}
                </div>

                <Select
                  options={retailerItemOptions}
                  isMulti={false}
                  isClearable={true}
                  isSearchable={true}
                  onChange={handleSelectChange}
                  placeholder={I18n.t("views.leadster_ai.page.test_assistant.select_content.placeholder")}
                  className="mb-3"
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 2147483647 }),
                  }}
                />
              </div>
            </div>
          )}

          <S.Container>
            <ChatPreview hideButtonClose />
          </S.Container>

          <div className="border-top mt-3 pt-3 text-end">
            <button type="button" className="btn btn-primary" onClick={handleClickAddToFlow}>
              {I18n.t("views.leadster_ai.page.test_assistant.add_to_flow")}
            </button>
          </div>
        </>
      ) : (
        <S.Container>
          <S.Icon src={folderIcon} />

          <div className="fs-3 fw-bold my-3">{I18n.t("views.leadster_ai.page.test_assistant.text_01")}</div>

          <S.Paragraph className="text-center">
            {parse(I18n.t("views.leadster_ai.page.test_assistant.text_02_html"))}
          </S.Paragraph>
        </S.Container>
      )}
    </>
  );
};

export default TestAssistant;
