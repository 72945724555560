import { createContext, useContext, useEffect, useReducer } from "react";

import { ACTIONS } from "./state/constants";
import { assistantReducer, initialState } from "./state/reducer";

import axios from "@/lib/axios";

export const AssistantContext = createContext({});

function AssistantProvider({ children }) {
  const [state, dispatch] = useReducer(assistantReducer, initialState);

  const updateSettings = (settings) => {
    dispatch({ type: ACTIONS.UPDATE_SETTINGS, payload: settings });
  };

  const resetAssistantData = () => {
    dispatch({ type: ACTIONS.RESET_ASSISTANT_DATA });
  };

  const reloadCharactersUsed = () => {
    const endpoint = `/ai/assistants/${state.assistant.id}/knowledge-bases/used-characters`;

    axios.get(endpoint).then(({ data }) => {
      dispatch({ type: ACTIONS.SET_CHARACTERS_USED, payload: data.used_characters });
    });
  };

  const setAssistantsList = (assistants) => {
    dispatch({ type: ACTIONS.SET_ASSISTANTS_LIST, payload: assistants });
  };

  const setAssistant = (assistant) => {
    dispatch({ type: ACTIONS.SET_ASSISTANT, payload: assistant });
  };

  const removeAssistant = (assistantId) => {
    dispatch({ type: ACTIONS.REMOVE_ASSISTANT, payload: assistantId });
  };

  const addPendingBase = (knowledgeBase) => {
    dispatch({ type: ACTIONS.ADD_PENDING_BASE, payload: knowledgeBase });
  };

  const removePendingBase = () => {
    dispatch({ type: ACTIONS.REMOVE_PENDING_BASE });
  };

  const updateStatusAlert = (status) => {
    dispatch({ type: ACTIONS.UPDATE_STATUS_ALERT, payload: status });
  };

  const updateAssistant = (assistantData) => {
    dispatch({ type: ACTIONS.UPDATE_ASSISTANT, payload: assistantData });
  };

  const setSelectedAddContentType = (type) => {
    dispatch({ type: ACTIONS.SET_SELECTED_ADD_CONTENT_TYPE, payload: type });
    localStorage.setItem("selectedAddContentType", type);
  };

  const setProcessingAddContentType = (type) => {
    dispatch({ type: ACTIONS.SET_PROCESSING_ADD_CONTENT_TYPE, payload: type });
    localStorage.setItem("processingAddContentType", type);
  };

  const setDisabledContent = (boolean) => {
    dispatch({ type: ACTIONS.SET_DISABLED_CONTENT, payload: boolean });
    localStorage.setItem("disabledContent", boolean.toString());
  };

  useEffect(() => {
    const storedType = localStorage.getItem("selectedAddContentType");
    const processingType = localStorage.getItem("processingAddContentType");
    const disabledContentString = localStorage.getItem("disabledContent");

    if (storedType) {
      dispatch({ type: ACTIONS.SET_SELECTED_ADD_CONTENT_TYPE, payload: storedType });
    }

    if (processingType) {
      dispatch({ type: ACTIONS.SET_PROCESSING_ADD_CONTENT_TYPE, payload: processingType });
    }

    if (disabledContentString !== null) {
      const disabledContentBoolean = disabledContentString === "true";
      dispatch({ type: ACTIONS.SET_DISABLED_CONTENT, payload: disabledContentBoolean });
    }
  }, []);

  return (
    <AssistantContext.Provider
      value={{
        ...state,
        updateSettings,
        resetAssistantData,
        reloadCharactersUsed,
        setAssistantsList,
        setAssistant,
        removeAssistant,
        addPendingBase,
        removePendingBase,
        updateStatusAlert,
        updateAssistant,
        setSelectedAddContentType,
        setProcessingAddContentType,
        setDisabledContent,
      }}
    >
      {children}
    </AssistantContext.Provider>
  );
}

export function useAssistantContext() {
  return useContext(AssistantContext);
}

export default AssistantProvider;
