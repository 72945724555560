export default [
  {
    title: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.title_01"),
    content: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.subtitle_01_html"),
    media: {
      type: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.type_01"),
      url: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.media_url_01"),
    },
  },
  {
    title: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.title_02"),
    content: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.subtitle_02_html"),
    media: {
      type: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.type_02"),
      url: I18n.t("guide_modal.script_flow.ai_block.end_conversation_text.media_url_02"),
    },
  },
];
