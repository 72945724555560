import { useState } from "react";
import { Alert, Button, Card, Spinner } from "react-bootstrap";
import parse from "html-react-parser";

import useCurrentUserStore from "@/store/currentUserStore";
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";

import axios from "@/lib/axios";

import * as S from "./styles";

const I18N_SCOPE = "views.integrations.modal.help_ai";

const HelpAiError = ({ event }) => {
  const { isFree } = useCurrentUserStore((state) => state.currentUser);

  const [stage, setStage] = useState("evaluate_error");
  const [response, setResponse] = useState(null);

  const sendErrorEvaluate = () => {
    let url = "/integration-events/help-ai-error/" + event.id

    setStage("waiting");

    axios.get(url)
      .then((response) => {
        setStage("success");
        setResponse(response.data.help_ia);
        toastr.success(I18n.t(`${I18N_SCOPE}.toast.success`));
      }).catch(() => {
        setStage("error");
        toastr.error(I18n.t(`${I18N_SCOPE}.toast.error`));
    });

    OpenpanelService.track("integration_error_analyze_ia");
  };

  const resendError = () => {
    sendErrorEvaluate();
    OpenpanelService.track("integration_error_analyze_error")
  };

  if (isFree) {
    return (
      <>
        <div className="mt-3">
          <i className="ti ti-sparkles icon text-primary h1 me-2"/> <b>{I18n.t(`${I18N_SCOPE}.free.title`)}</b>

          <Alert variant="primary" className="mt-1">
            <div className="row g-0">
              <div className="col-1 d-flex justify-content-center align-items-center">
                <div className="alert-icon">
                  <i className="ti ti-exclamation-circle h1"/>
                </div>
              </div>

              <div className="col-11 text-dark">
                <p><b>{I18n.t(`${I18N_SCOPE}.free.subtitle`)}</b></p>

                <p>{I18n.t(`${I18N_SCOPE}.free.describe`)}</p>

                <Button
                  variant="primary"
                  href={
                    buildWhatsAppLink({
                      number: getCustomVariable("whatsapp_number_sales"),
                      message: I18n.t(`${I18N_SCOPE}.seller_message`),
                    })
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => OpenpanelService.track("integration_error_talk_to_seller")}
                >
                  <i className="ti ti-messages icon me-2"/> {I18n.t(`${I18N_SCOPE}.free.button_sellers`)}
                </Button>
              </div>
            </div>
          </Alert>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mt-3 mb-3">
        <b>{I18n.t(`${I18N_SCOPE}.normal.title`)}</b>

        {stage === "evaluate_error" &&
        <Card className="mt-2" as={S.CustomCard}>
          <Card.Body>
            <div className="row g-0">
              <div className="col-1">
                <i className="ti ti-sparkles icon text-primary h1"/>
              </div>
              <div className="col-11">
                <p>{parse(I18n.t(`${I18N_SCOPE}.normal.help_with_ai_html`))}</p>

                <Button variant="primary" onClick={sendErrorEvaluate}>
                  <i className="ti ti-sparkles icon me-2"/> {I18n.t(`${I18N_SCOPE}.normal.button_help`)}
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
        }

        {stage === "waiting" &&
        <Alert variant="warning" className="mt-1">
          <div className="row g-0">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <Spinner animation="border" />
            </div>

            <div className="col-11 text-dark">
              <p>{parse(I18n.t(`${I18N_SCOPE}.normal.waiting_html`))}</p>
            </div>
          </div>
        </Alert>
        }

        {stage === "error" &&
        <Alert variant="danger" className="mt-1">
          <div className="row g-0">
            <div className="col-1 d-flex justify-content-center align-items-center">
              <div className="alert-icon">
                <i className="ti ti-exclamation-circle text-danger h1"/>
              </div>
            </div>

            <div className="col-11 text-dark">
              {parse(I18n.t(`${I18N_SCOPE}.normal.error_html`))}

              <Button variant="primary" onClick={resendError}>
                <i className="ti ti-rotate me-2"/> {I18n.t(`${I18N_SCOPE}.normal.button_try_again`)}
              </Button>
            </div>
          </div>
        </Alert>
        }

        {stage === "success" &&
        <div className="mt-3 mb-3">
          <S.Text>
            {parse(response)}
          </S.Text>

          <Button
            variant="primary"
            href={`/integrations/${event.company_integration_id}/edit`}
            onClick={() => OpenpanelService.track("integration_error_to_edit")}
          >
            <i className="ti ti-edit me-2"/> {I18n.t(`${I18N_SCOPE}.normal.button_edit`)}
          </Button>
        </div>
        }
      </div>
    </>
  );
};

export default HelpAiError;
