import { useAssistantContext } from "@/contexts/AssistantContext";

import AddSingleUrl from "../AddSingleUrl";
import AddWebsiteLink from "../AddWebsiteLink";

import FileIcon from "@/../../public/images/ai_assistant/file.svg";

const AddIndividualPages = () => {
  const { disabledContent, selectedAddContentType, setSelectedAddContentType, processingAddContentType } = useAssistantContext();

  const handleSelectionChange = (type) => {
    setSelectedAddContentType(`${type}`);
  };

  const isSelected = (type) => selectedAddContentType === `${type}`;

  const handleSelectionDisabled = (selected) => {
    return (disabledContent && !isSelected(selected)) || (disabledContent && processingAddContentType === "upload");
  };

  return (
    <>
      <AddSingleUrl
        type="single_url"
        inputLabel="views.leadster_ai.page.add_content.add_links.add_single_url_label"
        titleKey="views.leadster_ai.page.add_content.add_links.add_single_url"
        descriptionKey="views.leadster_ai.page.add_content.add_links.add_single_url_description"
        imageSrc={FileIcon}
        isSelected={isSelected("single_url")}
        onSelect={handleSelectionChange}
        isSelectionDisabled={handleSelectionDisabled("single_url")}
      />

      <AddWebsiteLink
        type="multiple_url"
        inputLabel="views.leadster_ai.page.add_content.add_links.add_multiple_url_label"
        titleKey="views.leadster_ai.page.add_content.add_links.add_multiple_url"
        descriptionKey="views.leadster_ai.page.add_content.add_links.add_multiple_url_description"
        imageSrc={FileIcon}
        isSelected={isSelected("multiple_url")}
        onSelect={handleSelectionChange}
        isSelectionDisabled={handleSelectionDisabled("multiple_url")}
        isListMultipleUrls={true}
      />
    </>
  );
};

export default AddIndividualPages;
