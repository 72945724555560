import React, { useEffect } from "react";

import { Stack } from "react-bootstrap";

import CreateNewAssistant from "@/components/leadster_ia/cards/CreateNewAssistant";
import ResponseLimit from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ResponseLimit";
import EmptyAssistantsInfo from "@/components/leadster_ia/cards/EmptyAssistantsInfo";
import AssistantList from "@/components/leadster_ia/cards/AssistantList";
import SkeletonLoader from "./SkeletonLoader";

import { useAssistantContext } from "@/contexts/AssistantContext";
import useLoadAssistants from "./hooks/useLoadAssistants";

const Home = () => {
  const { assistants, updateSettings } = useAssistantContext();
  const { isLoading } = useLoadAssistants();

  useEffect(() => {
    updateSettings({
      title: I18n.t("views.leadster_ai.page.home.title"),
      description: I18n.t("views.leadster_ai.page.home.subtitle"),
      breadcrumb: false,
    });
  }, []);

  return (
    <div className="w-100 pt-5">
      <Stack className="w-100 align-items-start" direction="horizontal" gap={3}>
        <Stack direction="vertical" gap={3} style={{ maxWidth: 330, minWidth: 330 }}>
          <CreateNewAssistant />
          <ResponseLimit />
        </Stack>

        <div className="vr" style={{ minHeight: "260px", alignSelf: "flex-start" }}/>

        <div className="d-flex align-items-center justify-content-center w-100">
          {isLoading ? (
            <SkeletonLoader />
          ) : assistants.length > 0 ? (
            <AssistantList />
          ) : (
            <EmptyAssistantsInfo />
          )}
        </div>
      </Stack>
    </div>
  );
};

export default Home;
