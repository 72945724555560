import styled from "styled-components";

export const BoxContainerShadow = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background:
      linear-gradient(#f5f7fb 33%, rgba(245,247,251, 0)),
      linear-gradient(rgba(245,247,251, 0), #f5f7fb 66%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(87,87,87, 0.5), rgba(0,0,0,0)),
      radial-gradient(farthest-side at 50% 100%, rgba(87,87,87, 0.5), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 1px, 100% 5px, 100% 7px, 100% 5px;
    pointer-events: none;
    border-radius: 2px;
  }

  .boxes-ai-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
    display: block;
    padding: 5px;
    margin-top: 10px;
    border-top: 1px solid #e9e9e9;
  }
`;
