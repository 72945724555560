import { useState } from "react";

import ModalLeadView from "@/components/lead/ModalLeadView";

import * as S from './styles';

const ButtonOpenModal = ({ id, size, icon, className, disabled }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    if (disabled) return;

    setShowModal(true);
  }

  return(
    <>
      <S.Button className={className} size={size} icon={icon} onClick={handleOpenModal} disabled={disabled}>
        <i className={`ti ti-${icon} me-2`} />
        {I18n.t("views.leads.filter.datatable.actions.see_data")}
      </S.Button>


      {showModal &&
        <ModalLeadView
          id={id}
          show={true}
          onButton={() => setShowModal(false)}
        />
      }
    </>
  );
};

export default ButtonOpenModal;
