import { Button } from "react-bootstrap";

import { useHistory } from "react-router-dom";

const RedirectButton = ({ to, label, icon }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
  };

  return (
    <Button
      onClick={handleClick}
      className="btn d-flex align-items-start justify-content-start border bg-white px-3 py-3 w-100 text-dark"
    >
      <i className={`text-primary ${icon}`}></i>
      {label}
    </Button>
  );
};

export default RedirectButton;
