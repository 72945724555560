import { useState, useEffect } from "react";
import ItemList from "./ItemList";
import EventBus from "@/utils/event_bus";

const I18N_SCOPE = "views.manage_flows.script_editor.ia_interaction";

const SelectableItems = ({ settingsItems, preSelectedItems, onDefinition, nodePreview }) => {
  const [availableItems, setAvailableItems] = useState([]);
  const [chosenItems, setChosenItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    EventBus.on("onAfterFlowBlockRemove", handleAfterFlowBlockRemove, "portal-root");
    const sortedPreSelectedItems = preSelectedItems.sort();
    setSelectedItems(sortedPreSelectedItems);
    initializeItems(sortedPreSelectedItems);
  }, []);

  const initializeItems = (selected) => {
    const initialAvailableItems = settingsItems.filter((item) => !selected.includes(item.value));
    const initialChosenItems = settingsItems.filter((item) => selected.includes(item.value));
    setAvailableItems(initialAvailableItems);
    setChosenItems(initialChosenItems);
  };

  const saveSettings = (selected) => {
    if (!selected) { return; }

    initializeItems(selected);
    onDefinition({ from: selected });
    setSelectedItems(selected);
  };

  const addItem = (item) => {
    const updatedSelectedItems = [...selectedItems, item.value].sort();
    saveSettings(updatedSelectedItems);

    const { save, label } = nodePreview.data;
    const { addNewBlockToChildren } = nodePreview.parent;

    const block = createBlock(save, label, item.value, item.label);

    addNewBlockToChildren(block, "below", nodePreview);
  };

  const removeItem = (item) => {
    filterByValue(item.value).forEach((currentNode) => {
      nodePreview.treeRemove(currentNode);
    });

    const updatedSelectedItems = selectedItems.filter((i) => i !== item.value);
    saveSettings(updatedSelectedItems);
  };

  const handleAfterFlowBlockRemove = (e) => {
    if (!e || e.values.length === 0 || e.field !== nodePreview.data.save) return;
    const currentItem = settingsItems.find((item) => item.value === e.values[0]);
    const updatedSelectedItems = nodePreview.data.from.filter((i) => i !== currentItem.value);
    saveSettings(updatedSelectedItems);
  };

  const createBlock = (field, fieldLabel, value, valueLabel) => ({
    if: {
      mainOperator: "OR",
      conditions: [
        {
          field,
          fieldLabel,
          operator: "=",
          values: [value],
          valueLabels: [valueLabel],
          readOnly: true,
        },
      ],
    },
    reject_lead: false,
    then: [],
  });

  const filterByValue = (value) => {
    const { filterChildrenByCondition } = nodePreview.parent;
    const { save } = nodePreview.data;

    return filterChildrenByCondition(save, value);
  };

  return (
    <div className="form-group d-flex justify-content-between">
      <div className="w-50">
        <label className="form-label">
          <i className="ti ti-click icon text-primary me-1" />
          {I18n.t(`${I18N_SCOPE}.settings.intent.select_intents`)}
        </label>
        <ItemList
          items={availableItems}
          onItemClick={addItem}
          emptyMessage={I18n.t(`${I18N_SCOPE}.settings.intent.all_intents_selected`)}
          itemClass="border-primary text-primary"
          iconName="ti-plus"
        />
      </div>
      <div className="w-50 ms-3">
        <label className="form-label">
          <i className="ti ti-input-check icon text-primary me-1" />
          {I18n.t(`${I18N_SCOPE}.settings.intent.selected_intents`)}
        </label>
        <ItemList
          items={chosenItems}
          onItemClick={removeItem}
          emptyMessage={I18n.t(`${I18N_SCOPE}.settings.intent.no_intents_selected`)}
          itemClass="border-secondary bg-light text-dark"
          iconName="ti-check"
          iconClass="text-primary"
          showCloseIcon={true}
        />
      </div>
    </div>
  );
};

export default SelectableItems;
