import { Card, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import swal from "sweetalert";
import EventCustomizationModal from "./EventCustomizationModal";
import * as S from "./styles";
import customAxios from "@/lib/axios";
import useFilters from "../../hooks/useFilters";

const I18N_SCOPE = "views.users.edit.company_form";

const EventCustomization = ({ reporteiMetric, initialStartDate, initialEndDate }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const [integrations, setIntegrations] = useState([]);
  const [activeSlug, setActiveSlug] = useState(null);
  const [events, setEvents] = useState({});
  const [removedEvents, setRemovedEvents] = useState({});
  const [pendingRemovedEvents, setPendingRemovedEvents] = useState({});
  const [saving, setSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [loadedDates, setLoadedDates] = useState({});

  const filters = useFilters();

  const formatDateToISO = (dateString) => {
    if (!dateString) return null;

    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  const normalizeRemovedEvents = (eventsObj) => {
    if (!eventsObj) return {};

    const normalized = {};

    Object.keys(eventsObj).forEach(key => {
      const keyMap = {
        'facebookAds': 'facebook_ads',
        'googleAnalytics4': 'google_analytics_4',
        'googleAdwords': 'google_adwords',
        'linkedinAds': 'linkedin_ads'
      };

      const normalizedKey = keyMap[key] || key.replace(/([A-Z])/g, '_$1').toLowerCase();
      normalized[normalizedKey] = eventsObj[key];
    });

    return normalized;
  };

  useEffect(() => {
    if (reporteiMetric && reporteiMetric.integrations) {
      const integrationsList = reporteiMetric.integrations.filter(
        integration => integration.slug !== 'linkedin_ads'
      ) || [];
      setIntegrations(integrationsList);

      const currentRemovedEvents = normalizeRemovedEvents(reporteiMetric.removedEvents) || {};

      setRemovedEvents(currentRemovedEvents);
      setPendingRemovedEvents({...currentRemovedEvents});

      if (integrationsList.length > 0 && !activeSlug) {
        setActiveSlug(integrationsList[0]?.slug);
      }
    }
  }, [reporteiMetric]);

  useEffect(() => {
    const formattedStartDate = formatDateToISO(filters.startDate);
    const formattedEndDate = formatDateToISO(filters.endDate);

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    if (activeSlug) {
      fetchEvents(activeSlug, formattedStartDate, formattedEndDate);
    }
  }, [filters.startDate, filters.endDate, activeSlug]);

  const handleModal = () => {
    const newState = !showModal;

    if (!newState && hasChanges) {
      swal({
        text: I18n.t(`${I18N_SCOPE}.event_customization.unsaved_changes_warning`),
        icon: "warning",
        buttons: {
          cancel: { text: I18n.t("shared.actions.cancel"), visible: true },
          confirm: { text: I18n.t("shared.actions.ok").toUpperCase(), visible: true }
        },
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          setPendingRemovedEvents({ ...removedEvents });
          setHasChanges(false);
          setShowModal(false);
        }
      });
      return;
    }

    setShowModal(newState);

    if (newState && activeSlug && !events[activeSlug]) {
      fetchEvents(activeSlug, startDate, endDate);
    }
  };

  const fetchEvents = async (slug, start = startDate, end = endDate, forceReload = false) => {
    if (!slug) return;

    const dateKey = `${start}_${end}`;

    if (!forceReload &&
        events[slug] &&
        loadedDates[slug] &&
        loadedDates[slug] === dateKey) {
      return;
    }

    setIsLoading(prev => ({ ...prev, [slug]: true }));
    try {
      const response = await axios.get(`/reportei_integration/metrics_get_data`, {
        params: {
          platform: slug,
          metrics: `${slug}_events`,
          start_date: start,
          end_date: end,
          filter_removed: false
        }
      });

      if (response.data && response.data.headers && response.data.values) {
        setEvents(prev => ({
          ...prev,
          [slug]: response.data.values.map(row => row[0])
        }));

        setLoadedDates(prev => ({
          ...prev,
          [slug]: dateKey
        }));
      }
    } catch (error) {
      toastr.error(I18n.t(`${I18N_SCOPE}.event_customization.no_events`));
    } finally {
      setIsLoading(prev => ({ ...prev, [slug]: false }));
    }
  };

  const handleTabChange = (slug) => {
    setActiveSlug(slug);

    const dateKey = `${startDate}_${endDate}`;
    if (!events[slug] || !loadedDates[slug] || loadedDates[slug] !== dateKey) {
      fetchEvents(slug, startDate, endDate);
    }
  };

  const isEventRemoved = (slug, eventName) => {
    return pendingRemovedEvents[slug] && pendingRemovedEvents[slug].includes(eventName);
  };

  const toggleEvent = (slug, eventName) => {
    const updatedRemovedEvents = JSON.parse(JSON.stringify(pendingRemovedEvents));

    if (!updatedRemovedEvents[slug]) {
      updatedRemovedEvents[slug] = [];
    }

    const eventIndex = updatedRemovedEvents[slug].indexOf(eventName);

    if (eventIndex > -1) {
      updatedRemovedEvents[slug].splice(eventIndex, 1);

      if (updatedRemovedEvents[slug].length === 0) {
        delete updatedRemovedEvents[slug];
      }
    } else {
      updatedRemovedEvents[slug].push(eventName);
    }

    setPendingRemovedEvents(updatedRemovedEvents);
    setHasChanges(true);
  };

  const saveChanges = async () => {
    setSaving(true);
    try {
      const allSlugs = new Set([
        ...Object.keys(removedEvents),
        ...Object.keys(pendingRemovedEvents)
      ]);

      for (const slug of allSlugs) {
        const originalEvents = (removedEvents[slug] || []).sort();
        const pendingEvents = (pendingRemovedEvents[slug] || []).sort();

        if (JSON.stringify(originalEvents) === JSON.stringify(pendingEvents)) {
          continue;
        }

        const response = await customAxios.put("/reportei_integration/toggle_events", {
          slug: slug,
          events: pendingRemovedEvents[slug] || [],
          replace_all: true
        });

        if (response.data && response.data.success) {
          setRemovedEvents(normalizeRemovedEvents(response.data.removed_events));
        }
      }

      toastr.success(I18n.t(`${I18N_SCOPE}.event_customization.save_success`));
      setHasChanges(false);
      setShowModal(false);
    } catch (error) {
      toastr.error(I18n.t(`${I18N_SCOPE}.event_customization.save_error`));
    } finally {
      setSaving(false);
    }
  };

  if (integrations.length === 0) {
    return null;
  }

  return (
    <S.Container>
      <S.StyledCard className="rounded d-flex align-items-center p-3 w-100">
        <div className="d-flex align-items-center w-100 gap-2">
          <Card.Text
            className="d-flex align-items-center m-0 p-0 gap-1"
          >
            {parse(I18n.t(`${I18N_SCOPE}.event_customization.description_html`))}
            <a
              href={I18n.t("shared.helpscout.leadster_reportei_partnership")}
              target="_blank"
              rel="noreferrer"
              >
              {I18n.t(`${I18N_SCOPE}.event_customization.description_link`)}
            </a>
          </Card.Text>
          <Button
            variant="primary"
            onClick={handleModal}
            className="px-3 py-1"
            disabled={integrations.length === 0}
          >
            <i className="icon ti ti-settings mr-2" />
            {I18n.t(`${I18N_SCOPE}.event_customization.button`)}
          </Button>
        </div>
      </S.StyledCard>

      <EventCustomizationModal
        showModal={showModal}
        handleModal={handleModal}
        integrations={integrations}
        activeSlug={activeSlug}
        handleTabChange={handleTabChange}
        isLoading={isLoading}
        events={events}
        saving={saving}
        hasChanges={hasChanges}
        saveChanges={saveChanges}
        isEventRemoved={isEventRemoved}
        toggleEvent={toggleEvent}
      />
    </S.Container>
  );
};

export default EventCustomization;
