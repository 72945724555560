// TODO: This component must be evolved so that it is possible to display the image
// of the recommended product in the same way as it is displayed in the chatbot.
const ProductsRecommendation = ({ urls }) => {
  // TODO: 'urls' should be changed to an array of objects with the product url and image
  return (
    <>
      <p>{I18n.t("views.leadster_ai.page.analytics.history.system_recommendation")}</p>
      <ul>
        {urls.map((url, index) => (
          <li key={index}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProductsRecommendation;
