import { useState, useEffect } from "react";
import MessagesModal from "../MessagesModal";

const GenerateAdditionalBlock = ({ scriptSelectFilter, dataRange, assistantId, tab }) => {
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({});

  const showModal = () => setShow(true);
  const closeModal = () => setShow(false);

  useEffect(() => {
    const [startDate, endDate] = dataRange.current.value.split(" - ");

    setFilters({
      startDate: startDate,
      endDate: endDate,
      scriptId: scriptSelectFilter,
      tab: tab,
    });
  }, [scriptSelectFilter, dataRange.current.value]);

  return (
    <>
      <button className="btn btn-link p-0" onClick={showModal}>
        {I18n.t("views.leadster_ai.page.analytics.messages_modal.text_button")}
      </button>

      {show && (
        <MessagesModal
          handleClose={closeModal}
          title={I18n.t("views.leadster_ai.page.analytics.messages_modal.title")}
          filters={filters}
          dataRange={dataRange.current.value}
          assistantId={assistantId}
          initialTab={tab}
        />
      )}
    </>
  );
};

export default GenerateAdditionalBlock;
