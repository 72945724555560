import create from "zustand";

const supportParamsExport = create(() => ({
  disable_button: false,
  assistant_id: null,
  script_id: null,
  data_range_str: null,
  start_date: null,
  end_date: null,
}));

export default supportParamsExport;
