import parse from 'html-react-parser';
import PropTypes from "prop-types";

const HeaderContainer = ({ icon, title, description }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <div className="bg-blue-lt d-flex align-items-center justify-content-center rounded me-2 p-2">
          <i className={`ti ti-${icon} fs-2`} />
        </div>
        <span className="fs-3 fw-bold">
          {title}
        </span>
      </div>

      <p className="fs-5">
        {parse(description)}
      </p>
    </>
  );
};

HeaderContainer.prototype = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default HeaderContainer;
