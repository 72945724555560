import parse from "html-react-parser";

const CardInformation = ({ title, description, children }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="h2 mb-2"> {title}</div>
        <div className="text-secondary fs-4">{description ? parse(description) : ""}</div>
        {children && <div className="mt-2">{children}</div>}
      </div>
    </div>
  );
};

export default CardInformation;
