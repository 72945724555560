const SelectIntentionAlert = () => {
  const I18N_SCOPE = "views.manage_flows.script_editor.ia_interaction";

  return (
    <div className="alert alert-primary mb-0">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-3">
            {I18n.t(`${I18N_SCOPE}.settings.intent.alert.title`)}
          </div>

          <p className="mb-1 text-muted fs-4">
            {I18n.t(`${I18N_SCOPE}.settings.intent.alert.describe`)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SelectIntentionAlert;
