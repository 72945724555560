import { useRef, useState } from "react";

import AiCalls from "@/components/dashboard/PageRank/AiCalls";
import HeaderContainer from "@/components/dashboard/PageRank/RowData/FlowOptions/HeaderContainer";

import swal from "sweetalert";

import axios from "@/lib/axios";

import * as S from "../style";



const CreateDefaultFlow = ({ pageUrl }) => {
  const [isLoading, setIsLoading] = useState(false);

  let call = useRef("");
  let suggestedCall = useRef("");

  const handleNewFlow = () => {
    setIsLoading(true);

    const payload = {
      page_url: pageUrl,
      ai_call: call.current,
      suggested_call: suggestedCall.current,
    };

    axios.post("/page-ranks/analytics/create-script", payload)
      .then(({ data }) => {
        swal(I18n.t("views.dashboard.page_rank.create_flow_modal.success_create_flow"), "", "success")
          .then(() => window.location.replace(`/scripts/${data.id}/edit`));
      })
      .catch(() => {
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
        setIsLoading(false);
      });
  };

  const selectedCall = (value) => {
    call.current = value
  };

  const selectedSuggestionCall = (value) => {
    suggestedCall.current = value
  };

  const handleButtonClick = () => {
    handleNewFlow();
    MixpanelService.track("page_rank_dashboard_new_flow");
  };

  return (
    <div className="card shadow-sm">
      <S.TopBorder />
      <div className="card-body mt-3">
        <HeaderContainer
          icon="message-plus"
          title={I18n.t("views.dashboard.page_rank.create_flow_modal.new_flow.title")}
          description={I18n.t("views.dashboard.page_rank.create_flow_modal.new_flow.description_html")}
        />
        <AiCalls
          url={pageUrl}
          selectedCall={selectedCall}
          selectedSuggestionCall={selectedSuggestionCall}
        />
        <button
          type="button"
          className="btn btn-outline-primary w-100 mb-3 fw-bold"
          onClick={handleButtonClick}
          disabled={isLoading}
        >
          {isLoading && <span className="spinner-border spinner-border-sm me-2 flex-shrink-0" />}
          {I18n.t("shared.actions.create_flow")}
        </button>
      </div>
    </div>
  );
};

export default CreateDefaultFlow;
