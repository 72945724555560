import { useState, useEffect } from "react";

import useCookie from "@/hooks/useCookie";

import parse from "html-react-parser";

const I18N_SCOPE = "views.manage_flows.conditional_block.configurations";
const COOKIE_KEY = "leadster-new-finish-flow";
const EXPIRE_IN_DAYS = 5;

const useAlertInfo = (finishFlow, rejectLead) => {
  const { value: cookieValue, setValue: setCookieValue } = useCookie(COOKIE_KEY, { expires: EXPIRE_IN_DAYS });

  const [icon, setIcon] = useState("");
  const [message, setMessage] = useState("");

  const show = finishFlow || rejectLead || !cookieValue;
  const isClosable = !finishFlow && !rejectLead && !cookieValue;

  useEffect(() => {
    const { icon, message } = getIconAndMessage();

    setIcon(icon);
    setMessage(message);
  }, [finishFlow, rejectLead]);

  const onClose = () => {
    setCookieValue(true);
  };

  const getIconAndMessage = () => {
    let icon, message = "";

    if (!cookieValue) {
      icon = "speakerphone";
      message = parse(I18n.t(`${I18N_SCOPE}.alerts.new_option_finish_flow_html`));
    }

    if (rejectLead && finishFlow) {
      icon = "message-check";
      message = I18n.t(`${I18N_SCOPE}.alerts.reject_and_finish`);
    } else if (rejectLead) {
      icon = "user-x";
      message = I18n.t(`${I18N_SCOPE}.alerts.reject_lead`);
    } else if (finishFlow) {
      icon = "message-check";
      message = I18n.t(`${I18N_SCOPE}.alerts.finish_flow`);
    }

    return { icon, message };
  };

  return { show, icon, message, isClosable, onClose };
};

export default useAlertInfo;
