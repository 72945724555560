import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const ChatModal = styled(Modal)`
  .modal-dialog {
    max-width: none;
    width: auto;
    margin: 0;
  }

  .modal-content {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .modal-body {
    padding: 0;
    overflow: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 22px;
  right: 28px;
  z-index: 9999;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;

  i {
    font-size: 25px;
  }
`;

export const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
  display: block;
`;
