import styled from "styled-components";

export const Avatar = styled.table.attrs({
  className: "avatar avatar-sm avatar-rounded me-3 px-3",
})`
  background-image: url("https://cdn.leadster.com.br/leadster/images/page_ranks/profile-conversion-call.png");
`;

export const CarouselContainer = styled.div`
  .rec-item-wrapper {
    width: 359px !important;
  }

  .rec-carousel-item {
    display: flex;
    align-items: stretch;
  }
`

export const EditorContainer = styled.div`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 305px;
  background-color: white;
  color: #333333;
  border-radius: 8px;
  padding: 6px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));

  &::before {
    content: "";
    position: absolute;
    border: 5px solid #fff;
    left: -4px;
    top: calc(50% - 5px);
    transform: rotate(45deg);
  }

  p {
    margin-top: 0;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mce-content-body {
    color: #555;
    text-align: left;
    font-size: 0.9rem;
    border: none;
    overflow: auto;
    border-radius: 0;
    padding: initial;
    min-height: 20px;
    max-height: 60px;
    scrollbar-width: 0px;
  }
`;
