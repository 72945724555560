import React, { useEffect } from "react";

import { ROUTES, getRoutesWithoutErrorPage } from "../routes";

import NavBar from "@/components/leadster_ia/NavBar";
import ContentLimit from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ContentLimit";
import TemplateInfo from "@/components/leadster_ia/subpages/CreateYourAiAssistant/TemplateInfo";
import ResponseLimit from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ResponseLimit";
import RedirectButton from "@/components/leadster_ia/shared/RedirectButton";
import SkeletonLoader from "./SkeletonLoader";

import { Card, Stack } from "react-bootstrap";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import { useAssistantContext } from "@/contexts/AssistantContext";
import useSetEditAssistant from "./hooks/useSetEditAssistant";
import useWatchPendingContent from "./hooks/useWatchPendingContent";

const ASSISTANT_ROUTES = ROUTES.createAssistant;

const linksIcon = [
  <i className="ti ti-mood-smile-beam me-2 text-primary" />,
  <i className="ti ti-cloud-upload me-2 text-primary" />,
  <i className="ti ti-messages me-2 text-primary" />,
];

const Content = () => {
  return (
    <>
      <ContentLimit />
      <TemplateInfo />
    </>
  );
};

const CreateYourAiAssistant = () => {
  useWatchPendingContent();

  const { isLoadingAssistant } = useSetEditAssistant();
  const { assistant, settings, updateSettings } = useAssistantContext();

  let { path } = useRouteMatch();
  const { assistantId } = useParams();

  const createYourAiAssistantSettings = settings.pages.assistant.subpages.createYourAiAssistant;
  const routesWithoutErrorPage = getRoutesWithoutErrorPage(ASSISTANT_ROUTES);

  useEffect(() => {
    updateSettings({
      title: I18n.t("views.leadster_ai.title"),
      description: I18n.t("views.leadster_ai.description"),
      breadcrumb: {
        to: "/ai/leadster",
        label: I18n.t('views.leadster_ai.page.back_button.label', {
          page: I18n.t('views.leadster_ai.page.back_button.assistant_page')
        })
      }
    });
  }, []);

  return (
    <div className="w-100">
      <Stack className="w-100 align-items-start" direction="horizontal" gap={3}>
        <div className="d-flex flex-column gap-3" style={{ minWidth: 340, maxWidth: 340 }}>
          <NavBar routes={routesWithoutErrorPage} icons={linksIcon} />
          <RedirectButton
            to={`/ai/leadster/analytics/${assistantId}/dashboard`}
            label={I18n.t("views.leadster_ai.page.redirect_button.analytics")}
            icon={"ti ti-chart-dots icon"}
          />
          <Switch>
            <Route path={`${path}/content`} component={Content} />
            <Route path={`${path}/test`} component={ResponseLimit} />
          </Switch>
        </div>

        {isLoadingAssistant ? (
          <SkeletonLoader />
        ) : (
          <Card className="w-100">
            <div className="card-header flex-column align-items-start">
              <div className="card-title mb-1">
                {createYourAiAssistantSettings.title}
              </div>

              <div className="card-subtitle">
                {createYourAiAssistantSettings.description}
              </div>
            </div>

            <Card.Body>
              <Switch>
                {ASSISTANT_ROUTES
                  .filter((route) => !(route.requireAssistant && !assistant))
                  .map((route, index) => {
                    return (
                      <Route
                        key={index}
                        exact={index === 0 && route.path === ""}
                        path={`${path}${route.path}`}
                        component={route.component}
                      />
                    );
                  })}
              </Switch>
            </Card.Body>
          </Card>
        )}
      </Stack>
    </div>
  );
};

export default CreateYourAiAssistant;
