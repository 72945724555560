import create from "zustand";

const historicParamsExport = create(() => ({
  assistant_id: null,
  script_id: null,
  conversation: null,
  review: null,
}));

export default historicParamsExport;
