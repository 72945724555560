import useCookie from "@/hooks/useCookie";

import * as S from "../style";

const EXPIRE_IN_DAYS = 5000;
const COOKIE_KEY = "banner-page-rank";

const BannerPageRank = () => {
  const { value: cookieValue, setValue: setCookieValue } = useCookie(COOKIE_KEY, { expires: EXPIRE_IN_DAYS });

  if (cookieValue) return;

  const handleClose = () => {
    OpenpanelService.track("close_banner_page_rank");
    setCookieValue(true);
  };

  return (
    <S.AlertContainer>
      <a href="#page-rank-section">
        <img
          className="img-fluid w-100 h-100 position-absolute top-0 start-0"
          src={I18n.t("views.dashboard.page_rank.banners.page_rank")}
        />
      </a>
      <a
        className="btn-close"
        onClick={handleClose}
      />
    </S.AlertContainer>
  );
};

export default BannerPageRank;
