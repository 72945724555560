import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";

const WhatsAppButton = ({ children }) => {
  return (
    <a
      href={buildWhatsAppLink({
        number: getCustomVariable("whatsapp_number_sales_1"),
        message: I18n.t("views.manage_flows.scheduler_block.hire_feature_contact_message"),
      })}
      className="btn btn-success"
      target="_blank"
    >
      {children}
    </a>
  );
};

export default WhatsAppButton;
