import { Button } from "react-bootstrap";

import { useHistory } from "react-router-dom";

const BackButton = ({ to, label }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
  };

  return (
      <Button
        onClick={handleClick}
        className="btn btn-link p-0 text-primary mb-3"
      >
        <i className={"text-primary ti ti-chevron-left"}></i>
        {label}
      </Button>
  );
};

export default BackButton;
