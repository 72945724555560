import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";
import { can } from "@/lib/user-permission";

import useCurrentUserStore from "@/store/currentUserStore";

import parse from "html-react-parser";

import * as S from "../style"

const RenderCardInfo = ({ generateCall, isLoading, error }) => {
  const hasPermission = can({ action: "manage", subject: "generate_ai_calls" });
  const { companyId } = useCurrentUserStore((state) => state.currentUser);

  const renderCard = () => {
    if (!generateCall && hasPermission) {
      return (
        <S.CardInfo>
          <p className="mb-1 text-black">
            {parse(I18n.t("views.dashboard.page_rank.create_flow_modal.ai_calls_description_html"))}
            <a
              className="text-primary ms-1"
              href={I18n.t("views.dashboard.page_rank.create_flow_modal.learn_more")}
              target="_blank"
            >
              {I18n.t("shared.actions.learn_more")}
            </a>
          </p>
        </S.CardInfo>
      );
    }

    if (!generateCall) {
      return (
        <S.CardInfo>
          <p className="mb-1 text-black">
            {I18n.t("views.dashboard.page_rank.create_flow_modal.ai_calls_block")}
            <a
              className="text-primary ms-1"
              href={buildWhatsAppLink({
                number: getCustomVariable("whatsapp_number_sales_1"),
                message: I18n.t("services.chatgpt.whatsapp_text.block_alert", { company_id: companyId }),
              })}
              target="_blank"
            >
              {I18n.t("shared.actions.talk_to_our_team")}
            </a>
          </p>
        </S.CardInfo>
      );
    }

    if (isLoading) {
      return (
        <S.CardInfo>
          <div className="d-flex align-items-center">
            <div className="spinner-border text-primary me-3" role="status" />
            <span className="mb-1 text-black">
              {parse(I18n.t("views.dashboard.page_rank.create_flow_modal.generating_calls_html"))}
            </span>
          </div>
        </S.CardInfo>
      );
    }

    if(error) {
      return (
        <S.CardInfo color="red-lt">
          <div className="small overflow-auto">
            {parse(error)}
          </div>
        </S.CardInfo>
      );
    }
  };

  return (
    <>
      {renderCard()}
    </>
  );
};

export default RenderCardInfo;
