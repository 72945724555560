import { Modal, Button, Image } from "react-bootstrap";

import parse from "html-react-parser";

import IntegrateReporteiButton from "../IntegrateReporteiButton";

const I18N_SCOPE = "views.dashboard.integrate_reportei.start_trial_modal";

const StartTrialModalReportei = ({ onHide }) => {
  return (
    <Modal
      show={true}
      onHide={onHide}
      className="modal-blur"
      backdrop="static"
      size="lg"
      centered
    >
      <Modal.Body className="p-4 text-center">
        <Image
          src="https://cdn.leadster.com.br/dashboard/ai/modal_banner.webp"
          alt="Google Ads"
          rounded
        />
        <h2 className="my-3">{I18n.t(`${I18N_SCOPE}.title`)}</h2>
        <div className="mb-4 d-flex row justify-content-center gap-1">
          <p className="p-0 m-0 fs-3">{parse(I18n.t(`${I18N_SCOPE}.description_1`))}</p>
          <p className="p-0 m-0 fs-3">{parse(I18n.t(`${I18N_SCOPE}.description_2`))}</p>
        </div>
        <div className="d-flex justify-content-between gap-3">
          <Button variant="outline-secondary" onClick={onHide}>
            {I18n.t(`${I18N_SCOPE}.button.not_now`)}
          </Button>
          <IntegrateReporteiButton
            onCloseModal={onHide}
            icon={false}
            buttonName={I18n.t(`${I18N_SCOPE}.button.ok`)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StartTrialModalReportei;
