import { useRouteMatch } from "react-router-dom";

import GuideModal from "@/components/shared/GuideModal";
import guideContents from "@/components/leadster_ia_pages/AiAssistant/subpages/guideContents";

import useCurrentUserStore from "@/store/currentUserStore";

import axios from "@/lib/axios";

const StartTrialGuide = ({modal}) => {
  const {
    isAdministrator,
    plugins: { leadsterAi: { isTrialStarted, isTrialing } },
  } = useCurrentUserStore((state) => state.currentUser);

  const { url } = useRouteMatch();

  const startTrialEnabled = !isTrialStarted && !isTrialing && !isAdministrator;

  const startTrialButtonClick = () => {
    if (isAdministrator) {
      return navigateToAssistant();
    }

    axios
      .post("/ai/leadster/start-trial")
      .then(() => navigateToAssistant())
      .catch(() => {
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error")
          .then(() => navigateToAssistant());
      });
  };

  const navigateToAssistant = () => {
    window.location.href = `${url.replace(/\/$/, "")}/assistants`;
  };

  return(
    <>
      <GuideModal
        autoShow={startTrialEnabled}
        isClosable
        guideContents={guideContents}
        finishButtonText={I18n.t("guide_modal.leadster_ai.onboard.start_trial_btn")}
        finishButtonPosition="center"
        finishCallback={startTrialButtonClick}
        ref={modal}
      />
    </>
  );
};

export default StartTrialGuide;
