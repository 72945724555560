import { useEffect, useState } from "react";

import Assistant from "./Assistant";

import Alert from "react-bootstrap/Alert"

import usePagination from "@/hooks/usePagination";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";

import axios from "@/lib/axios";

import * as Styled from "./styles";

const AssistantSelector = ({ onDefinition, hasPermission, assistantId }) => {
  const [assistants, setAssistants] = useState([]);
  const [currentAssistant, setCurrentAssistant] = useState(null);
  const [isLoadingAssistant, setIsLoadingAssistant] = useState(false);
  const [linkedAssistantAlert, setLinkedAssistantAlert] = useState(!!assistantId);

  const {
    data,
    paginationData,
    isLoading,
    setCurrentPage,
  } = usePagination("/ai/assistants", {
    defaultDataValue: [],
    defaultPerPage: 5,
    dataIndex: 'assistants',
    errorFeedbackLib: false,
  });

  const { targetRef } = useInfiniteScroll({
    callback: () => setCurrentPage(paginationData.next_page),
    threshold: 0.3,
    enabled: !!paginationData.next_page,
  });

  const findAssistant = (id) => {
    axios
      .get(`/ai/assistants/${id}`)
      .then(({ data }) => setCurrentAssistant(data.assistant))
      .finally(() => setIsLoadingAssistant(false));
  };

  const handleAssistantChange = (event) => {
    MixpanelService.track("leadster_ai_block_select_assistant");

    const assistant = assistants.find((assistant) => assistant.id == event.target.value)

    if (assistant) {
      setLinkedAssistantAlert(assistant.id);
      onDefinition({ botId: assistant.id });
      setCurrentAssistant(assistant);
    }
  };

  useEffect(() => {
    if (assistantId) {
      findAssistant(assistantId);
    };
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setAssistants((prev) => [...prev, ...data]);
    };
  }, [data]);

  useEffect(() => {
    if(!assistantId && currentAssistant?.id) {
      const persistedAssistant = assistantId || currentAssistant?.id;
      assistantId = persistedAssistant;
      onDefinition({ botId: persistedAssistant });
    }
  }, [assistantId]);

  return (
    <>
      <div className='form-group'>
        <div className="row g-3 mb-3">
          <div className="col-lg-6">
            <div className="list-group list-group-flush border rounded">
              <div className="list-group-header px-2">
                <div className="row">
                  <Styled.Subtitle className="col p-2 mx-2">
                    {I18n.t("views.manage_flows.script_editor.ia_interaction.subtitle_1")}
                  </Styled.Subtitle>
                </div>
              </div>

              <div className="list-group-item px-2">
                {(linkedAssistantAlert && assistants.length > 0) &&
                  <Alert variant="info">
                    <Alert.Heading className="fw-bold">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.linked_assistant.title")}
                    </Alert.Heading>

                    <p className="text-body mb-0">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.linked_assistant.message")}
                    </p>
                  </Alert>
                }

                {(!linkedAssistantAlert && assistants.length == 0) &&
                  <Alert variant="danger">
                    <Alert.Heading className="fw-bold">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.assistant_not_created.title")}
                    </Alert.Heading>

                    <p className="text-body mb-0">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.assistant_not_created.message")}
                    </p>
                  </Alert>
                }

                <Styled.Scroll className="overflow-scroll">
                  {assistants.map((assistant, index) => {
                    return (
                      <Assistant
                        key={index}
                        nameGroup="assistant-checked"
                        onChange={handleAssistantChange}
                        value={assistant.id}
                        name={assistant.name}
                        defaultChecked={assistant.id == currentAssistant?.id}
                        disabled={!hasPermission}
                        hidden={assistant.id == currentAssistant?.id}
                        typeOfAssistant={assistant.type_of_assistant}
                      />
                    )}
                  )}

                  {isLoading &&
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  }
                  {!isLoading > 0 &&
                    <div ref={targetRef} style={{ height: "2px" }} />
                  }
                </Styled.Scroll>
              </div>

              <Styled.NewAssistent className="list-group-footer px-2">
                <a href={`${window.location.origin}/ai/leadster/assistants`} target="_blank">
                  <i className="ti ti-plus me-2" /> {I18n.t("views.manage_flows.script_editor.ia_interaction.create_ai_assistant")}
                </a>
              </Styled.NewAssistent>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="list-group list-group-flush border rounded">
              <div className="list-group-header px-2">
                <div className="row">
                  <Styled.Subtitle className="col p-2 mx-2">
                    {I18n.t("views.manage_flows.script_editor.ia_interaction.subtitle_2")}
                  </Styled.Subtitle>
                </div>
              </div>
              <div className="list-group-item px-2">
                {currentAssistant &&
                  <Assistant
                    value={currentAssistant.id}
                    name={currentAssistant.name}
                    defaultChecked={true}
                    disabled={true}
                    typeOfAssistant={currentAssistant.type_of_assistant}
                    loading={isLoadingAssistant}
                  />
                }

                {!linkedAssistantAlert &&
                  <Alert variant="danger">
                    <Alert.Heading className="fw-bold">
                      {I18n.t("views.manage_flows.script_editor.ia_interaction.unlinked_assistant.title")}
                    </Alert.Heading>

                    <div className="text-body">
                      <p>{I18n.t("views.manage_flows.script_editor.ia_interaction.unlinked_assistant.message_01")}</p>
                      <p className="text-body mb-0">{I18n.t("views.manage_flows.script_editor.ia_interaction.unlinked_assistant.message_02")}</p>
                    </div>
                  </Alert>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default AssistantSelector;
