import { useEffect, useState } from "react";

import CarouselConversionCalls from "@/components/dashboard/PageRank/AiCalls/CarouselConversionCalls";
import RenderCardInfo from "@/components/dashboard/PageRank/AiCalls/RenderCardInfo";

import useGenerateCallsAi from "@/hooks/useGenerateCallsAi";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import parse from "html-react-parser";

import * as S from "./style";

const DEFAULT_CONV_CALL_INDEX = 0;

const aiCalls = ({ url, selectedCall, selectedSuggestionCall }) => {
  const { isLoading, error, callsList, fetchCalls } = useGenerateCallsAi(url, false);
  const [generateCalls, setGenerateCalls] = useState(false);
  const [selectedConversionCallIndex, setSelectedConversionCallIndex] = useState(DEFAULT_CONV_CALL_INDEX);

  useEffect(() => {
    if (generateCalls) {
      fetchCalls();
    }
  }, [generateCalls]);

  const handleGenerateCalls = () => {
    if (generateCalls) {
      setSelectedConversionCallIndex(DEFAULT_CONV_CALL_INDEX);
      selectedCall("");
      selectedSuggestionCall("");
    }

    setGenerateCalls(!generateCalls);
  }

  const updateCurrentCall = (call) => {
    selectedCall(call);
  };

  const handleUpdateIndex = (index) => {
    selectedSuggestionCall(callsList[index]);
  };

  const handleSetSelectedIndex = (index) => {
    setSelectedConversionCallIndex(index);
  };

  return (
    <>
      <div className="border-top border-2 border-info my-4" />
      <S.CardContainer>
        <label className="form-check form-switch mb-0">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            checked={generateCalls}
            onChange={handleGenerateCalls}
          />
          <span className="form-check-label cursor-pointer fw-bold">
          {I18n.t("views.dashboard.page_rank.create_flow_modal.ai_calls")}
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <Tooltip>
                  {parse(I18n.t("views.dashboard.page_rank.create_flow_modal.new_flow.ai_calls_info_html"))}
                </Tooltip>
              }
            >
          <i className="ti ti-help-circle fs-3 ms-2" />
        </OverlayTrigger>
        </span>
        </label>

        {generateCalls && callsList.length > 0 ? (
          <div className="mt-4">
            <S.CardCarousel>
              <CarouselConversionCalls
                conversionCallsList={callsList}
                selectedConversionCallIndex={selectedConversionCallIndex}
                setSelectedConversionCallIndex={handleSetSelectedIndex}
                onChangeIndex={handleUpdateIndex}
                onChangeCall={updateCurrentCall}
              />
            </S.CardCarousel>
          </div>
        ) : (
          <RenderCardInfo generateCall={generateCalls} isLoading={isLoading} error={error} />
        )}
      </S.CardContainer>
      <div className="border-top border-2 border-info my-4" />
    </>
  );
};

export default aiCalls;
