import { useEffect, useState } from "react";

import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

import EmailCreatableSelect from "@/components/leadster_ia/shared/EmailCreatableSelect";
import historicParamsExport from "@/components/leadster_ia/cards/Analytics/store/historicParamsExport";

import { classBinding } from "@/utils/helpers";

import {
  handleKeyDown,
  requestExportData,
} from "../ModalUtils";

const I18N_SCOPE = "views.leadster_ai.export.modal";

const ModalHistoricExport = ({show = false, onClose}) => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();

  const [emails, setEmails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const historicParams = historicParamsExport.getState();

  const handleEmails = (val) => {
    setEmails(val);
  }

  const onSubmit = async (data) => {
    try{
      setIsSubmitting(true);

      const url = "/ai/leadster/analytics/export-histories";

      const params = {
        assistant_id: data.assistant_id,
        script_id: data.script_id,
        conversation: data.conversation,
        review: data.review,
        emails: emails,
      };

      await requestExportData(url, params);

    } catch(exception) {
      swal("", exception.message, "error").then(_r => "");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    reset();
  }, [historicParams]);

  return (
    <Modal show={show} className="modal-blur" backdrop="static" size="lg" centered onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">{I18n.t(`${I18N_SCOPE}.historic.title`)}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <Modal.Body>
          <Row>
            <p>{I18n.t(`${I18N_SCOPE}.historic.subtitle`)}</p>
          </Row>

          <Form.Control type="hidden" {...register("assistant_id")} value={historicParams.assistant_id} />
          <Form.Control type="hidden" {...register("script_id")} value={historicParams.script_id} />
          <Form.Control type="hidden" {...register("conversation")} value={historicParams.conversation} />
          <Form.Control type="hidden" {...register("review")} value={historicParams.review} />

          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="fs-3"><b>{I18n.t(`${I18N_SCOPE}.mail_label`)}</b></Form.Label>
                <EmailCreatableSelect onBlur={handleEmails} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" className={classBinding({ "btn-loading": isSubmitting}, "btn btn-primary")}>
            <i className="ti ti-table-export me-1"/>
            {I18n.t(`${I18N_SCOPE}.button_label`)}
          </Button>
        </Modal.Footer>

      </Form>
    </Modal>
  );
};

export default ModalHistoricExport;
