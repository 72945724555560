import { useState } from "react";
import { Button, Card, Form, Image, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { useAssistantContext } from "@/contexts/AssistantContext";
import { classBinding } from "@/utils/helpers";
import axios from "@/lib/axios";

import Alert from "../Alert";
import ListMultipleUrls from "./components/ListMultipleUrls";
import HelpPopover from "@/components/shared/HelpPopover";
import ContentUsageModal from "../ContentUsageModal";

const URLS = {
  MULTI_URL_DEFAULT: (id) => `/ai/multi_url_processes/assistant/${id}/create-default`,
  VTEX: (id) => `/ai/multi_url_processes/assistant/${id}/create-vtex`,
  SITEMAP: (id) => `/ai/multi_url_processes/assistant/${id}/create-sitemap`,
  GOOGLE_SHOPPING: (id) => `/ai/multi_url_processes/assistant/${id}/create-google-shopping`,
};

const ASSISTANT_TYPES = {
  VTEX: "vtex",
  SITEMAP: "sitemap",
  GOOGLE_SHOPPING: "google_shopping",
  E_COMMERCE: "e_commerce",
};

const AddWebsiteLink = ({
  type,
  titleKey,
  descriptionKey,
  inputLabel,
  recommendationIsAvailable,
  imageSrc,
  isSelected,
  onSelect,
  isSelectionDisabled,
  isListMultipleUrls,
}) => {
  const {
    addPendingBase,
    assistant,
    updateAssistant,
    setProcessingAddContentType,
    processingAddContentType,
    setDisabledContent,
  } = useAssistantContext();

  const {
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [contentValue, setContentValue] = useState("");
  const [tempData, setTempData] = useState(null);

  const pendingBase = assistant.pending_knowledge_base;
  const isDisabled = !!pendingBase || isLoading || isSelectionDisabled;
  const isInvalid = errors.hasOwnProperty("url");
  const typeOfAssistant = assistant.type_of_assistant;

  const currentUrlApi = (type) => {
    switch (type) {
      case ASSISTANT_TYPES.VTEX:
        return URLS.VTEX(assistant.id);
      case ASSISTANT_TYPES.SITEMAP:
        return URLS.SITEMAP(assistant.id);
      case ASSISTANT_TYPES.GOOGLE_SHOPPING:
        return URLS.GOOGLE_SHOPPING(assistant.id);
      default:
        return URLS.MULTI_URL_DEFAULT(assistant.id);
    }
  };

  const handleMultipleUrl = (data, usageChoice) => {
    const payload = { ...data, usage: usageChoice };
    const urlApi = currentUrlApi(type);

    updateAssistant({
      status_alert: "searching_pages",
      path: data.url,
      has_multiple_url_process_data: true,
      processing_type: type,
    });

    setProcessingAddContentType(type);
    setDisabledContent(true);

    axios
      .post(urlApi, payload)
      .then(({ data }) => {
        addPendingBase(data.knowledge_base);
        reset();

        if (typeOfAssistant === ASSISTANT_TYPES.E_COMMERCE && !isListMultipleUrls) {
          window.location.reload();
        } else {
          setShowModal(true);
        }
      })
      .catch(() => {
        toastr.error(I18n.t("request_feedback_alert.error.message"));

        if (typeOfAssistant === ASSISTANT_TYPES.E_COMMERCE) {
          setDisabledContent(false);
          setTimeout(() => window.location.reload(), 5000);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleDoneModal = () => {
    toastr.success(I18n.t("views.leadster_ai.page.add_content.add_links.alerts.success_processed"));
  };

  const onSubmit = (data) => {
    if (isDisabled) return;
    setIsLoading(true);
    MixpanelService.track(mixpanelEvent(type));

    if (typeOfAssistant === "e_commerce") {
      setShowUsageModal(true);
      setContentValue(data.url);
      setTempData(data);
    } else {
      handleMultipleUrl(data, "generic");
    }
  };

  const handleUsageConfirm = (usageChoice) => {
    setShowUsageModal(false);
    if (tempData) {
      handleMultipleUrl(tempData, usageChoice);
      setTempData(null);
    }
  };

  const mixpanelEvent = (type) => {
    switch (type) {
      case ASSISTANT_TYPES.VTEX:
        return "leadster_ai_add_vtex_url";
      case ASSISTANT_TYPES.SITEMAP:
        return "leadster_ai_add_sitemap_url";
      case ASSISTANT_TYPES.GOOGLE_SHOPPING:
        return "leadster_ai_add_google_shopping_url";
      default:
        return "leadster_ai_add_multiple_url";
    }
  };

  const handleClick = () => {
    if (!isDisabled) {
      onSelect(type);
    }
  };

  const handleHideUsageModal = () => {
    setShowUsageModal(false);
    setTempData(null);
    setContentValue("");
    window.location.reload();
  };

  function showProcessingAlert(type) {
    if (processingAddContentType === type) {
      return <Alert onCancel={() => window.location.reload()} />;
    }

    return null;
  }

  return (
    <Card
      className={`mb-3 border-2 ${isSelectionDisabled ? "bg-light text-muted opacity-50" : ""}`}
      border={isSelected ? "primary" : "light"}
      onClick={handleClick}
      style={{ cursor: isSelectionDisabled ? "not-allowed" : "pointer" }}
    >
      <Card.Body className="p-0">
        <div className="d-flex align-items-center mb-3 p-3">
          <Form.Check
            type="radio"
            name={`add-url-type-${type}`}
            id={`add-${type}-radio`}
            checked={isSelected}
            onChange={() => !isDisabled && onSelect(type)}
            className="me-3"
            disabled={isDisabled}
          />
          <div className="d-flex align-items-center flex-grow-1">
            {imageSrc && (
              <div className="d-flex justify-content-center align-items-center me-3 bg-primary-lt p-2 rounded">
                <Image src={imageSrc} alt={`${type} icon`} width={20} height={20} />
              </div>
            )}
            <div>
              <div className="d-flex align-items-center">
                <p className="mb-1 fw-bold fs-4">{I18n.t(titleKey)}</p>
                {recommendationIsAvailable && (
                  <div className="mb-1 fw-bold fs-5 rounded bg-primary-lt px-3 py-1 ms-2">
                    {I18n.t("views.leadster_ai.page.add_content.add_website.products_recommendation")}
                    <HelpPopover
                      className="ms-2"
                      content={I18n.t("views.leadster_ai.page.add_content.add_website.help_popover_html")}
                    />
                  </div>
                )}
              </div>
              <p className="mb-0 fs-4">{I18n.t(descriptionKey)}</p>
            </div>
          </div>
        </div>

        {isSelected && (
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-3 p-3 border-top">
            {showProcessingAlert(type)}
            <Form.Label className="mb-2 pt-3 fw-bold fs-4">{I18n.t(inputLabel)}</Form.Label>
            <InputGroup className={classBinding({ "is-invalid": isInvalid })}>
              <Form.Control
                type="text"
                isInvalid={isInvalid}
                placeholder={I18n.t("views.leadster_ai.page.add_content.add_links.placeholder")}
                disabled={isDisabled}
                {...register("url", { required: true })}
                className={`form-control ${isSelected ? "border-primary border-2" : ""}`}
              />
              <Button variant="primary" type="submit" disabled={isDisabled}>
                <i className="icon ti ti-world-search me-2" />
                {I18n.t("views.leadster_ai.page.add_content.add_links.add_page")}
              </Button>
            </InputGroup>

            <Form.Control.Feedback type="invalid">{_.get(errors, "url.message")}</Form.Control.Feedback>
          </Form>
        )}
      </Card.Body>

      {showModal && <ListMultipleUrls show={showModal} onClose={handleCloseModal} onDone={handleDoneModal} />}

      <ContentUsageModal
        show={showUsageModal}
        onHide={handleHideUsageModal}
        onConfirm={handleUsageConfirm}
        contentValue={contentValue}
      />
    </Card>
  );
};

export default AddWebsiteLink;
