import { Form, Spinner } from "react-bootstrap";
import * as S from "./styles";

const I18N_SCOPE = "views.users.edit.company_form";

const EventsTable = ({
  slug,
  isLoading,
  events,
  isEventRemoved,
  toggleEvent,
  saving
}) => {
  if (isLoading) {
    return (
      <div className="text-center py-5">
        <Spinner animation="border" className="text-blue" role="status" />
      </div>
    );
  }

  if (!events || events.length === 0) {
    return (
      <div className="text-center py-5">
        <p>{I18n.t(`${I18N_SCOPE}.event_customization.no_events`)}</p>
      </div>
    );
  }

  return (
    <S.TableContainer>
      <S.Table>
        <S.Header>
          <S.Cell className="py-3">{I18n.t(`${I18N_SCOPE}.event_customization.table_columns.event_name`)}</S.Cell>
          <S.Cell className="py-3">{I18n.t(`${I18N_SCOPE}.event_customization.table_columns.display_event`)}</S.Cell>
        </S.Header>
        <S.Body>
          {events.map((eventName) => {
            const isRemoved = isEventRemoved(slug, eventName);
            const switchChecked = !isRemoved;

            return (
              <S.Row key={eventName}>
                <S.Cell>{eventName}</S.Cell>
                <S.Cell>
                  <Form.Check
                    type="switch"
                    id={`event-switch-${slug}-${eventName}`}
                    checked={switchChecked}
                    onChange={() => toggleEvent(slug, eventName)}
                    disabled={saving}
                  />
                </S.Cell>
              </S.Row>
            );
          })}
        </S.Body>
      </S.Table>
    </S.TableContainer>
  );
};

export default EventsTable;
