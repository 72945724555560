import styled from "styled-components";

export const UlAiCalls = styled.div.attrs({
  id: "ai_calls",
  className: "card card-active border-cyan my-4 bg-blue-lt border-2",
})`
  min-height: 60px;
  min-width: 240px;

  &:hover {
    cursor: pointer;
  }
`;
