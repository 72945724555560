import ReporteiSwitchAvailability from "../shared/ReporteiSwitchAvailability";

const I18N_SCOPE = "views.users.edit.company_form.ads_options";

const integrationsConfig = [
  {
    slug: "google_adwords",
    label: I18n.t(`${I18N_SCOPE}.google_adwords`),
  },
  {
    slug: "google_analytics_4",
    label: I18n.t(`${I18N_SCOPE}.google_analytics_4`),
  },
  {
    slug: "facebook_ads",
    label: I18n.t(`${I18N_SCOPE}.facebook_ads`),
  },
  {
    slug: "linkedin_ads",
    label: I18n.t(`${I18N_SCOPE}.linkedin_ads`),
  },
];

const DashboardReporteiSwitchAvailability = () => {
  return (
    <>
      {integrationsConfig.map((integration) => (
        <div key={integration.slug} className="mb-1">
          <ReporteiSwitchAvailability
            slug={integration.slug}
            label={I18n.t(`${I18N_SCOPE}.show_reportei_ads_message`, { ads: integration.label })}
          />
        </div>
      ))}
    </>
  );
};

export default DashboardReporteiSwitchAvailability;
