import { Button } from "react-bootstrap";

import useCurrentUserStore from "@/store/currentUserStore";

import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";

const ChatWithUs = ({smallMsg, whatsMsg}) => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  return(
    <>
      <hr className="my-3" />

      <div className="fw-medium p-2 bg-primary-lt small rounded mb-2">
        {smallMsg}
      </div>

      <div className="d-grid gap-2">
        <div className="fw-medium small text-center">
          {I18n.t("views.leadster_ai.page.response_limit.alert_buy")}
        </div>

        {isNational &&
          <a
            href={
              buildWhatsAppLink(
                {
                  number: getCustomVariable("whatsapp_number_sales_1"),
                  message: whatsMsg,
                }
              )
            }
            className="btn btn-success w-100"
            target="_blank"
            rel="noopener"
          >
            <i className="ti ti-brand-whatsapp icon" />
            {I18n.t("views.leadster_ai.page.response_limit.contact_us")}
          </a>
        }

        {!isNational && (
          <>
            <Button
              variant="primary"
              className="w-100 contact-tidio"
              data-tidio-message={I18n.t("shared.tidio.subscription_request_message")}
            >
              <i className="ti ti-messages icon" />
              {I18n.t("shared.actions.contact_chat")}
            </Button>

            <a className="btn btn-success w-100"
               href={
                 buildWhatsAppLink(
                   {
                     number: getCustomVariable("contact_whatsapp_international"),
                     message: whatsMsg,
                   }
                 )
               }
               target="_blank"
               rel="noopener"
            >
              <i className="ti ti-brand-whatsapp icon"/>
              {I18n.t("shared.actions.contact_whatsapp")}
            </a>
          </>
        )}
      </div>
    </>
  );
};

export default ChatWithUs;
