import { useEffect, useState } from "react";

import Box from "../Box/index";
import RenderCard from "./RenderCard";

import useGenerateCallsAi from "@/hooks/useGenerateCallsAi";

import * as S from "./style";

const AiCalls = ({ setIsLoading, isMobile, avatar }) => {
  const [url, setUrl] = useState("");
  const [formattedCalls, setFormattedCalls] = useState([]);

  const { isLoading, error, callsList, fetchCalls } = useGenerateCallsAi(url, false);

  const formatCalls = (data) => {
    if (data.length <= 0) return;

    const calls = data.map(callText => {
      return { call: callText };
    });

    setFormattedCalls(calls);
  };

  const generateCalls = () => {
    setFormattedCalls([]);
    fetchCalls();
  };

  useEffect(() => {
    formatCalls(callsList);
  }, [callsList]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <div className="m-3">
      <div className="h3 fw-bold">{I18n.t("views.manage_flows.call_models.ai_calls.title")}</div>
      <p className="fs-4">{I18n.t("views.manage_flows.call_models.ai_calls.description")}</p>
      <div className="border-top border-2 border-info my-3" />
      <div className="d-flex align-items-center">
        <i className="ti ti-world-www text-primary fs-2" />
        <span className="ms-2 fw-bold">{I18n.t("views.manage_flows.call_models.ai_calls.subtitle")}</span>
      </div>
      <p className="fs-4 mt-2">{I18n.t("views.manage_flows.call_models.ai_calls.subtitle_description")}</p>
      <div className="my-3">
        <div className="row justify-content-center">
          <div className="col">
            <div className="input-group">
              <input
                className="form-control border-primary border-1"
                type="text"
                placeholder={I18n.t("views.manage_flows.call_models.ai_calls.placeholder_url")}
                value={url}
                aria-label="URL"
                onChange={(event => setUrl(event.target.value))}
                disabled={isLoading}
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={generateCalls}
                disabled={isLoading}
              >
                <i className="fs-2 ti ti-sparkles me-2" />
                {I18n.t("views.manage_flows.call_models.ai_calls.btn_title")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="border-top border-2 border-info mt-4" />

      {formattedCalls.length > 0 ? (
          <S.BoxContainerShadow>
            <div className="boxes-ai-container">
              <Box
                isMobile={isMobile}
                identifier={`boxes_ai_calls`}
                avatar={avatar}
                calls={formattedCalls}
                aiCall={true}
              />
            </div>
          </S.BoxContainerShadow>
        ) : (
          <RenderCard isLoading={isLoading} error={error} />
        )
      }
    </div>
  );
};

export default AiCalls;
