import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const StyledCard = styled(Card)`
  background: linear-gradient(90deg, #C1E0FE, #E5F0FD);
`;
