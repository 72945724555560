import { useState, useEffect } from "react";

import { Form, Col, Row, ListGroup, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import IdleMessage from "./components/IdleMessage";
import InfiniteScrollLoader from "./components/InfiniteScrollLoader";
import EvaluationConversation from "./components/EvaluationConversation";
import ProductsRecommendation from "../MessageHistory/components/ProductsRecommendation";
import ButtonOpenModal from "@/components/lead/ButtonOpenModal";
import HelpPopover from "@/components/shared/HelpPopover";
import ButtonExport from "@/components/leadster_ia/shared/ButtonExport";
import ModalHistoricExport from "@/components/leadster_ia/cards/Analytics/export/ModalHistoricExport";

import { useParams } from "react-router-dom";

import { fetchLinkedScripts, fetchMessageHistory } from "../services/fetchServices";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";
import historicParamsExport from "@/components/leadster_ia/cards/Analytics/store/historicParamsExport";
import useFetchMessages from "./hooks/useFetchMessages";

import hourGlassImg from "@/../assets/images/ai_assistant/hourglass-done.png";
import sleepingFaceImg from "@/../assets/images/ai_assistant/sleeping-face.png";

import * as S from "./styles";

const CONVERSATION_FILTER_CONFIGS = {
  "cv-liked": { type: "conversation", value: true },
  "cv-disliked": { type: "conversation", value: false },
  "rs-liked": { type: "response", value: true },
  "rs-disliked": { type: "response", value: false },
  "rs-unassessed": { type: "response", value: "unassessed" },
};

const MessageHistory = () => {
  useCreateYourAiAssistant("/history", "analytics");

  const { assistantId } = useParams();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [messageHistoryList, setMessageHistoryList] = useState([]);
  const [scriptsFilter, setScriptsFilter] = useState([]);
  const [scriptSelectFilter, setScriptSelectFilter] = useState(null);
  const [conversationFilter, setConversationFilter] = useState(null);
  const [evaluationFilter, setEvaluationFilter] = useState(null);
  const [responseEvaluationFilter, setResponseEvaluationFilter] = useState(null);

  const { messagesList, hasMore, nextPage } = useFetchMessages(
    {
      ai_assistant_id: assistantId,
      script_id: scriptSelectFilter,
      status: conversationFilter,
      evaluation_conversation: evaluationFilter,
      response_evaluation: responseEvaluationFilter,
    },
    [scriptSelectFilter, conversationFilter, evaluationFilter, responseEvaluationFilter]
  );

  useEffect(() => {
    loadScriptsFilter();
  }, []);

  useEffect(() => {
    fillHistoricParamsExport();
    setMessageHistoryList([]);
    setSelectedMessage({});
  }, [scriptSelectFilter, conversationFilter, evaluationFilter, responseEvaluationFilter]);

  const handleSelectScriptFilter = (e) => {
    OpenpanelService.track("leadster_ai_analytics_history_filtered_by_script");
    setScriptSelectFilter(e.target.value || null);
  }

  const handleSelectConversationFilter = (e) => {
    OpenpanelService.track("leadster_ai_analytics_history_filtered_by_conversation");
    setConversationFilter(e.target.value || null);
  }

  const handleEvaluationFilterChange = (e) => {
    OpenpanelService.track("leadster_ai_analytics_history_filtered_by_evaluation");

    setEvaluationFilter(null);
    setResponseEvaluationFilter(null);

    const config = CONVERSATION_FILTER_CONFIGS[e.target.value];

    if (!config) return;

    if (config.type === "conversation") {
      return setEvaluationFilter(config.value);
    }

    setResponseEvaluationFilter(config.value);
  };

  const loadScriptsFilter = async () => {
    const { linkedScripts } = await fetchLinkedScripts({ assistantId });

    setScriptsFilter(linkedScripts);
  };

  const loadMessagesHistory = async (leadId) => {
    OpenpanelService.track("leadster_ai_analytics_history_conversation");

    const { messagesHistory } = await fetchMessageHistory({
      selectedMessage: leadId,
      page: 1,
      scripts: scriptSelectFilter,
      conversation: conversationFilter,
      evaluationConversation: evaluationFilter,
      assistantId,
      responseEvaluation: responseEvaluationFilter,
    });

    setMessageHistoryList(messagesHistory);
  };

  const fillHistoricParamsExport = () => {
    historicParamsExport.setState({
      assistant_id: assistantId,
      script_id: scriptSelectFilter,
      conversation: conversationFilter,
      review: evaluationFilter,
    });
  };

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <Form.Select onChange={handleSelectScriptFilter}>
            <option value="">
              {I18n.t("views.leadster_ai.page.analytics.history.all_flows")}
            </option>

            {scriptsFilter.map(({ id, name }) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </Form.Select>
        </Col>

        <Col>
          <Form.Select onChange={handleSelectConversationFilter}>
            <option value="">
              {I18n.t("views.leadster_ai.page.analytics.history.all_answers")}
            </option>
            <option value="done">
              {I18n.t("views.leadster_ai.page.analytics.history.answered")}
            </option>
            <option value="not_found">
              {I18n.t("views.leadster_ai.page.analytics.history.unanswered")}
            </option>
          </Form.Select>
        </Col>

        <Col>
          <Form.Select onChange={handleEvaluationFilterChange}>
            <option value="">
              {I18n.t("views.leadster_ai.page.analytics.history.all_reviews")}
            </option>
            <option value="cv-liked">
              {I18n.t("views.leadster_ai.page.analytics.history.positive_reviews")}
            </option>
            <option value="cv-disliked">
              {I18n.t("views.leadster_ai.page.analytics.history.negative_reviews")}
            </option>
            <option value="rs-liked">
              {I18n.t("views.leadster_ai.page.analytics.history.response_liked")}
            </option>
            <option value="rs-disliked">
              {I18n.t("views.leadster_ai.page.analytics.history.response_disliked")}
            </option>
            <option value="rs-unassessed">
              {I18n.t("views.leadster_ai.page.analytics.history.response_unassessed")}
            </option>
          </Form.Select>
        </Col>

        <Col md={2}>
          <ButtonExport Modal={ModalHistoricExport} disable={!messagesList.length} />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          {messagesList.length ? (
            <InfiniteScroll
              dataLength={messagesList.length}
              next={nextPage}
              hasMore={hasMore}
              loader={<InfiniteScrollLoader />}
              inverse={false}
              height={625}
            >
              <ListGroup>
                {messagesList.map((message) => (
                  <S.ListGroupItem
                    key={message.lead_id}
                    active={message.lead_id === selectedMessage?.lead_id}
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedMessage(message);
                      loadMessagesHistory(message.lead_id);
                    }}
                  >
                    <div className="mb-2 text-secondary">
                      <time>
                        {dayjs(message.created_at).format(I18n.t("js.formats.default.date_time"))}
                      </time>
                    </div>

                    <S.Answer className="mb-2 fw-bold">
                      {I18n.t("views.leadster_ai.page.analytics.history.visitor")}: {message.question}
                    </S.Answer>

                    <S.Message className="fw-light">
                      {message.assistant_name}:{" "}

                      {message.products_recommendation.length ? (
                        <ProductsRecommendation urls={message.products_recommendation} />
                      ) : (
                        message.answer
                      )}
                    </S.Message>
                  </S.ListGroupItem>
                ))}
              </ListGroup>
            </InfiniteScroll>
          ) : (
            <IdleMessage
              title={I18n.t("views.leadster_ai.page.analytics.history.idle_messages.title")}
              description={I18n.t("views.leadster_ai.page.analytics.history.idle_messages.description")}
              icon={{ imgUrl: hourGlassImg, color: "#00CFFF" }}
            />
          )}
        </Col>

        <Col sm={6}>
          {messageHistoryList.length ? (
            <Card>
              <Card.Header className="bg-white d-flex justify-content-between">
                {selectedMessage.lead_deleted_at ? (
                  <div className="d-flex align-items-center fw-bold">
                    <i className="ti ti-user-x me-2" />
                    {I18n.t("views.leadster_ai.page.analytics.history.no_data")}
                    <HelpPopover
                      className="ms-2"
                      content={I18n.t("views.leadster_ai.page.analytics.history.no_data_popover")}
                      placement="top"
                    />
                  </div>
                ) : (
                  <>
                    <div className="fw-bold">
                      {I18n.t("views.leadster_ai.page.analytics.history.see_data")}
                    </div>

                    <ButtonOpenModal
                      className="btn-outline-primary"
                      id={selectedMessage.lead_id}
                      icon="user"
                    />
                  </>
                )}
              </Card.Header>

              <Card.Body className="d-flex flex-column justify-content-end pe-0">
                <InfiniteScroll
                  dataLength={messageHistoryList.length}
                  hasMore={false}
                  inverse={true}
                  height={458}
                >
                  {messageHistoryList.map((message, key) => (
                    <div key={key}>
                      <S.MessageCard isAnswer>
                        <p className="m-0">{message.question}</p>
                        <time>
                          {dayjs(message.created_at).format(I18n.t("js.formats.default.date_time"))}
                        </time>
                      </S.MessageCard>

                      <div className="d-flex align-items-end mb-4">
                        <S.Avatar alt={message.assistant_name[0]} src={message.avatar} />
                        <div>
                          <S.MessageCard>
                            <p className="m-0">
                              {message.products_recommendation.length ? (
                                <ProductsRecommendation urls={message.products_recommendation} />
                              ) : (
                                message.answer
                              )}
                            </p>

                            <time>
                              {dayjs(message.created_at).format(I18n.t("js.formats.default.date_time"))}
                            </time>
                          </S.MessageCard>

                          {message.liked !== null && (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{I18n.t("views.leadster_ai.page.analytics.history.response_evaluated")}</Tooltip>}
                            >
                              <i className={`fa fa-thumbs-${message.liked ? "up" : "down"} mt-1 ms-3`} />
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </Card.Body>

              <EvaluationConversation message={selectedMessage} />
            </Card>
          ) : (
            <IdleMessage
              title={I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.title")}
              description={I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.description")}
              icon={{ imgUrl: sleepingFaceImg, color: "#007DFF" }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MessageHistory;
