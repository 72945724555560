import parse from "html-react-parser";

import dayjs from "@/lib/dayjs";
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";

const formatDate = (date) => {
  return dayjs(date).format(I18n.t("js.formats.default.date"));
};

export const getFaqMessages = (
  hasPermission,
  series,
  hasScriptsSelected,
  assistantId,
  lastUpdated,
  willBeCreatedIn,
  nextAnalysisDate
) => {
  let message1 = null;
  let message2 = null;
  let linkTo = null;
  let updatedAtMessage, nextAnalysisMessage;

  if (hasPermission === false) {
    message1 = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_permission.message_1_html")
    );
    message2 = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_permission.link_html")
    );
    linkTo = buildWhatsAppLink({
      number: getCustomVariable("whatsapp_plugins_sales"),
      message: I18n.t("shared.tidio.subscription_request_message"),
    });
  } else if (series.length === 0 && !lastUpdated) {
    message1 = parse(
      I18n.t(
        "views.leadster_ai.page.analytics.dashboard.graph.will_be_created_in_html",
        {
          date: formatDate(willBeCreatedIn),
        }
      )
    );
  } else if (series.length === 0) {
    message1 = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.will_be_displayed_html")
    );
  } else if (!hasScriptsSelected && series.length > 0) {
    message1 = parse(
      I18n.t(
        "views.leadster_ai.page.analytics.dashboard.graph.no_activation_alert.message_1_with_context_html"
      )
    );
    message2 = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_activation_alert.message_2_html")
    );
    linkTo = `/ai/leadster/assistants/${assistantId}/activation`;
  }

  if (!lastUpdated && !hasPermission) {
    updatedAtMessage = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.updated_at_empty_html")
    );
    nextAnalysisMessage =  parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_analysis_forecast_html"))
  } else if (!lastUpdated) {
    updatedAtMessage = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.updated_at_empty_html")
    );
    nextAnalysisMessage = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.next_analysis_html", {
        date: formatDate(nextAnalysisDate),
      })
    );
  } else {
    updatedAtMessage = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.updated_at_html", {
        date: formatDate(lastUpdated),
      })
    );
    nextAnalysisMessage = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.next_analysis_html", {
        date: formatDate(nextAnalysisDate),
      })
    );
  }

  return {
    message1,
    message2,
    linkTo,
    updatedAtMessage,
    nextAnalysisMessage,
  };
};

export const getAiSupportMessages = (
  hasPermission,
) => {
  let message1 = null;
  let message2 = null;
  let linkTo = null;

  if (hasPermission === false) {
    message1 = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_permission.message_2_html")
    );
    message2 = parse(
      I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_permission.link_html")
    );
    linkTo = buildWhatsAppLink({
      number: getCustomVariable("whatsapp_plugins_sales"),
      message: I18n.t("shared.whatsapp.plugin_request_message"),
    });
  }

  return {
    message1,
    message2,
    linkTo,
  };
};
