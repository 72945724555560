import useCookie from "@/hooks/useCookie";
import useCurrentUserStore from "@/store/currentUserStore";

import bannerGif from "@/../assets/images/panorama/panorama-2025.png";

import * as S from "./styles";

const EXPIRE_IN_DAYS = 7;
const COOKIE_KEY = "leadster-info-panorama-2025";
const TRACK_EVENT = "panorama_2025_dashboard_banner";
const LINK_PANORAMA = "https://leadster.com.br/panorama-de-geracao-de-leads-no-brasil-2025/?utm_source=produto&utm_medium=organic&utm_campaign=panorama_2025&utm_content=banner";

const BannerPanorama2025 = () => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);
  const { value: cookieValue, setValue: setCookieValue } = useCookie(COOKIE_KEY, {
    expires: EXPIRE_IN_DAYS,
  });

  const markAsSeen = () => setCookieValue(true);
  const trackInterest = () => OpenpanelService.track(TRACK_EVENT);

  const showBanner = !cookieValue && isNational;

  return (
    <S.BannerWrapper show={showBanner} variant={false} onClose={markAsSeen} dismissible>
      <a href={LINK_PANORAMA} className="d-block" target="_blank" onClick={trackInterest}>
        <S.BannerImage src={bannerGif} alt="Panorama 2025" />
      </a>
    </S.BannerWrapper>
  );
};

export default BannerPanorama2025;
