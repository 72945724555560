import styled from "styled-components";

export const CustomCard = styled.div`
  background-color: #CCE2FC;
`;

export const Text = styled.div`
  border: 1px solid #007DFF;
  padding: 15px;
  margin-top: 10px;
  border-radius: 5px;
  height: 350px;
  overflow: auto;
  margin-bottom: 10px;
`;
