import useAlertInfo from "./hooks/useAlertInfo";

import * as S from "./style";

const AlertConfiguration = ({ finishFlow, rejectLead }) => {
  const { show, icon, message, isClosable, onClose } = useAlertInfo(finishFlow, rejectLead);

  if (!show) return null;

  return (
    <S.AlertContainer>
      <div className="d-flex align-items-center">
        <i className={`ti ti-${icon} icon me-2`} />
        <div className="text-body fw-medium">{message}</div>

        {isClosable && <a className="btn-close" onClick={onClose} />}
      </div>
    </S.AlertContainer>
  );
};

export default AlertConfiguration;
