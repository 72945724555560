import PropTypes from "prop-types";

import AdsAnalytics from "../AdsAnalytics";

const AdsAnalyticsBlock = ({ title, analyticChannel }) => {
  return (
    <div>
      <div className="d-flex justify-content-start">
        {title && (
          <h3 className="h1 mt-3 mb-2 text-primary">{title}</h3>
        )}

        <div className="ps-3 flex-grow-1">
          <hr className="w-100" />
        </div>
      </div>

      <AdsAnalytics analyticChannel={analyticChannel} />
    </div>
  );
};

AdsAnalyticsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  analyticChannel: PropTypes.string.isRequired,
};

export default AdsAnalyticsBlock;
