import { useState } from "react";
import dayjs from "@/lib/dayjs";

import { Span } from "./styles";

const MessageItem = ({ message }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleMessage = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="mb-3 border-bottom border-right px-3 pb-3 pt-1 position-relative me-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <small className="text-muted">{dayjs(message.created_at).format(I18n.t("js.formats.default.date"))}</small>
      </div>
      <div className="d-flex align-items-center">
        <span className="fw-bold me-2">
          {I18n.t("views.leadster_ai.page.analytics.messages_modal.visitor")}:
        </span>
        <span className="fw-bold">{message.question}</span>
      </div>
      <div className="d-flex align-items-start mt-2">
        <span className="fw-bold me-2">
          {I18n.t("views.leadster_ai.page.analytics.messages_modal.assistant")}:
        </span>
        <Span isExpanded={isExpanded}>
          {message.answer}
        </Span>
      </div>
      <div
        onClick={toggleMessage}
        className="position-absolute top-0 end-0"
        style={{ zIndex: 1, cursor: "pointer" }}
      >
        <i className={`ti ti-chevron-${isExpanded ? "up" : "down"} fs-1 ${isExpanded ? "text-primary" : ""}`}></i>
      </div>
    </div>
  );
};

export default MessageItem;
