import { useState, useEffect, useRef } from "react";

import { fetchLinkedScripts, fetchAssistantAnalytics, fetchSummaryQuestions } from "../services/fetchServices";
import useDatePickRange from "./hooks/useDatePickRange";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import { useParams } from "react-router-dom";
import dayjs from "@/lib/dayjs";

import CustomTabs from "@/components/shared/CustomTabs";
import FrequentlyAskedQuestions from "./components/FrequentlyAskedQuestions";
import AiSupport from "./components/AiSupport";
import supportParamsExport from "@/components/leadster_ia/cards/Analytics/store/supportParamsExport";


const ServiceAnalytics = () => {
  useCreateYourAiAssistant("/dashboard", "analytics");

  const { assistantId } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [isLoadingDonutCharts, setIsLoadingDonutCharts] = useState(true);

  const [scriptFilter, setScriptFilter] = useState([]);
  const [scriptSelectFilter, setScriptSelectFilter] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [currentWithContext, setCurrentWithContext] = useState({});
  const [currentWithoutContext, setCurrentWithoutContext] = useState({});
  const [lastUpdated, setLastUpdated] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [startDate, setStartDate] = useState(dayjs().subtract(6, "days").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const dataRange = useRef();

  useDatePickRange(dataRange.current, startDate, endDate, (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    OpenpanelService.track("leadster_ai_analytics_support_filtered_by_date");
  });

  const handleScriptFilterChange = (event) => {
    setScriptSelectFilter(event.target.value === "all" ? [null] : event.target.value);
    OpenpanelService.track("leadster_ai_analytics_support_filtered_by_script");
  }

  const loadScriptFilter = async () => {
    const { linkedScripts } = await fetchLinkedScripts({ assistantId });

    setScriptFilter(linkedScripts);
    setScriptSelectFilter(linkedScripts?.map(({ id }) => id));
  }

  const loadAssistantAnalytics = async () => {
    setLoading(true);

    const { analytics } = await fetchAssistantAnalytics({
      scriptIds: scriptSelectFilter,
      assistantId,
      startDate,
      endDate,
    });

    fillSupportParamsExport(analytics);
    setCurrentData(analytics);
    setLoading(false);
  }

  const loadSummaryQuestions = async () => {
    setIsLoadingDonutCharts(true);

    const { summaryQuestions } = await fetchSummaryQuestions({ assistantId });
    const template = { startAt: null, endAt: null, series: [], options: { labels: [] } }

    setCurrentWithContext({ ...template, ...(summaryQuestions?.with_context || {}) });
    setCurrentWithoutContext({ ...template, ...(summaryQuestions?.without_context || {}) });
    setLastUpdated(summaryQuestions?.last_updated);
    setCreatedAt(summaryQuestions?.created_at);
    setIsLoadingDonutCharts(false);
  }

  const fillSupportParamsExport = (analytics) => {
    supportParamsExport.setState({
      disable_button: !analytics.has_data,
      assistant_id: assistantId,
      script_id: scriptSelectFilter,
      data_range_str: $(dataRange.current).val(),
      start_date: startDate,
      end_date: endDate,
    });
  };

  useEffect(() => {
    loadScriptFilter();
    loadSummaryQuestions();
  }, []);

  useEffect(() => {
    loadAssistantAnalytics();
  }, [scriptSelectFilter, startDate, endDate]);

  const hasScriptsSelected = !!scriptSelectFilter.length;

  const tabsConfig = [
    {
      eventKey: "aiSupport",
      title: I18n.t("views.leadster_ai.page.analytics.tab.ai_support"),
      iconClassName: "ti ti-device-analytics",
      content: (
        <AiSupport
          handleScriptFilterChange={handleScriptFilterChange}
          scriptSelectFilter={scriptSelectFilter}
          scriptFilter={scriptFilter}
          dataRange={dataRange}
          isLoading={isLoading}
          currentData={currentData}
          assistantId={assistantId}
        />
      ),
    },
    {
      eventKey: "frequentlyAskedQuestions",
      title: I18n.t("views.leadster_ai.page.analytics.tab.faq"),
      iconClassName: "ti ti-chart-donut",
      content: (
        <FrequentlyAskedQuestions
          assistantId={assistantId}
          hasScriptsSelected={hasScriptsSelected}
          createdAt={createdAt}
          lastUpdated={lastUpdated}
          isLoadingDonutCharts={isLoadingDonutCharts}
          currentWithContext={currentWithContext}
          currentWithoutContext={currentWithoutContext}
        />
      ),
    },
  ];

  return (
    <CustomTabs
      tabsConfig={tabsConfig}
      defaultActiveKey="aiSupport"
      tabParamName="tab"
    />
  );
}

export default ServiceAnalytics;
