import { useRef, useState } from "react";

import ReactPortal from "@/components/ReactPortal";
import ScriptToDuplicate from "./ScriptToDuplicate";
import AiCalls from "@/components/dashboard/PageRank/AiCalls";
import HeaderContainer from "@/components/dashboard/PageRank/RowData/FlowOptions/HeaderContainer";

import Modal from "react-bootstrap/Modal";

import { classBinding } from "@/utils/helpers";
import axios from "@/lib/axios";

import swal from "sweetalert";

import * as S from "../style";

const DuplicateFlow = ({ pageUrl }) => {
  const [showScriptModal, setShowScriptModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allowedScripts, setAllowedScripts] = useState([]);

  let call = useRef("");
  let suggestedCall = useRef("");

  const handleLoadScripts = () => {
    setIsLoading(true);

    setShowScriptModal(true);

    axios.get('/page-ranks/list-scripts-to-duplicate')
      .then(({ data }) => setAllowedScripts(data.scripts))
      .catch(() => {
        setAllowedScripts([]);
        swal(
          I18n.t("request_feedback_alert.error.title"),
          I18n.t("request_feedback_alert.error.message"),
          "error"
        );
      })
      .then(() => setIsLoading(false));
  };

  const selectedCall = (value) => {
    call.current = value
  };

  const selectedSuggestionCall = (value) => {
    suggestedCall.current = value
  };

  const handleButtonClick = () => {
    handleLoadScripts();
    MixpanelService.track("page_rank_dashboard_click_on_duplicate_flow");
  };

  return (
    <div className="card shadow-sm">
      <S.TopBorder color="info" />
      <div className="card-body mt-3">
        <HeaderContainer
          icon="copy"
          title={I18n.t("views.dashboard.page_rank.create_flow_modal.duplicate_flow.title")}
          description={I18n.t("views.dashboard.page_rank.create_flow_modal.duplicate_flow.description_html")}
        />
        <AiCalls
          url={pageUrl}
          selectedCall={selectedCall}
          selectedSuggestionCall={selectedSuggestionCall}
        />
        <button
          type="button"
          className="btn btn-outline-primary mb-3 w-100 fw-bold"
          onClick={handleButtonClick}
        >
          {I18n.t("shared.actions.duplicate_flow")}
        </button>
      </div>

      <ReactPortal>
        <Modal
          show={showScriptModal}
          className="modal-blur"
          size="xl"
          centered
          onHide={() => setShowScriptModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <div>
              <div className="modal-title mb-1">
                {I18n.t("views.dashboard.page_rank.create_flow_modal.title")}
              </div>
              <div className="text-muted">
                {I18n.t("views.dashboard.page_rank.create_flow_modal.subtitle")}{" "}
                <S.PageLink href={pageUrl} target="_blank">
                  {pageUrl}
                </S.PageLink>
              </div>
            </div>
          </Modal.Header>

          <Modal.Body className="overflow-auto" style={{ maxHeight: 500}}>
            <div
              className={classBinding({ "content-loading": isLoading }, "modal-body")}
              style={{ minHeight: 400, maxHeight: 500 }}
            >
              {allowedScripts.map((script) => (
                <ScriptToDuplicate
                  key={script.id}
                  pageUrl={pageUrl}
                  script={script}
                  aiCall={call.current}
                  suggestionAiCall={suggestedCall.current}
                />
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </ReactPortal>
    </div>
  );
};

export default DuplicateFlow;
