import { useAssistantContext } from "@/contexts/AssistantContext";

import AddWebsiteLink from "../AddWebsiteLink";

import VtexIcon from "@/../../public/images/ai_assistant/vtex.svg";
import SitemapIcon from "@/../../public/images/ai_assistant/xml.svg";
import GoogleShoppingIcon from "@/../../public/images/ai_assistant/google-shopping.svg";

const AddUrls = () => {
  const { disabledContent, selectedAddContentType, setSelectedAddContentType, processingAddContentType } = useAssistantContext();

  const handleSelectionChange = (type) => {
    setSelectedAddContentType(`${type}`);
  };

  const isSelected = (type) => selectedAddContentType === `${type}`;

  const handleSelectionDisabled = (selected) => {
    return (disabledContent && !isSelected(selected)) || (disabledContent && processingAddContentType === "upload");
  };

  return (
    <>
      <AddWebsiteLink
        type="vtex"
        inputLabel="views.leadster_ai.page.add_content.add_website.add_vtex_label"
        titleKey="views.leadster_ai.page.add_content.add_website.add_vtex_site"
        descriptionKey="views.leadster_ai.page.add_content.add_website.add_vtex_site_description"
        imageSrc={VtexIcon}
        isSelected={isSelected("vtex")}
        onSelect={handleSelectionChange}
        isSelectionDisabled={handleSelectionDisabled("vtex")}
        isListMultipleUrls={false}
      />

      <AddWebsiteLink
        type="sitemap"
        inputLabel="views.leadster_ai.page.add_content.add_website.add_sitemap_label"
        titleKey="views.leadster_ai.page.add_content.add_website.add_sitemap_xml"
        descriptionKey="views.leadster_ai.page.add_content.add_website.add_sitemap_xml_description"
        imageSrc={SitemapIcon}
        isSelected={isSelected("sitemap")}
        onSelect={handleSelectionChange}
        isSelectionDisabled={handleSelectionDisabled("sitemap")}
        isListMultipleUrls={false}
      />

      <AddWebsiteLink
        type="google_shopping"
        inputLabel="views.leadster_ai.page.add_content.add_website.add_google_label"
        titleKey="views.leadster_ai.page.add_content.add_website.add_google_shopping"
        descriptionKey="views.leadster_ai.page.add_content.add_website.add_google_shopping_description"
        imageSrc={GoogleShoppingIcon}
        isSelected={isSelected("google_shopping")}
        onSelect={handleSelectionChange}
        isSelectionDisabled={handleSelectionDisabled("google_shopping")}
        isListMultipleUrls={false}
        recommendationIsAvailable
      />
    </>
  );
};

export default AddUrls;
