import styled from "styled-components";

export const Span = styled.span.attrs({
  className: "text-truncate d-inline-block",
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ isExpanded }) => (isExpanded ? "normal" : "nowrap")};
  max-height: ${({ isExpanded }) => (isExpanded ? "none" : "1.5em")};
  display: inline-block;
`;
