import React from "react";

const AiAssistantAlert = ({ message1, message2, linkTo }) => {
  const hasLink = Boolean(linkTo);

  return (
    <div className="alert alert-warning mb-3">
      <div className="d-flex align-items-center">
        <i className="ti ti-exclamation-circle icon alert-icon" />
        <div className="text-body">
          {message1}

          {hasLink && (
            <>
              {" "}
              <a
                href={linkTo}
                target="_blank"
                rel="noopener noreferrer"
              >
                {message2}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AiAssistantAlert;
