import styled from "styled-components";

export const BulbMessage = styled.div`
    background-color: #d3e7fd;
    font-size: 14px;
    color: black;
    padding: 11px;
    border-radius: 5px;
    margin-bottom: 15px;

    i {
      font-size: 17px;
      color: #1e90ff;
    }
`;
