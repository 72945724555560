import { useState, useEffect } from "react";

import { Modal, Button, Form, Card } from "react-bootstrap";
import parse from "html-react-parser";

const ContentUsageModal = ({ show, onHide, onConfirm, contentValue }) => {
  const [selectedOption, setSelectedOption] = useState("product");

  useEffect(() => {
    if (!show) {
      setSelectedOption("product");
    }
  }, [show]);

  const handleConfirm = () => {
    onConfirm(selectedOption);
  };

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <Modal.Header closeButton className="border-0 mb-0">
        <Modal.Title className="fs-4 fw-bold my-0">
          {I18n.t("views.leadster_ai.page.add_content.usage_modal.title")}
        </Modal.Title>
      </Modal.Header>
      <Card className="border-0 mx-2">
        <div className="border-bottom border-top my-3 p-3">
          <Card.Title>
            {I18n.t("views.leadster_ai.page.add_content.usage_modal.adding_as_content")}
          </Card.Title>
          <Card.Body className="py-1 bg-primary-lt fw-bold rounded">
            <i className="ti ti-world-www text-black icon me-2" />
            {contentValue}
          </Card.Body>
        </div>
      </Card>
      <Modal.Body>
        <Form>
          <Form.Label className="mb-3 fs-4 fw-bold">
            {I18n.t("views.leadster_ai.page.add_content.usage_modal.select_between")}
          </Form.Label>

          <Form.Check
            type="radio"
            id="add-as-product"
            name="content-usage"
            value="product"
            className={`mb-3 p-3 px-4 bg-primary-lt rounded ${
              selectedOption === "product" ? "border border-primary border-2" : "border"
            }`}
            onChange={() => setSelectedOption("product")}
            checked={selectedOption === "product"}
            label={
              <div>
                <strong className="text-black mb-1 fw-bold fs-4">
                  {I18n.t("views.leadster_ai.page.add_content.usage_modal.add_as_product")}
                </strong>
                <div className="text-black mb-2 fs-5">
                  {I18n.t("views.leadster_ai.page.add_content.usage_modal.product_description")}
                </div>
                <div className="text-secondary fs-5 fst-italic">
                  {parse(I18n.t("views.leadster_ai.page.add_content.usage_modal.product_example"))}
                </div>
              </div>
            }
            style={{ cursor: "pointer" }}
          />

          <Form.Check
            type="radio"
            id="add-as-generic"
            name="content-usage"
            value="generic"
            className={`mb-3 p-3 px-4 bg-primary-lt rounded ${
              selectedOption === "generic" ? "border border-primary border-2" : "border"
            }`}
            onChange={() => setSelectedOption("generic")}
            checked={selectedOption === "generic"}
            label={
              <div>
                <strong className="text-black mb-1 fw-bold fs-4">
                  {I18n.t("views.leadster_ai.page.add_content.usage_modal.add_as_generic")}
                </strong>
                <div className="text-black mb-2 fs-5">
                  {I18n.t("views.leadster_ai.page.add_content.usage_modal.generic_description")}
                </div>
                <div className="text-secondary fs-5 fst-italic">
                  {parse(I18n.t("views.leadster_ai.page.add_content.usage_modal.generic_example_1"))}
                </div>
                <div className="text-secondary fs-5 fst-italic">
                  {parse(I18n.t("views.leadster_ai.page.add_content.usage_modal.generic_example_2"))}
                </div>
              </div>
            }
            style={{ cursor: "pointer" }}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm}>
          {I18n.t("views.leadster_ai.page.add_content.usage_modal.confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContentUsageModal;
