import { Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import DonutChart from "../DonutChart";
import AiAssistantAlert from "./components/AiAssistantAlert";
import { getFaqMessages } from "./utils";

import dayjs from "@/lib/dayjs";
import { can } from "@/lib/user-permission";

import ButtonExport from "@/components/leadster_ia/shared/ButtonExport";
import ModalFrequentlyExport from "@/components/leadster_ia/cards/Analytics/export/ModalFrequentlyExport";
import questionParamsExport from "@/components/leadster_ia/cards/Analytics/store/questionParamsExport";

const FrequentlyAskedQuestions = ({
  lastUpdated,
  isLoadingDonutCharts,
  createdAt,
  currentWithContext,
  currentWithoutContext,
  hasScriptsSelected,
  assistantId
}) => {
  const series = currentWithContext?.series || [];
  const today = dayjs();
  const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });

  let willBeCreatedIn = dayjs(createdAt).add(7, "day");
  if (willBeCreatedIn.isBefore(today, "day")) {
    willBeCreatedIn = today.add(1, "day");
  }

  let nextAnalysisDate;
  if (!lastUpdated) {
    nextAnalysisDate = willBeCreatedIn.add(7, "day");
  } else {
    nextAnalysisDate = dayjs(lastUpdated).add(7, "day");
  }

  if (nextAnalysisDate.isBefore(today, "day")) {
    nextAnalysisDate = today.add(1, "day");
  }

  if (!hasScriptsSelected && series.length === 0) {
    const renderNoActivationAlert = () => {
      return (
        <>
        {I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_activation_alert.message_1_without_context")}
          <a
            href={`/ai/leadster/assistants/${assistantId}/activation`}
            target="_blank"
            rel="noopener noreferrer"
          >
          {parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_activation_alert.message_2_html"))}
          </a>
        </>
      );
    };

    return <AiAssistantAlert message1={renderNoActivationAlert()} />;
  }

  const {
    message1,
    message2,
    linkTo,
    updatedAtMessage,
    nextAnalysisMessage
  } = getFaqMessages(hasPermission, series, hasScriptsSelected, assistantId, lastUpdated, willBeCreatedIn, nextAnalysisDate);

  questionParamsExport.setState({
    disable_button: _.isEmpty(series),
    assistant_id: assistantId,
  });

  return (
    <>
      {message1 && <AiAssistantAlert message1={message1} message2={message2} linkTo={linkTo} />}

      <Row className="mb-3 align-items-center">
        <Col>
          {updatedAtMessage}
        </Col>

        <Col>
          <div className="text-end">
            <ButtonExport Modal={ModalFrequentlyExport} disable={questionParamsExport.getState().disable_button}/>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <DonutChart
            title={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.with_context.title")}
            subtitle={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.with_context.subtitle")}
            footer={parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.with_context.footer_html"))}
            isLoading={isLoadingDonutCharts}
            data={currentWithContext}
          />
        </Col>
        <Col>
          <DonutChart
            title={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_context.title")}
            subtitle={I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_context.subtitle")}
            footer={parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.without_context.footer_html"))}
            isLoading={isLoadingDonutCharts}
            data={currentWithoutContext}
          />
        </Col>
      </Row>

      <div className="mt-3">
        <p>{nextAnalysisMessage}</p>
      </div>
    </>
  );
};

export default FrequentlyAskedQuestions;
