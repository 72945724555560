import { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import * as S from "./styles";

const CustomTabs = ({ tabsConfig, defaultActiveKey, tabParamName = "tab", onSelect }) => {
  let history, location;
  let routerAvailable = false;

  try {
    history = useHistory();
    location = useLocation();
    routerAvailable = true;
  } catch (e) {
    routerAvailable = false;
  }

  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  useEffect(() => {
    if (routerAvailable) {
      const query = new URLSearchParams(location.search);
      const tab = query.get(tabParamName) || defaultActiveKey;
      setActiveKey(tab);
    }
  }, [routerAvailable && location?.search]);

  const handleSelect = (key) => {
    setActiveKey(key);

    if (routerAvailable) {
      const query = new URLSearchParams(location.search);
      query.set(tabParamName, key);
      history.replace({ search: query.toString() });
    }

    if (onSelect && typeof onSelect === 'function') {
      onSelect(key);
    }
  };

  const renderIcon = (icon) => {
    if (!icon) return null;

    if (typeof icon === 'string' && icon.match(/^https?:\/\//)) {
      return <img src={icon} alt="" className="me-2" style={{ width: '20px' }} />;
    }

    return <i className={`icon ${icon} text-primary me-2`} />;
  };

  return (
    <S.Tabs
      activeKey={activeKey}
      onSelect={handleSelect}
      id="custom-tabs"
      className="mb-2 border border-0"
    >
      {tabsConfig.map(({ eventKey, title, icon, content }) => (
        <Tab
          eventKey={eventKey}
          key={eventKey}
          title={
            <span>
              {renderIcon(icon)}
              {title}
            </span>
          }
        >
          <div className="pt-3">{content}</div>
        </Tab>
      ))}
    </S.Tabs>
  );
};

export default CustomTabs;
