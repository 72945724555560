import { useState, useEffect, useMemo } from "react";
import { ListGroup, Spinner } from "react-bootstrap";

import IntegrateReporteiButton from "../IntegrateReporteiButton";
import StartTrialModalReportei from "../StartTrialModalReportei";
import useCookie from "@/hooks/useCookie";

import parse from "html-react-parser";
import swal from "sweetalert";

import * as S from "./styles";

const I18N_SCOPE = "views.dashboard.integrate_reportei";
const COOKIE_KEY = "banner-integrations-reportei";

const BannerIntegrationsReportei = ({ reporteiMetric, shouldNotShowBanner }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [integratedSlugs, setIntegratedSlugs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canCloseBanner, setCanCloseBanner] = useState(false);
  const [bannerClosed, setBannerClosed] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);

  if (shouldNotShowBanner) return null;

  const { value: bannerCookie, setValue: setBannerCookie } = useCookie(COOKIE_KEY);

  const integrations = [
    {
      title: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.google_ads_title`),
      description: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.google_ads_description`),
      icon:
        "https://cdn.leadster.com.br/leadster/images/dashboard/ads_icons/logo_google_ads.webp",
      slug: "google_adwords",
    },
    {
      title: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.ga4_title`),
      description: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.ga4_description`),
      icon:
        "https://cdn.leadster.com.br/leadster/images/dashboard/ads_icons/logo_analytics.webp",
      slug: "google_analytics_4",
    },
    {
      title: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.meta_ads_title`),
      description: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.meta_ads_description`),
      icon:
        "https://cdn.leadster.com.br/leadster/images/dashboard/ads_icons/logo_meta.webp",
      slug: "facebook_ads",
    },
    {
      title: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.linkedin_ads_title`),
      description: I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.linkedin_ads_description`),
      icon:
        "https://cdn.leadster.com.br/leadster/images/dashboard/ads_icons/logo_linkedin.webp",
      slug: "linkedin_ads",
    },
  ];

  useEffect(() => {
    if (reporteiMetric?.status !== "session_created") {
      setLoading(false);
      return;
    }

    const integrations = reporteiMetric.integrations;
    setIntegratedSlugs(
      integrations && integrations.length
        ? integrations.map((integration) => integration.slug)
        : []
    );
    setCanCloseBanner(true);
    setLoading(false);
  }, [reporteiMetric]);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
  };

  const updateBannerCookie = () => {
    const now = Date.now();
    if (!bannerCookie) {
      setBannerCookie({ firstInteraction: now, lastClosedAt: now, count: 1 });
    } else {
      setBannerCookie({
        firstInteraction: bannerCookie.firstInteraction,
        lastClosedAt: now,
        count: bannerCookie.count + 1,
      });
    }
  };

  const handleCloseBanner = () => {
    swal({
      text: I18n.t(`${I18N_SCOPE}.modal.warning_messages.close_banner_title`),
      icon: "warning",
      buttons: [
        I18n.t(`${I18N_SCOPE}.modal.buttons.cancel`),
        I18n.t(`${I18N_SCOPE}.modal.buttons.ok`),
      ],
      dangerMode: true,
    }).then((willClose) => {
      if (willClose) {
        swal({
          text: parse(I18n.t(`${I18N_SCOPE}.modal.success_messages.close_banner_title`)),
          icon: "success",
          html: true,
        }).then(() => {
          updateBannerCookie();
          setBannerClosed(true);
        });
      }
    });
  };

  const shouldDisplayBanner = useMemo(() => {
    if (!bannerCookie) return true;

    const now = Date.now();
    const { firstInteraction, lastClosedAt, count } = bannerCookie;
    const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
    const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;

    if (now - firstInteraction >= THIRTY_DAYS) return false;
    if (count >= 3) return false;
    if (now - lastClosedAt < SEVEN_DAYS) return false;

    return true;
  }, []);

  if (bannerClosed || !shouldDisplayBanner) {
    return null;
  }

  const filteredIntegrations = integrations.filter(
    (item) => !integratedSlugs.includes(item.slug)
  );

  if (loading) {
    return (
      <S.Container>
        <S.LoadingWrapper>
          <Spinner animation="border" className="text-blue" role="status" />
        </S.LoadingWrapper>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.ButtonContainer>
        <S.MinimizeButton variant="link" onClick={handleToggle} canCloseBanner={canCloseBanner}>
          {I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.${isMinimized ? 'maximize' : 'minimize'}`)}
          <i className={`ms-1 ti ti-${isMinimized ? "plus" : "minus"} icon`} />
        </S.MinimizeButton>
        {canCloseBanner && (
          <S.CloseButton variant="link" onClick={handleCloseBanner}>
            {I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.close`)}
            <i className="ms-1 ti ti-x icon" />
          </S.CloseButton>
        )}
      </S.ButtonContainer>

      <S.ContentWrapper>
        <S.LeftContent>
          <S.Header>
            <S.Info>
              <div className="d-flex align-items-center mb-1">
                <S.TagNew className="text-blue">
                  {I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.new_tag`)}
                </S.TagNew>
                <S.LogoImage
                  src="https://cdn.leadster.com.br/dashboard/ai/leadster_reportei.webp"
                  alt="Leadster Reportei"
                />
              </div>
              <S.Title>
                <S.GradientText>
                  {I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.title`)}
                </S.GradientText>{" "}
                {I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.subtitle`)}
              </S.Title>
              {!isMinimized && (
                <S.Description>
                  {parse(I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.${canCloseBanner ? 'description_2_html' : 'description_1_html'}`))}
                </S.Description>
              )}
              {!canCloseBanner && (
                isMinimized ? (
                  <S.LearnMore
                    href="#super-dashboard"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTrialModal(true);
                    }}
                  >
                    {parse(I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.minimized_link_html`))}
                  </S.LearnMore>
                ) : (
                  <S.LearnMore
                    href="#super-dashboard"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTrialModal(true);
                    }}
                  >
                    <i className="ti ti-player-skip-forward icon me-2 text-blue" />
                    {I18n.t(`${I18N_SCOPE}.banner_integrations_reportei.learn_more`)}
                  </S.LearnMore>
                )
              )}
            </S.Info>
          </S.Header>
        </S.LeftContent>

        <S.RightContent>
          {isMinimized ? (
            <S.MiniBannerImage
              src="https://cdn.leadster.com.br/dashboard/ai/mini_banner_ads.webp"
              alt="Mini Banner Ads"
            />
          ) : (
            <ListGroup>
              {filteredIntegrations.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  style={{ borderColor: "#CCE2FB" }}
                  className="d-flex justify-content-between align-items-center bg-white"
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={item.icon}
                      width={40}
                      className="me-3"
                      alt={item.title}
                    />
                    <S.IntegrationWrapper>
                      <S.IntegrationTitle>{item.title}</S.IntegrationTitle>
                      <S.IntegrationDescription>{item.description}</S.IntegrationDescription>
                    </S.IntegrationWrapper>
                  </div>
                  <IntegrateReporteiButton />
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </S.RightContent>
      </S.ContentWrapper>

      {showTrialModal && (
        <StartTrialModalReportei onHide={() => setShowTrialModal(false)} />
      )}
    </S.Container>
  );
};

export default BannerIntegrationsReportei;
