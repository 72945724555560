import { useEffect, useRef, useState, memo } from "react";

import { useContextSelector } from "use-context-selector";
import useUpdateEffect from "@/hooks/useUpdateEffect";

import CloneBlockButton from "@/components/scripts/ScriptEditorParts/CloneBlockButton";
import DeleteButton from "@/components/scripts/ScriptEditorParts/DeleteButton";
import HelpPopover from "@/components/shared/HelpPopover";
import PermissionsGate from '@/components/permissions/PermissionsGate';

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { ScriptContext } from "@/contexts/ScriptContext";
import { can } from "@/lib/user-permission";

import { selectAllLabels } from "../ScriptEditor/scriptFieldLabels";
import {
  nameToAttributes,
  attributesToPrefix,
  nameToSuffix,
  removeItemFromArray,
} from "../ScriptEditorParts/script-utils";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const ScriptBlockSelector = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const mobileRequiredFields = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent.requiredFields);
  const desktopRequiredFields = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent.requiredFields);

  const modalRef = useRef();
  const modalScoreRef = useRef();
  const tagsInputRef = useRef();
  const multiRef = useRef();
  const selectorRef = useRef();
  const nameRef = useRef();
  const requiredRef = useRef();
  const nosyncRef = useRef();
  const shortUID = useRef(Math.random().toString(36).slice(-8));
  const optionMap = useRef({});

  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";
  let requiredFields = (desktopRequiredFields || []).filter(Boolean);

  if (props.isMobile) {
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
    requiredFields = (mobileRequiredFields || []).filter(Boolean);
  }

  const [currentName, setCurrentName] = useState(props.definition.save || "");
  const [tmpName, setTmpName] = useState(props.definition.save || "");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionRating, setSelectedOptionRating] = useState(0);
  const [tmpSelectedOption, setTmpSelectedOption] = useState("");
  const [optionScores, setOptionScores] = useState(_.get(props.definition, `option_scores`, {}));
  const [labelAlreadyExist, setLabelAlreadyExist] = useState(false);
  const [isRequiredField, setIsRequiredField] = useState(
    requiredFields.indexOf(props.definition.save || "") >= 0
  );

  useEffect(() => {
    window.initJqueryPopover();
    updateDynamicField(currentName);
  }, []);

  useEffect(() => {
    initializeTagInput();
    initializeModalScoreEvents();
    window.initJqueryTooltip();
  });

  useUpdateEffect(() => {
    $(tagsInputRef.current)[0].selectize.focus();
  }, [props.definition.from]);

  const updateDynamicField = (value) => {
    if (!props.id) return;

    dispatch({
      type: "SET_DYNAMIC_FIELD",
      payload: {
        key: props.id.replace(/^\D+/g, ""),
        value: value,
      },
    });
  }

  const saveSettings = () => {
    if (!hasPermission) return;
    if (isInvalidName()) return;

    const newName = getCurrentName().trim();
    const isRequiredField = requiredRef.current.checked;

    let updatedRequiredFields = requiredFields.filter(
      (fieldName) => fieldName !== currentName
    );

    if (isRequiredField) {
      updatedRequiredFields.push(newName);
    }

    setTmpName(newName);
    setCurrentName(newName);
    updateDynamicField(newName);
    setIsRequiredField(isRequiredField);

    props.setDefinition({
      ...props.definition,
      save: newName,
      multi: multiRef.current.checked,
      selector: selectorRef.current.checked,
    });

    props.globalKeySet('requiredFields', updatedRequiredFields);
    dispatch({
      type: dispatchActionType,
      payload: {
        key: 'requiredFields',
        value: updatedRequiredFields
      }
    });

    $(nameRef.current).val(newName);
    $(modalRef.current).modal("hide");
  }

  const saveScore = () => {
    if (!hasPermission) return;

    const oldTagValue = selectedOption;
    const newTagValue = tmpSelectedOption.trim();

    let newTagsValue = tagsValue.split(";");

    if ((oldTagValue !== newTagValue && newTagsValue.includes(newTagValue)) || (newTagValue == '')) {
      return swal("", I18n.t("views.manage_flows.notification.already_exists_or_cannot_be_blank"), "error");
    }

    const updateIndex = newTagsValue.indexOf(oldTagValue);

    newTagsValue[updateIndex] = newTagValue;
    setTagsValue(newTagsValue.join(";"));

    let newOptionScores = {...optionScores};
    delete newOptionScores[oldTagValue];

    newOptionScores[newTagValue] = selectedOptionRating;
    setOptionScores(newOptionScores);

    props.setDefinition({
      ...props.definition,
      option_scores: newOptionScores,
      from: newTagsValue
    });

    $(modalScoreRef.current).modal('hide');
  }

  const handleInputName = (value) => {
    if (!hasPermission) return;

    const replacedValue = value.replace(/[^:\w\s]/gi, "");
    const labels = selectAllLabels();
    const previewLabels = removeItemFromArray(labels, currentName);

    setTmpName(replacedValue);
    setLabelAlreadyExist(previewLabels.includes(replacedValue.trim()));
  };

  const onChangeTagsInput = (value) => {
    const fromValue = value === "" ? [] : value.split(";");

    setTagsValue(value);

    let newOptionScores = { ...optionScores };
    Object.keys(newOptionScores).forEach(option => {
      if (!fromValue.includes(option)) {
        delete newOptionScores[option];
      }
    });

    setOptionScores(newOptionScores);

    const newDefinition = { ...props.definition, from: fromValue, option_scores: newOptionScores };
    if (Object.keys(newOptionScores).length === 0) {
      delete newDefinition.option_scores
    }

    props.setDefinition(newDefinition);
  }

  const initializeModalScoreEvents = () => {
    if (noSaveFieldData) return;

    $(`#${shortUID.current} .selectize-item`).on("click", (event) => {
      const $this = $(event.currentTarget);
      const selectedOption = $this.data("value");
      const selectedOptionRating = _.get(optionScores, selectedOption, 0);

      setSelectedOption(String(selectedOption));
      setTmpSelectedOption(String(selectedOption));
      setSelectedOptionRating(selectedOptionRating);

      $(modalScoreRef.current).modal('show');
    });
  }

  const initializeTagInput = () => {
    if ($(tagsInputRef.current)[0].selectize) {
      $(tagsInputRef.current)[0].selectize.destroy();
    }

    $(tagsInputRef.current).selectize({
      delimiter: ";",
      persist: false,
      maxOptions: 0,
      onChange: onChangeTagsInput,
      create: (input) => ({ value: input, text: input }),
      render: {
        option_create: (data, escape) => `
          <div class="create">
            + <strong>${escape(data.input)}</strong>&hellip;
          </div>
        `,
        item: (item, escape) => {
          let ratingIcons = '';

          if (!noSaveFieldData) {
            const rating = optionScores[item.value] || 0;

            if (!rating) {
              ratingIcons = '<img src="/images/lead_score/fire_off_deny.svg" class="me-1" width="12" />'
            }

            for (let index = 0; index < rating; index++) {
              ratingIcons += '<img src="/images/lead_score/fire_on.svg" class="me-1" width="12"/>';
            }
          }

          return `
            <div class="selectize-item" data-value="${item.value}" title="${I18n.t("views.manage_flows.selection_block.set_a_qualifying_grade")}">
              ${ratingIcons}${item.value}
            <div>
          `;
        },
      },
    });
  }

  const disableTagsInput = () => {
    const tagsInput = $(tagsInputRef.current);
    const selectizeInstance = _.get(tagsInput, '0.selectize', false)

    if (selectizeInstance) {
      selectizeInstance.disable();
    }
  }

  const getCurrentName = () => {
    const attributes = nameToAttributes(tmpName);

    attributes["nosync"] = nosyncRef.current
      ? nosyncRef.current.checked
      : false;

    return attributesToPrefix(attributes) + nameToSuffix(tmpName);
  };

  const attributes = () => {
    const attributes = {};

    for (let key of currentName.split("::").slice(0, -1)) {
      attributes[key] = true;
    }

    return attributes;
  };

  const buildOption = (opt) => {
    if (typeof opt === "string") {
      return opt;
    }

    if (opt.save || opt.say) {
      optionMap.current[opt.save] = opt.say;

      return opt.save || opt.say;
    }

    return "";
  }

  const isEmptyName = () => {
    return tmpName === "";
  };

  const isInvalidName = () => {
    return isEmptyName() || labelAlreadyExist;
  };

  const inputClassName = () => {
    const isInvalid = isInvalidName() ? "is-invalid" : "";
    return `form-control ${isInvalid}`;
  };

  if (!hasPermission) {
    disableTagsInput();
  }

  const [tagsValue, setTagsValue] = useState(
    props.definition.from.map(buildOption).join(";")
  );

  const nosync = attributes()["nosync"];
  const noSaveFieldData = currentName.startsWith("nosync::");

  return (
    <>
      <div className="row gx-2">
        <div className="col">
          <div className="input-group" id={shortUID.current}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{I18n.t("views.manage_flows.script_editor.click_here_to_edit_settings")}</Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-success pe-1"
                onClick={() => $(modalRef.current).modal('show')}
              >
                <i className="ti ti-list-details me-2" />
                {_.truncate(nameToSuffix(currentName), { length: 50 })}
                {isRequiredField && (<i className="fa fa-asterisk ms-2 text-warning" />)}
                <i className="ti ti-dots-vertical ms-2" />
              </button>
            </OverlayTrigger>

            <input
              type="text"
              className="form-control option-start-list"
              ref={tagsInputRef}
              placeholder={I18n.t("views.manage_flows.script_editor.list_of_options")}
              value={tagsValue}
              onChange={() => {}}
            />
          </div>
        </div>

        {!props.isFirstBlock && (
          <div className="col-auto">
            <CloneBlockButton onDuplicate={props.onDuplicate} enabled={hasPermission} />
          </div>
        )}

        <div className="col-auto">
          <DeleteButton onDelete={props.onDelete} />
        </div>

        {/* Settings Modal */}
        <div ref={modalRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <h5 className="modal-title">
                  {I18n.t("views.manage_flows.script_editor.user_response_settings")}
                </h5>
              </div>

              <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon>
                <div>
                  <div className="modal-body py-3">
                    <label className="form-label fw-medium">
                      {I18n.t("views.manage_flows.script_editor.field_name")}
                      <HelpPopover
                        className="ms-2"
                        content={I18n.t("views.manage_flows.script_editor.this_field_does_not_accept_accents")}
                      />
                    </label>

                    <input
                      type="text"
                      className={inputClassName()}
                      onChange={(e) => handleInputName(e.target.value)}
                      value={tmpName}
                      ref={nameRef}
                      maxLength="255"
                      placeholder={I18n.t("views.manage_flows.selection_block.enter_the_field_name")}
                    />

                    <div className="invalid-feedback">
                      {isEmptyName() && (<div>{I18n.t("views.manage_flows.selection_block.field_name_cannot_be_empty")}</div>)}
                      {labelAlreadyExist && (<div>{I18n.t("views.manage_flows.selection_block.field_name_already_exists")}</div>)}
                    </div>
                  </div>

                  <div className="modal-body py-3">
                    <div className="mb-3">
                      <label className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          ref={multiRef}
                          defaultChecked={props.definition.multi}
                        />
                        <span className="form-check-label fw-medium">
                          {I18n.t("views.manage_flows.selection_block.multiple_choice")}
                        </span>
                      </label>

                      <div className="form-text text-body">
                        {I18n.t("views.manage_flows.selection_block.select_more_than_one_option")}
                      </div>
                    </div>

                    <div className='mb-3'>
                      <label className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          ref={selectorRef}
                          defaultChecked={props.definition.selector}
                        />
                        <span className="form-check-label fw-medium">
                          {I18n.t("views.manage_flows.selection_block.use_simple_selector")}
                        </span>
                      </label>

                      <div className="form-text text-body">
                        {I18n.t("views.manage_flows.selection_block.recommendation_for_list_selector")}
                      </div>
                    </div>

                    <div>
                      <label className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          ref={requiredRef}
                          defaultChecked={isRequiredField}
                        />
                        <span className="form-check-label fw-medium">
                          {I18n.t("views.manage_flows.selection_block.required_field")}
                        </span>
                      </label>

                      <div className="form-text text-body">
                        {I18n.t("views.manage_flows.selection_block.leads_that_dont_answer_your_question")}
                      </div>
                    </div>

                    {props.isFirstBlock && (
                      <div className="mt-3">
                        <label className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            ref={nosyncRef}
                            defaultChecked={nosync}
                          />
                          <span className="form-check-label fw-medium">
                            {I18n.t("views.manage_flows.selection_block.do_not_store_this_information")}
                          </span>
                        </label>

                        <div className="form-text text-body">
                          {I18n.t("views.manage_flows.selection_block.will_not_use_this_information")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </PermissionsGate>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn me-auto"
                  onClick={() => $(modalRef.current).modal("hide")}
                >
                  {I18n.t("shared.actions.cancel")}
                </button>

                <PermissionsGate scope={PERMISSION_SCOPE}>
                  <button
                    type="button"
                    className="btn btn-success"
                    disabled={isInvalidName()}
                    onClick={saveSettings}
                  >
                    {I18n.t("shared.actions.save")}
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Score Modal */}
      <div ref={modalScoreRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h5 className="modal-title">
                {I18n.t("views.manage_flows.selection_block.user_response_score")}
              </h5>
            </div>

            <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon>
              <div className="modal-body py-3">
                <div className="input-group mb-3">
                  <span className="input-group-text text-body fw-medium">
                    {I18n.t("views.manage_flows.selection_block.field_name")}
                  </span>

                  <input
                    type="text"
                    className="form-control"
                    value={tmpSelectedOption}
                    onChange={(e) => { setTmpSelectedOption(e.target.value); }}
                  />
                </div>

                <div className="input-group">
                  <span className="input-group-text text-body fw-medium">
                    {I18n.t("views.manage_flows.selection_block.lead_score")}
                    <a
                      href={I18n.t("shared.helpscout.lead_qualification_feature")}
                      target="_blank"
                      className="form-help ms-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={I18n.t("views.manage_flows.selection_block.see_how_to_set_up_lead_qualification")}
                    >
                      <i className="fa fa-info" />
                    </a>
                  </span>

                  <div className="form-control fire-rating-selector justify-content-start">
                    <div className="d-inline-block">
                      <input type="radio" onChange={() => {}} checked={selectedOptionRating === 5} />
                      <label onClick={() => setSelectedOptionRating(5)} />

                      <input type="radio" onChange={() => {}} checked={selectedOptionRating === 4} />
                      <label onClick={() => setSelectedOptionRating(4)} />

                      <input type="radio" onChange={() => {}} checked={selectedOptionRating === 3} />
                      <label onClick={() => setSelectedOptionRating(3)} />

                      <input type="radio" onChange={() => {}} checked={selectedOptionRating === 2} />
                      <label onClick={() => setSelectedOptionRating(2)} />

                      <input type="radio" onChange={() => {}} checked={selectedOptionRating === 1} />
                      <label onClick={() => setSelectedOptionRating(1)} />
                    </div>
                  </div>

                  <button
                    className="btn"
                    type="button"
                    onClick={() => setSelectedOptionRating(0)}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={I18n.t("views.leads.filter.withdraw_selection")}
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
            </PermissionsGate>

            <div className="modal-footer">
              <button
                type="button"
                className="btn me-auto"
                onClick={() => $(modalScoreRef.current).modal("hide")}
              >
                {I18n.t("shared.actions.cancel")}
              </button>

              <PermissionsGate scope={PERMISSION_SCOPE}>
                <button
                  type="button"
                  className={"btn btn-success"}
                  onClick={saveScore}
                >
                  {I18n.t("shared.actions.save")}
                </button>
              </PermissionsGate>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(ScriptBlockSelector, (prevProps, nextProps) => {
  return prevProps.definition === nextProps.definition;
});
