import { Modal, Button, Spinner } from "react-bootstrap";
import IntegrationTabs from "../IntegrationTabs";
import * as S from "./styles";

const I18N_SCOPE = "views.users.edit.company_form";

const EventCustomizationModal = ({
  showModal,
  handleModal,
  integrations,
  activeSlug,
  handleTabChange,
  isLoading,
  events,
  saving,
  hasChanges,
  saveChanges,
  isEventRemoved,
  toggleEvent
}) => {
  return (
    <Modal show={showModal} onHide={handleModal} centered size="lg">
      <S.StyledModalHeader closeButton className="d-flex flex-column align-items-start py-3 gap-2">
        <Modal.Title className="fs-3 fw-bold">{I18n.t(`${I18N_SCOPE}.event_customization.title`)}</Modal.Title>
        <Modal.Title className="fs-4">{I18n.t(`${I18N_SCOPE}.event_customization.subtitle`)}</Modal.Title>
      </S.StyledModalHeader>
      <S.StyledModalBody>
        {integrations.length > 0 ? (
          <IntegrationTabs
            integrations={integrations}
            activeSlug={activeSlug}
            handleTabChange={handleTabChange}
            isLoading={isLoading}
            events={events}
            isEventRemoved={isEventRemoved}
            toggleEvent={toggleEvent}
            saving={saving}
          />
        ) : (
          <div className="text-center py-5">
            <p>{I18n.t(`${I18N_SCOPE}.event_customization.no_integrations`)}</p>
          </div>
        )}
      </S.StyledModalBody>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={handleModal} className="me-2 btn btn-outline-primary">
          {I18n.t(`${I18N_SCOPE}.event_customization.cancel_button`)}
        </Button>
        <Button
          variant="primary"
          onClick={saveChanges}
          disabled={saving || !hasChanges}
        >
          {saving ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
              {I18n.t(`${I18N_SCOPE}.event_customization.saving`)}
            </>
          ) : I18n.t(`${I18N_SCOPE}.event_customization.save_button`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventCustomizationModal;
