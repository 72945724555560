import styled from "styled-components";

export const CardInfo = styled.div.attrs(({ color }) => ({
  className: `bg-opacity-75 rounded p-3 my-4 bg-${color || 'blue-lt'}`,
}))`
  max-height: 90px;
  overflow: auto;
`;

export const CardContainer = styled.div.attrs({
  className: 'justify-content-center',
})`
  min-height: 128px;
  max-height: 128px;
`;

export const CardCarousel = styled.div.attrs({
  className: 'mx-auto',
})`
  width: 440px;
`;
