import { useState, useEffect, useRef } from "react";
import { Button, Spinner } from "react-bootstrap";

import PropTypes from "prop-types";
import swal from "sweetalert";

import axios from "@/lib/axios";

const I18N_SCOPE = "views.dashboard.integrate_reportei";

const IntegrateReporteiButton = ({
  onCloseModal,
  icon,
  buttonName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef(null);
  const popupRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      window.removeEventListener("focus", handleFocusAfterPopupClose);
    };
  }, []);

  const handleFocusAfterPopupClose = async () => {
    try {
      await axios.get("/reportei_integration");
    } catch (error) {
      const errorMsg =
        error.response && error.response.data && error.response.data.error
          ? `${I18n.t(`${I18N_SCOPE}.modal.error_messages.error_integrating`)}: ${error.response.data.error}`
          : I18n.t(`${I18N_SCOPE}.modal.error_messages.unexpected_error`);
      swal("", errorMsg, "error");
    } finally {
      setIsLoading(false);
      window.location.reload();
      if (typeof onCloseModal === "function") {
        onCloseModal();
      }
    }
  };

  const checkPopupClosed = () => {
    if (popupRef.current && popupRef.current.closed) {
      if (document.hasFocus()) {
        return handleFocusAfterPopupClose();
      }
      return window.addEventListener("focus", handleFocusAfterPopupClose, { once: true });
    }

    timerRef.current = setTimeout(checkPopupClosed, 500);
  };

  const handleIntegrate = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post("/reportei_integration");
      const data = response.data;

      if (data.session_link) {
        const popupWidth = 1200;
        const popupHeight = 800;

        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth || document.documentElement.clientWidth || screen.width;
        const height = window.innerHeight || document.documentElement.clientHeight || screen.height;

        const left = width / 2 - popupWidth / 2 + dualScreenLeft;
        const top = height / 2 - popupHeight / 2 + dualScreenTop;

        const popup = window.open(
          data.session_link,
          "ReporteiIntegration",
          `scrollbars=yes, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`
        );

        if (!popup) {
          swal("", I18n.t(`${I18N_SCOPE}.modal.error_messages.error_popup_blocked`), "error");
          setIsLoading(false);
          return;
        }

        popupRef.current = popup;
        if (window.focus) {
          popup.focus();
        }

        checkPopupClosed();
      } else {
        swal("", I18n.t(`${I18N_SCOPE}.modal.error_messages.invalid_session_link`), "error");
        setIsLoading(false);
      }
    } catch (error) {
      const errorMsg =
        error.response && error.response.data && error.response.data.error
          ? `${I18n.t(`${I18N_SCOPE}.modal.error_messages.error_integrating`)}: ${error.response.data.error}`
          : I18n.t(`${I18N_SCOPE}.modal.error_messages.unexpected_error`);
      swal("", errorMsg, "error");
      setIsLoading(false);
    }
  };

  return (
    <Button variant="primary" className="fs-4 fw-bold" onClick={handleIntegrate} disabled={isLoading}>
      {isLoading ? (
        <Spinner animation="border" role="status" size="sm" className="me-2" />
      ) : (
        icon && icon
      )}
      {buttonName}
    </Button>
  );
};

IntegrateReporteiButton.propTypes = {
  onCloseModal: PropTypes.func,
  icon: PropTypes.node,
  buttonName: PropTypes.string,
};

IntegrateReporteiButton.defaultProps = {
  icon: <i className="ti ti-plug icon me-2" />,
  buttonName: I18n.t(`${I18N_SCOPE}.button.integrate`),
};

export default IntegrateReporteiButton;
