import { useState, useEffect } from "react";

import usePagination from "@/hooks/usePagination";

const useFetchMessages = (filters = {}, dependencies = []) => {
  const [messagesList, setMessagesList] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
  }, [...dependencies]);

  const { isLoading, currentPage, setCurrentPage, nextPage } = usePagination(
    "/ai/history_tracker",
    {
      filters,
      dependencies,
      defaultDataValue: [],
      dataIndex: "history_trackers",
      onSuccess: (data, paginationData) => {
        const messagesData = currentPage === 1 ? [...data] : [...messagesList, ...data];

        setMessagesList(messagesData);
        setHasMore(!!paginationData.next_page);
      },
    }
  );

  return { messagesList, isLoading, hasMore, nextPage };
};

export default useFetchMessages;
