import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import LineChartCard from "../LineChartCard";
import LineChartOptionsPercentage from "../LineChartCard/LineChartOptionsPercentage";

import axios from "@/lib/axios";

const AdsAnalytics = ({ analyticChannel }) => {
  const [conversionRate, setConversionRate] = useState("0%");
  const [completedLeads, setCompletedLeads] = useState("0%");

  const [generatedLeads, setGeneratedLeads] = useState(0);
  const [uniqueVisitors, setUniqueVisitors] = useState(0);

  const [generatedLeadsChartData, setGeneratedLeadsChartData] = useState([]);
  const [uniqueVisitorsChartData, setUniqueVisitorsChartData] = useState([]);
  const [completedLeadsChartData, setCompletedLeadsChartData] = useState([]);
  const [conversionRateChartData, setConversionRateChartData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [filter, setFilter] = useState([]);

  const updateData = useCallback(async () => {
    const dateRange = $("#date-range").val();
    const splitedDateRange = dateRange.split(" - ");
    const startDate = splitedDateRange[0];
    const endDate = splitedDateRange[1];
    const scriptId = $("#analytics-script").val();
    const mobile = $("input[name=mobile]:checked").val();
    const score = $('input[name="lead_score"]:checked').val();

    const dataFilter = {
      start_date: startDate,
      end_date: endDate,
      script_id: scriptId,
      mobile,
      score,
      analytic_channel: analyticChannel,
    };

    setFilter(dataFilter);

    try {
      setIsLoading(true);

      const response = await axios.get("/dashboard-metrics/ads", { params: dataFilter });
      const { data } = response;

      setUniqueVisitors(data.unique_visitors);
      setGeneratedLeads(data.total_leads);
      setConversionRate(data.conversion_rate);
      setCompletedLeads(data.completed_leads);

      setGeneratedLeadsChartData(data.total_leads_chart);
      setUniqueVisitorsChartData(data.unique_visitors_chart);
      setCompletedLeadsChartData(data.completed_leads_chart);
      setConversionRateChartData(data.conversion_rate_chart);
    } catch (e) {
      setUniqueVisitors(0);
      setGeneratedLeads(0);
      setConversionRate("0%");
      setCompletedLeads("0%");

      setGeneratedLeadsChartData([]);
      setUniqueVisitorsChartData([]);
      setCompletedLeadsChartData([]);
      setConversionRateChartData([]);

      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    $("#analytics-script").on("change", () => updateData());
    $("input[name=mobile]").on("change", () => updateData());
    $('input[name="lead_score"]').on("change", () => updateData());
    $("#date-range").on("change", () => updateData());
    updateData();
  }, []);

  return (
    <div className="row row-deck row-cards">
      <div className="col-6 col-md-3">
        <LineChartCard
          data={generatedLeadsChartData}
          value={generatedLeads}
          label={I18n.t("views.dashboard.ads.generated_leads")}
          isLoading={isLoading}
          hasError={hasError}
        />
      </div>

      <div className="col-6 col-md-3">
        <LineChartCard
          data={conversionRateChartData}
          options={LineChartOptionsPercentage}
          value={conversionRate}
          label={I18n.t("views.dashboard.ads.conversion_rate")}
          isLoading={isLoading}
          hasError={hasError}
        />
      </div>

      <div className="col-6 col-md-3">
        <LineChartCard
          data={uniqueVisitorsChartData}
          value={uniqueVisitors}
          label={I18n.t("views.dashboard.ads.unique_visitors")}
          isLoading={isLoading}
          hasError={hasError}
        />
      </div>

      <div className="col-6 col-md-3">
        <LineChartCard
          data={completedLeadsChartData}
          value={completedLeads}
          options={LineChartOptionsPercentage}
          label={I18n.t("views.dashboard.ads.completed_leads")}
          isLoading={isLoading}
          hasError={hasError}
        />
      </div>
    </div>
  );
};

AdsAnalytics.propTypes = {
  analyticChannel: PropTypes.string.isRequired,
};

export default AdsAnalytics;
