import { can } from "@/lib/user-permission";

import useCurrentUserStore from "@/store/currentUserStore";
import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import { getCustomVariable } from "@/lib/custom-variables";

import parse from "html-react-parser";

import * as S from "./style";

const RenderCard = ({ isLoading, error }) => {
  const hasPermission = can({ action: "manage", subject: "generate_ai_calls" });
  const { companyId } = useCurrentUserStore((state) => state.currentUser);

  const renderCard = () => {
    if (error && !isLoading) {
      return (
        <S.CardInfo color="red-lt">
          <div className="overflow-auto my-3">
            {parse(error)}
          </div>
        </S.CardInfo>
      );
    }

    if (!hasPermission) {
      return (
        <S.CardInfo>
          <div className="my-3 d-flex align-items-center">
            <i className="ti ti-lock fs-2" />
            <div className="ms-2 text-black">
              <span className="fw-bold fs-3 d-block">
                {I18n.t("views.manage_flows.call_models.ai_calls.card_info.not_permission_title")}
              </span>
              <span className="mt-2">
                {I18n.t("views.manage_flows.call_models.ai_calls.card_info.not_permission_description")}
              </span>
              <a
                className="text-primary ms-1"
                href={buildWhatsAppLink({
                  number: getCustomVariable("whatsapp_number_sales_1"),
                  message: I18n.t("services.chatgpt.whatsapp_text.block_alert", { company_id: companyId }),
                })}
                target="_blank"
              >
                {I18n.t("shared.actions.talk_to_our_team")}
              </a>
            </div>
          </div>
        </S.CardInfo>
      );
    }

    if (isLoading) {
      return (
        <S.CardInfo>
          <div className="d-flex align-items-center my-3">
            <div className="spinner-border text-primary me-3" role="status" />
            <span className="mb-1 text-black">
              {parse(I18n.t("views.manage_flows.call_models.ai_calls.card_info.is_loading_html"))}
            </span>
          </div>
        </S.CardInfo>
      );
    }

    return (
      <S.CardInfo>
        <div className="my-3 d-flex align-items-center">
          <i className="ti ti-exclamation-circle fs-2" />
          <div className="ms-2 text-black">
            <span className="fw-bold fs-3 d-block">
              {I18n.t("views.manage_flows.call_models.ai_calls.card_info.title")}
            </span>
            <span className="d-block mt-2">
              {I18n.t("views.manage_flows.call_models.ai_calls.card_info.description")}
            </span>
          </div>
        </div>
      </S.CardInfo>
    );
  };

  return (
    <>
      {renderCard()}
    </>
  );

};

export default RenderCard;
