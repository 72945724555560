export const metaEvents = [
  { label: "AddPaymentInfo", value: "AddPaymentInfo" },
  { label: "AddToCart", value: "AddToCart" },
  { label: "AddToWishlist", value: "AddToWishlist" },
  { label: "CompleteRegistration", value: "CompleteRegistration" },
  { label: "Contact", value: "Contact" },
  { label: "CustomizeProduct", value: "CustomizeProduct" },
  { label: "Donate", value: "Donate" },
  { label: "FindLocation", value: "FindLocation" },
  { label: "InitiateCheckout", value: "InitiateCheckout" },
  { label: "Lead", value: "Lead" },
  { label: "Purchase", value: "Purchase" },
  { label: "Schedule", value: "Schedule" },
  { label: "Search", value: "Search" },
  { label: "StartTrial", value: "StartTrial" },
  { label: "SubmitApplication", value: "SubmitApplication" },
  { label: "Subscribe", value: "Subscribe" },
  { label: "ViewContent", value: "ViewContent" },
];

export const googleEvents = [
  { label: "add_payment_info", value: "add_payment_info" },
  { label: "add_shipping_info", value: "add_shipping_info" },
  { label: "add_to_cart", value: "add_to_cart" },
  { label: "add_to_wishlist", value: "add_to_wishlist" },
  { label: "begin_checkout", value: "begin_checkout" },
  { label: "earn_virtual_currency", value: "earn_virtual_currency" },
  { label: "generate_lead", value: "generate_lead" },
  { label: "join_group", value: "join_group" },
  { label: "level_end", value: "level_end" },
  { label: "level_start", value: "level_start" },
  { label: "level_up", value: "level_up" },
  { label: "login", value: "login" },
  { label: "post_score", value: "post_score" },
  { label: "purchase", value: "purchase" },
  { label: "refund", value: "refund" },
  { label: "remove_from_cart", value: "remove_from_cart" },
  { label: "search", value: "search" },
  { label: "select_content", value: "select_content" },
  { label: "select_item", value: "select_item" },
  { label: "select_promotion", value: "select_promotion" },
  { label: "share", value: "share" },
  { label: "sign_up", value: "sign_up" },
  { label: "spend_virtual_currency", value: "spend_virtual_currency" },
  { label: "tutorial_begin", value: "tutorial_begin" },
  { label: "tutorial_complete", value: "tutorial_complete" },
  { label: "unlock_achievement", value: "unlock_achievement" },
  { label: "view_cart", value: "view_cart" },
  { label: "view_item", value: "view_item" },
  { label: "view_item_list", value: "view_item_list" },
  { label: "view_promotion", value: "view_promotion" },
];
