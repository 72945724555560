import { useRef } from "react";

import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";

import StartNewTrialButton from "@/components/leadster_ia/shared/StartNewTrialButton";
import StartTrialGuide from "@/components/leadster_ia/shared/StartTrialGuide";
import ChatWithUs from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ResponseLimit/components/ChatWithUs";

import useCurrentUserStore from "@/store/currentUserStore";
import useResponseTracker from "./hooks/useResponseTracker";
import dayjs from "@/lib/dayjs";

const ResponseLimit = () => {
  const {
    locale: userLocale,
    plugins: { leadsterAi: { allowedNewTrial, isTrialStarted, isTrialing, isPurchased, endBalancePeriod } },
  } = useCurrentUserStore((state) => state.currentUser);

  const { limitResponses, consumedResponses, reachedLimit } = useResponseTracker();

  const trialGuideModalRef = useRef();

  const endPeriod = dayjs(endBalancePeriod).add(1, "day").format(I18n.t("js.formats.default.date"));

  const showStartTrial = !isTrialStarted && !isTrialing && !isPurchased;

  const showRenewTrial = allowedNewTrial;

  const showEndTrialInfo = !isPurchased && !showStartTrial && !showRenewTrial && reachedLimit;

  const showOverConsumptionInfo = isPurchased && reachedLimit;

  const forceShowGuide = (e) => {
    e.preventDefault();
    trialGuideModalRef.current.show();
  };

  return (
    <Card>
      <Card.Body>
        {showStartTrial &&
          <>
            <div className="rounded-1 bg-primary-lt p-2">
              <p className="text-body fw-medium small mb-0">
                {I18n.t("views.leadster_ai.page.response_limit.start_trial.text_1")}
              </p>
            </div>

            <div className="border border-primary shadow text-center rounded-1 mt-3 p-3">
              <p className="small fw-bold">
                {I18n.t("views.leadster_ai.page.response_limit.start_trial.text_2")}
              </p>

              <Button className="btn-outline-primary" onClick={forceShowGuide}>
                <i className="ti ti-sparkles icon me-2"/>
                {I18n.t("views.leadster_ai.page.response_limit.start_trial.button_label")}
              </Button>
            </div>

            <StartTrialGuide modal={trialGuideModalRef} />

            <hr/>
          </>
        }

        {showRenewTrial &&
          <>
            <div className="rounded-1 bg-primary-lt p-2">
              <p className="text-body fw-medium small mb-0">
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_1")}{" "}
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_2")}
              </p>
            </div>

            <div className="border border-primary shadow text-center rounded-1 mt-3 p-3">
              <p className="small fw-bold">
                {I18n.t("views.leadster_ai.page.response_limit.allowed_trial.text_3")}
              </p>

              <StartNewTrialButton variant="outline-primary" />
            </div>

            <hr/>
          </>
        }

        <>
          <div className="fs-3 fw-medium mb-2">
            {I18n.t("views.leadster_ai.page.response_limit.title")}

            <OverlayTrigger
              overlay={
                <Popover className="bg-white p-2">
                  {I18n.t("views.leadster_ai.page.response_limit.tooltip")}
                </Popover>
              }
            >
              <span className="form-help d-none d-sm-inline px-1 ms-2">?</span>
            </OverlayTrigger>
          </div>

          <div className="mb-2 small">
            {I18n.t("views.leadster_ai.page.response_limit.subtitle")}
          </div>

          <div className="h3 fw-medium">
            {I18n.t("views.leadster_ai.page.response_limit.limit", {
              limit: limitResponses.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
              consumed: consumedResponses.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
            })}
          </div>
        </>

        {showEndTrialInfo &&
          <ChatWithUs
            smallMsg={I18n.t("views.leadster_ai.page.response_limit.end_trial.small_msg")}
            whatsMsg={I18n.t("views.leadster_ai.page.response_limit.end_trial.whats_msg")}
          />
        }

        {showOverConsumptionInfo &&
          <ChatWithUs
            smallMsg={I18n.t("views.leadster_ai.page.response_limit.over_consumption.small_msg", {end_period: endPeriod})}
            whatsMsg={I18n.t("views.leadster_ai.page.response_limit.over_consumption.whats_msg")}
          />
        }
      </Card.Body>
    </Card>
  );
};

export default ResponseLimit;
