import { useState, useEffect } from "react";

import ListMultipleUrls from "../AddWebsiteLink/components/ListMultipleUrls";

import { useAssistantContext } from "@/contexts/AssistantContext";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import parse from "html-react-parser";

import axios from "@/lib/axios";

import * as S from "./styles";

const Alert = ({ onSeePages, onCancel, onClose }) => {
  useCreateYourAiAssistant("/content");

  const { assistant, setDisabledContent } = useAssistantContext();
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState("");
  const [showSeePagesBtn, setShowSeePagesBtn] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [typeAlert, setTypeAlert] = useState("alert-warning");
  const [statusAlert, setStatusAlert] = useState(assistant?.status_alert);

  const getPendingBase = () => {
    return assistant?.pending_knowledge_base || {};
  };

  const getPath = () => {
    return assistant?.path || getPendingBase()?.filename || getPendingBase()?.url || "";
  };

  const isMultipleUrlProcess = () => {
    const { has_multiple_url_process_data } = assistant || {};
    return getPendingBase()?.has_multiple_url_process_data || has_multiple_url_process_data;
  };

  const handleSeePages = () => {
    setShowModal(true);
    if (typeof onSeePages === "function") onSeePages();
  };

  const handleDeleteMultipleUrl = async ({ displayNotification = false } = {}) => {
    const urlApi = `/ai/multi_url_processes/assistant/${assistant.id}`;

    await axios.delete(urlApi).finally(() => {
      if (displayNotification) {
        toastr.success(I18n.t("views.leadster_ai.page.add_content.add_links.alerts.success_on_cancel"));
      }
    });
  };

  const handleCancel = async () => {
    await handleDeleteMultipleUrl({ displayNotification: true });
    await setDisabledContent(false);

    if (typeof onCancel === "function") onCancel();
  };

  const handleClose = async () => {
    await handleDeleteMultipleUrl();

    if (typeof onClose === "function") onClose();
  };

  const resetSettings = () => {
    setText("");
    setShowSeePagesBtn(false);
    setShowCancelBtn(false);
    setTypeAlert("alert-warning");
  };

  const isProcessComplete = (status) => {
    return status === "waiting_for_user" || status === "done" || status === "error";
  };

  useEffect(() => {
    if (assistant?.status_alert === "done" || assistant?.status_alert === "error") {
      setDisabledContent(false);
    }

    resetSettings();
    setStatusAlert(assistant?.status_alert);
  }, [assistant?.status_alert]);

  useEffect(() => {
    if (statusAlert) {
      if (statusAlert === "uploading_file") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.file", { filename: getPath() }));
        return;
      }
      if (statusAlert === "searching_pages") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.url", { url: getPath() }));
        if (isMultipleUrlProcess()) {
          setShowCancelBtn(true);
        }
        return;
      }
      if (statusAlert === "waiting_for_user") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.select_urls"));
        setShowSeePagesBtn(true);
        setShowCancelBtn(true);
        return;
      }
      if (statusAlert === "recording_data") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.process_urls", { url: getPath() }));
        return;
      }
      if (statusAlert === "done") {
        setText(I18n.t("views.leadster_ai.page.add_content.loading.sucess_urls", { url: getPath() }));
        setTypeAlert("alert-success");
        setShowCloseBtn(true);
        return;
      }
      if (statusAlert === "error") {
        setText(I18n.t("views.leadster_ai.page.add_content.alerts.error", { url: getPath() }));
        setTypeAlert("alert-danger");
        setShowCloseBtn(true);
        return;
      }
    }
  }, [statusAlert]);

  return (
    typeof statusAlert === "string" && (
      <S.Content typeAlert={typeAlert}>
        <S.Spinner hidden={isProcessComplete(statusAlert)} />
        <div className="flex-grow-1 text-break">
          {parse(text)}
          <S.SeePages hidden={!showSeePagesBtn} onClick={handleSeePages}>
            {I18n.t("shared.actions.see_pages")}
          </S.SeePages>
          <S.Cancel hidden={!showCancelBtn} onClick={handleCancel}>
            {I18n.t("shared.actions.cancel")}
          </S.Cancel>
          <div
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="close"
            hidden={!showCloseBtn}
            onClick={handleClose}
          ></div>
          {showModal && <ListMultipleUrls show={showModal} onClose={() => setShowModal(false)} />}
        </div>
      </S.Content>
    )
  );
};

export default Alert;
