import { useState, useEffect } from "react";
import useUpdateEffect from "@/hooks/useUpdateEffect";

import { Modal, Tab } from "react-bootstrap";

import MessageItem from "./MessageItem";

import usePagination from "@/hooks/usePagination";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";

import * as S from "./styles.js";

const MessagesModal = ({ handleClose, title, filters, assistantId }) => {
  const [activeKey, setActiveKey] = useState(filters.tab || "satisfactory");
  const [messages, setMessages] = useState([]);

  const {
    data,
    paginationData,
    isLoading,
    setCurrentPage,
  } = usePagination(`/ai/assistants/${assistantId}/qualified_messages`, {
    defaultDataValue: [],
    defaultPerPage: 10,
    dataIndex: "messages",
    errorFeedbackLib: false,
    dependencies: [activeKey],
    filters: {
      start_date: filters.startDate,
      end_date: filters.endDate,
      script_id: filters.scriptId,
      tab: activeKey,
    },
  });

  const { targetRef: targetRefSatisfied } = useInfiniteScroll({
    callback: () => setCurrentPage(paginationData.next_page),
    threshold: 0.3,
    enabled: activeKey === "satisfactory" && !!paginationData.next_page,
  });

  const { targetRef: targetRefUnsatisfied } = useInfiniteScroll({
    callback: () => setCurrentPage(paginationData.next_page),
    threshold: 0.3,
    enabled: activeKey === "unsatisfactory" && !!paginationData.next_page,
  });

  useEffect(() => {
    setMessages(data);
  }, [activeKey]);

  useEffect(() => {
    if (data.length > 0) {
      setMessages((prev) => [...prev, ...data]);
    }
  }, [data]);

  useUpdateEffect(() => {
    setCurrentPage(1);
    setMessages([]);
  }, [activeKey]);

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      dialogClassName="modal-xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ minHeight: "80vh" }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-start w-100">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 bg-white rounded-bottom" style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <S.Tabs
          activeKey={activeKey}
          id="custom-tabs"
          onSelect={handleSelect}
          className="mb-0 border-bottom pb-2 pt-3 ps-3"
        >
          <Tab
            eventKey="satisfactory"
            title={
              <span>
                <i className={`icon ti ti-thumb-up text-primary me-2`}/>
                {I18n.t("views.leadster_ai.page.analytics.messages_modal.tab_satisfied")}
              </span>
            }
          >
            <div>
              <div className="py-3" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                {messages.length === 0 && !isLoading && (
                  <div className="align-items-center justify-content-center">
                    <div className="h3 text-center mb-2">
                      {I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.title")}
                    </div>
                    <p className="text-center">
                      {I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.description")}
                    </p>
                  </div>
                )}

                {messages.map((message, index) => {
                  return (
                    <MessageItem key={index} message={message} />
                  )
                })}

                {isLoading &&
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                }

                {!isLoading > 0 &&
                  <div ref={targetRefSatisfied} style={{ height: "2px" }} />
                }
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="unsatisfactory"
            title={
              <span>
                <i className={`icon ti ti-thumb-down text-primary me-2`}/>
                {I18n.t("views.leadster_ai.page.analytics.messages_modal.tab_dissatisfied")}
              </span>
            }
          >
            <div>
              <div className="py-3" style={{ maxHeight: "60vh", overflowY: "auto" }}>
                {messages.length === 0 && !isLoading && (
                  <div>
                    <div className="h3 text-center mb-2">
                      {I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.title")}
                    </div>
                    <p className="text-center">
                      {I18n.t("views.leadster_ai.page.analytics.history.idle_message_details.description")}
                    </p>
                  </div>
                )}

                {messages.map((message, index) => {
                  return (
                    <MessageItem key={index} message={message} />
                  )
                })}

                {isLoading &&
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                }

                {!isLoading > 0 &&
                  <div ref={targetRefUnsatisfied} style={{ height: "2px" }} />
                }
              </div>
            </div>
          </Tab>
        </S.Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default MessagesModal;
