import parse from "html-react-parser";

import { can } from "@/lib/user-permission";

const EmptyAlert = ({ footerText }) => {
    const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });

  return (
    <>
      <div className="avatar avatar-rounded mb-2 bg-primary">😴</div>
      <div className="text-muted fw-normal mb-3">
        {parse(I18n.t("views.leadster_ai.page.analytics.dashboard.graph.no_data_html"))}
      </div>
      {hasPermission && (<div className="alert-warning mt-4 fs-4 w-100">
        <i className="ti ti-point-filled alert-icon p-0 m-0" />
        {footerText}
      </div>)}
    </>
  );
};

export default EmptyAlert;
