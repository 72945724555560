const InfiniteScrollLoader = () => {
  return (
    <div className="d-flex align-items-center text-secondary justify-content-center mt-2">
      <div className="spinner-border spinner-border-sm me-2" />
      {I18n.t("views.leadster_ai.page.analytics.history.loading_more_items")}
    </div>
  );
};

export default InfiniteScrollLoader;
