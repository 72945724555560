import { useState } from "react";
import { Modal } from "react-bootstrap";

import * as S from "./styles";

const DemoModal = ({ show, onClose, scriptId }) => {
  const [isReady, setIsReady] = useState(false);

  const handleIframeLoad = () => {
    setIsReady(true);
  };

  return (
    <S.ChatModal
      show={show}
      onHide={onClose}
      centered
      dialogClassName="no-padding-modal"
      backdrop="static"
      keyboard={false}
      className="modal-blur"
    >
      <Modal.Body>
        <S.Container>
          {isReady && (
            <S.CloseButton onClick={onClose}>
              <i className="ti ti-circle-x icon alert-icon"></i>
            </S.CloseButton>
          )}
          {scriptId && (
            <S.Iframe src={`/scripts/${scriptId}/demo?integration_test=true`} onLoad={handleIframeLoad} />
          )}
        </S.Container>
      </Modal.Body>
    </S.ChatModal>
  );
};

export default DemoModal;
