import styled from "styled-components";
import { Button as BootstrapButton } from "react-bootstrap";

export const Container = styled.div`
  position: relative;
  background: linear-gradient(
    45deg,
    rgba(227, 248, 255, 1) 2%,
    rgba(222, 239, 255, 1) 7%,
    rgba(210, 230, 255, 1) 60%,
    rgba(218, 242, 255, 1) 78%,
    rgba(212, 252, 248, 1) 90%,
    rgba(212, 252, 248, 1) 100%
  );
  border-radius: 8px;
  padding: 55px 115px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 2px;
  display: flex;
  gap: 10px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const MinimizeButton = styled(BootstrapButton)`
  position: absolute;
  top: 2px;
  right: ${(props) => (props.canCloseBanner ? "100px" : "2px")};
  text-decoration: none;
  color: #475569;
  background: transparent;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: none;
    color: #475569;
  }
`;

export const CloseButton = styled(BootstrapButton).attrs(({ canCloseBanner }) => ({
  style: {
    right: canCloseBanner ? "100px" : "2px",
  },
}))`
  position: absolute;
  top: 2px;
  text-decoration: none;
  color: #475569;
  background: transparent;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: none;
    color: #475569;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 70px;
  flex-wrap: wrap;
`;

export const LeftContent = styled.div`
  width: 385px;
`;

export const RightContent = styled.div`
  flex: 1;
  min-width: 300px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Info = styled.div`
  max-width: 100%;
`;

export const TagNew = styled.h3`
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  background: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 2rem;
`;

export const GradientText = styled.span`
  background: linear-gradient(
    to right,
    #006ff9,
    #0092ff,
    #00c4fe,
    #00ced1
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`;

export const Description = styled.p`
  font-size: 1rem;
  color: #475569;
  margin-bottom: 1rem;
`;

export const LearnMore = styled.a`
  font-weight: 700;
  text-decoration: none;
  color: #2c3e50;

  &:hover {
    text-decoration: none;
    color: #2c3e50;
  }
`;

export const IntegrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const IntegrationTitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: #2c3e50;
  margin: 0;
`;

export const IntegrationDescription = styled.p`
  font-size: 0.875rem;
  color: #475569;
  margin: 0;
`;

export const LogoImage = styled.img`
  margin-top: 6px;
  height: 47px;
`;

export const MiniBannerImage = styled.img`
  max-width: 100%;
`;
