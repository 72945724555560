import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom-color: #CCE2FB;
`;

export const StyledModalBody = styled(Modal.Body)`
  padding: 0.75rem 1.5rem 0 1.5rem;
  background-color: #fff;
`;
