import React, { useState, useEffect } from "react";

import ScriptCardBlock from "../ScriptCardBlock";
import ScriptCardFooter from "../ScriptCardFooter";
import HelpPopover from "@/components/shared/HelpPopover";
import PermissionsGate from '@/components/permissions/PermissionsGate';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/lib/user-permission";

import parse from "html-react-parser";

import gaLogoImg from "@/../assets/images/ga_logo.png";
import metaLogoImg from "@/../assets/images/meta_logo.png";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const MarketingEvents = () => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const desktopContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent);

  const convoScript = desktopContent;

  const [enableGoogleEvents, setEnableGoogleEvents] = useState(
    !!convoScript.gaConversionEvent
  );

  const [enableFacebookEvents, setenableFacebookEvents] = useState(
    !!convoScript.fbqConversionEvents
  );

  const [googleEventsData, setGoogleEventsData] = useState(
    !!convoScript.gaConversionEvent
      ? convoScript.gaConversionEvent
      : ["", "", ""]
  );

  const [facebookEventsData, setFacebookEventsData] = useState(
    !!convoScript.fbqConversionEvents
      ? convoScript.fbqConversionEvents
      : ["",""]
  );

  const setScriptGaConversionEvent = () => {
    const value =
      enableGoogleEvents && googleEventsData.filter((item) => !!item).length
        ? googleEventsData
        : null;

    dispatch({
      type: "SET_DESKTOP_AND_MOBILE_CONVOSCRIPT_VALUE",
      payload: { key: "gaConversionEvent", value },
    });
  };

  const setScriptFbqConversionEvent = () => {
    const value =
      enableFacebookEvents && !!facebookEventsData[0]
        ? facebookEventsData
        : null;

    dispatch({
      type: "SET_DESKTOP_AND_MOBILE_CONVOSCRIPT_VALUE",
      payload: { key: "fbqConversionEvents", value },
    });
  };

  useEffect(
    () => setScriptGaConversionEvent(),
    [JSON.stringify(googleEventsData), enableGoogleEvents]
  );

  useEffect(
    () => setScriptFbqConversionEvent(),
    [JSON.stringify(facebookEventsData), enableFacebookEvents]
  );

  useEffect(() => {
    window.initJqueryPopover();
  }, []);

  const cardBody = {
    title: `${I18n.t("views.manage_flows.script_editor.shooting_for_marketing_platforms")}`,
    icon: `ti ti-send`,
    description: `${I18n.t("views.manage_flows.script_editor.shooting_for_marketing_platforms_description")}`,
  };

  const cardFooter = {
    title: `${I18n.t("views.manage_flows.script_editor.integrate_with_your_marketing_platforms")}`,
    description: `${I18n.t("views.manage_flows.script_editor.integrate_with_your_marketing_platforms_description")}`,
    secondDescription: `${I18n.t("views.manage_flows.script_editor.integrate_with_your_marketing_platforms_description_second")}`,
  };

  return (
    <>
      <ScriptCardBlock
        title={cardBody.title}
        icon={cardBody.icon}
        description={cardBody.description}
      >
        <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
          <div>
            <label className="form-label">
              {I18n.t("views.manage_flows.script_editor.conversion_events")}
              <HelpPopover
                className="ms-1"
                content={I18n.t("views.manage_flows.script_editor.conversion_events_description_html")}
              />
            </label>

            <div>
              <label className="form-check form-check-inline form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={enableGoogleEvents}
                  disabled={!hasPermission}
                  onChange={(event) => {
                    if (!hasPermission) return;
                    setEnableGoogleEvents(event.target.checked)}
                  }
                />
                <span className="form-check-label">
                  <img src={gaLogoImg} height={33} />
                  <a
                    href={I18n.t("shared.helpscout.ga4_trigger_events")}
                    className='form-text d-block'
                    target="_blank"
                  >
                    {I18n.t("views.manage_flows.script_editor.how_to_set_up_events_text")}
                  </a>
                </span>
              </label>
            </div>

            <div className="row g-3">
              <div className="col-md-4">
                <label className="form-label">{I18n.t("views.manage_flows.script_editor.category")}</label>

                <input
                  type="text"
                  className="form-control"
                  value={googleEventsData[0]}
                  disabled={!enableGoogleEvents}
                  onChange={(event) => {
                    if (!hasPermission) return;
                    googleEventsData[0] = event.target.value.trim();
                    setGoogleEventsData([...googleEventsData]);
                  }}
                />
              </div>

              <div className="col-md-4">
                <label className="form-label">{I18n.t("views.manage_flows.script_editor.marketing_events_block.event")}</label>

                <input
                  type="text"
                  className="form-control"
                  value={googleEventsData[1]}
                  disabled={!enableGoogleEvents}
                  onChange={(event) => {
                    if (!hasPermission) return;
                    googleEventsData[1] = event.target.value.trim();
                    setGoogleEventsData([...googleEventsData]);
                  }}
                />
              </div>

              <div className="col-md-4">
                <label className="form-label">{I18n.t("views.manage_flows.script_editor.tag_id")}</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="G-1ABCDE34FG"
                  value={googleEventsData[2]}
                  disabled={!enableGoogleEvents}
                  onChange={(event) => {
                    if (!hasPermission) return;
                    googleEventsData[2] = event.target.value.trim();
                    setGoogleEventsData([...googleEventsData]);
                  }}
                />
              </div>
            </div>
          </div>
        </PermissionsGate>

        <hr className="my-3" />

        <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
          <div>
            <div>
              <label className="form-check form-check-inline form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={enableFacebookEvents}
                  disabled={!hasPermission}
                  onChange={(event) =>{
                    if (!hasPermission) return;
                    setenableFacebookEvents(event.target.checked)
                  }}
                />
                <span className="form-check-label">
                  <img src={metaLogoImg} height={20} />

                  <a
                    href={I18n.t("shared.helpscout.ga4_trigger_events")}
                    className='form-text d-block'
                    target="_blank"
                  >
                    {I18n.t("views.manage_flows.script_editor.how_to_set_up_events_text")}
                  </a>
                </span>
              </label>
            </div>

            <div>
              <label className="form-label">
                {I18n.t("views.manage_flows.script_editor.pixel_code")}
                <a
                  className="form-help ms-2"
                  href={I18n.t("shared.helpscout.ga4_trigger_events")}
                  target="_blank"
                  data-bs-toggle="tooltip"
                  title={I18n.t("shared.actions.click_to_learn_more")}
                >
                  <i className="fa fa-info"></i>
                </a>
              </label>

              <input
                type="text"
                className="form-control"
                value={facebookEventsData[1]}
                disabled={!enableFacebookEvents}
                onChange={(event) => {
                  if (!hasPermission) return;
                  facebookEventsData[1] = event.target.value.trim()
                  setFacebookEventsData([...facebookEventsData])
                }}
              />
            </div>

            <br />

            <div>
              <label className="form-label"> {I18n.t("views.manage_flows.script_editor.event")}</label>

              <input
                type="text"
                className="form-control"
                value={facebookEventsData[0]}
                disabled={!enableFacebookEvents}
                onChange={(event) => {
                  if (!hasPermission) return;
                  facebookEventsData[0] = event.target.value.trim()
                  setFacebookEventsData([...facebookEventsData])
                }}
              />
            </div>

          </div>
        </PermissionsGate>
      </ScriptCardBlock>

      <ScriptCardFooter title={cardFooter.title}>
        <p className="mb-2 tracking-wide">{parse(cardFooter.description)}</p>
        <p className="mb-0 tracking-wide">{parse(cardFooter.secondDescription)}</p>
      </ScriptCardFooter>
    </>
  );
};

export default MarketingEvents;
