import CustomTabs from "@/components/shared/CustomTabs";
import EventsTable from "../EventsTable";

const IntegrationTabs = ({
  integrations,
  activeSlug,
  handleTabChange,
  isLoading,
  events,
  isEventRemoved,
  toggleEvent,
  saving
}) => {
  const getFormattedSlugName = (slug) => {
    return slug
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getIconForSlug = (slug) => {
    const icons = {
      "facebook_ads": "https://cdn.leadster.com.br/leadster/images/dashboard/ads_icons/logo_meta.webp",
      "google_analytics_4": "https://cdn.leadster.com.br/leadster/images/dashboard/ads_icons/logo_analytics.webp",
      "google_adwords": "https://cdn.leadster.com.br/leadster/images/dashboard/ads_icons/logo_google_ads.webp"
    };

    return icons[slug] || "ti ti-chart-pie";
  };

  const generateTabsConfig = () => {
    return integrations.map((integration) => ({
      eventKey: integration.slug,
      title: getFormattedSlugName(integration.slug),
      icon: getIconForSlug(integration.slug),
      content: (
        <EventsTable
          slug={integration.slug}
          isLoading={isLoading[integration.slug]}
          events={events[integration.slug] || []}
          isEventRemoved={isEventRemoved}
          toggleEvent={toggleEvent}
          saving={saving}
        />
      )
    }));
  };

  return (
    <div className="w-100">
      <CustomTabs
        tabsConfig={generateTabsConfig()}
        defaultActiveKey={activeSlug}
        tabParamName="integration"
        onSelect={handleTabChange}
      />
    </div>
  );
};

export default IntegrationTabs;
