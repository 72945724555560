import { useState } from "react";
import { Button, Card, Form, Image, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAssistantContext } from "@/contexts/AssistantContext";
import { classBinding } from "@/utils/helpers";
import axios from "@/lib/axios";
import Alert from "../Alert";
import ContentUsageModal from "../ContentUsageModal";

const AddSingleUrl = ({
  type,
  titleKey,
  descriptionKey,
  inputLabel,
  imageSrc,
  isSelected,
  onSelect,
  isSelectionDisabled,
}) => {
  const {
    addPendingBase,
    assistant,
    updateAssistant,
    setProcessingAddContentType,
    processingAddContentType,
    setDisabledContent,
  } = useAssistantContext();

  const {
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showUsageModal, setShowUsageModal] = useState(false);
  const [contentValue, setContentValue] = useState("");
  const [tempData, setTempData] = useState(null);

  const pendingBase = assistant.pending_knowledge_base;
  const isDisabled = !!pendingBase || isLoading || isSelectionDisabled;
  const isInvalid = errors.hasOwnProperty("url");
  const typeOfAssistant = assistant.type_of_assistant;

  const handleSingleUrl = (data, usageChoice) => {
    const payload = {
      ...data,
      ai_assistant_id: assistant.id,
      base_type: "url",
      usage: usageChoice,
    };

    updateAssistant({
      status_alert: "searching_pages",
      path: data.url,
      has_multiple_url_process_data: false,
      processing_type: type,
    });

    setProcessingAddContentType(type);

    setDisabledContent(true);

    axios
      .post("/ai/knowledge_bases", payload)
      .then(({ data }) => {
        addPendingBase(data.knowledge_base);
        reset();
      })
      .catch((err) => {
        setProcessingAddContentType(null);
        setDisabledContent(false);

        if (_.get(err, "response.status") === 422) {
          const responseErrors = _.get(err.response, "data.errors", {});
          reset();

          Object.entries(responseErrors).forEach(([fieldName, [firstErrorMessage]]) => {
            setError(fieldName, { type: "custom", message: firstErrorMessage });
          });

          toastr.error(I18n.t("request_feedback_alert.validation_error.message"));
          return;
        }

        toastr.error(I18n.t("request_feedback_alert.error.message"));
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = (data) => {
    if (isDisabled) return;
    setIsLoading(true);
    MixpanelService.track("leadster_ai_add_url");

    if (typeOfAssistant === "e_commerce") {
      setShowUsageModal(true);
      setContentValue(data.url);
      setTempData(data);
    } else {
      handleSingleUrl(data, "generic");
    }
  };

  const handleUsageConfirm = (usageChoice) => {
    setShowUsageModal(false);
    if (tempData) {
      handleSingleUrl(tempData, usageChoice);
      setTempData(null);
    }
  };

  const handleClick = () => {
    if (!isDisabled) {
      onSelect(type);
    }
  };

  const handleHideUsageModal = () => {
    setShowUsageModal(false);
    setTempData(null);
    setContentValue("");
    window.location.reload();
  };

  const showProcessingAlert = (type) => {
    if (processingAddContentType === type) {
      return <Alert onCancel={() => window.location.reload()} />;
    }

    return null;
  };

  return (
    <Card
      className={`mb-3 border-2 ${isSelectionDisabled ? "bg-light text-muted opacity-50" : ""}`}
      border={isSelected ? "primary" : "light"}
      onClick={handleClick}
      style={{ cursor: isSelectionDisabled ? "not-allowed" : "pointer" }}
    >
      <Card.Body className="p-0">
        <div className="d-flex align-items-center mb-3 p-3">
          <Form.Check
            type="radio"
            name={`add-url-type-${type}`}
            id={`add-${type}-radio`}
            checked={isSelected}
            onChange={() => !isDisabled && onSelect(type)}
            className="me-3"
            disabled={isDisabled}
          />
          <div className="d-flex align-items-center flex-grow-1">
            {imageSrc && (
              <div className="d-flex justify-content-center align-items-center me-3 bg-primary-lt p-2 rounded">
                <Image src={imageSrc} alt={`${type} icon`} width={20} height={20} />
              </div>
            )}
            <div>
              <p className="mb-1 fw-bold fs-4">{I18n.t(titleKey)}</p>
              <p className="mb-0 fs-4">{I18n.t(descriptionKey)}</p>
            </div>
          </div>
        </div>

        {isSelected && (
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-3 p-3 border-top">
            {showProcessingAlert(type)}
            <Form.Label className="mb-2 pt-3 fw-bold fs-4">{I18n.t(inputLabel)}</Form.Label>
            <InputGroup className={classBinding({ "is-invalid": isInvalid })}>
              <Form.Control
                type="text"
                isInvalid={isInvalid}
                placeholder={I18n.t("views.leadster_ai.page.add_content.add_links.placeholder")}
                disabled={isDisabled}
                {...register("url", { required: true })}
                className={`form-control ${isSelected ? "border-primary border-2" : ""}`}
              />
              <Button variant="primary" type="submit" disabled={isDisabled}>
                <i className="icon ti ti-world-search me-2" />
                {I18n.t("views.leadster_ai.page.add_content.add_links.add_page")}
              </Button>
            </InputGroup>

            <Form.Control.Feedback type="invalid">{_.get(errors, "url.message")}</Form.Control.Feedback>
          </Form>
        )}
      </Card.Body>

      <ContentUsageModal
        show={showUsageModal}
        onHide={handleHideUsageModal}
        onConfirm={handleUsageConfirm}
        contentValue={contentValue}
      />
    </Card>
  );
};

export default AddSingleUrl;
