import { useEffect, useState } from "react";
import axios from "@/lib/axios";

const useGenerateCallsAi = (url, autoFetch = false) => {
  const [callsList, setCallsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCalls = async () => {
    if (!url) return;

    setIsLoading(true);

    try {
      const { data } = await axios.get("/ai/conversion/calls", {
        params: { url }
      });
      setCallsList(data.calls);
      toastr.success(I18n.t("views.dashboard.page_rank.create_flow_modal.calls_generated_successfully"))
      return data;
    } catch (error) {
      const errorMessage = _.get(error.response,
        "data.errors.message_html",
        I18n.t("shared.messages.request_feedback_alert.error.message")
      );
      setError(errorMessage);
      toastr.error(I18n.t("request_feedback_alert.error.message"))
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (autoFetch && url) {
      fetchCalls();
    }
  }, [url, autoFetch]);

  return {
    callsList,
    isLoading,
    error,
    fetchCalls
  };
};

export default useGenerateCallsAi;
