import styled from "styled-components";

export const PageLink = styled.a.attrs({
  className: "fw-medium",
})`
  max-width: calc(var(--tblr-modal-width) - 70px);
  overflow-wrap: break-word;
  font-size: 0.9rem;
  display: inline-block;
`;

export const TopBorder = styled.div.attrs(({ color }) => ({
  className: `position-absolute top-0 start-0 end-0 bg-${color || 'primary'}`,
}))`
  height: 8px;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
`;
