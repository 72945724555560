import { useState } from "react";

import { Button } from "react-bootstrap";

const ButtonExport = ({Modal, disable = false}) => {

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);

  return(
    <>
      <Button variant="primary" onClick={handleOpenModal} disabled={disable}>
        <i className="ti ti-table-export me-1"/>
        {I18n.t("views.leadster_ai.export.modal.button_label")}
      </Button>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
};

export default ButtonExport;
