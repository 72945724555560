import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import PermissionsGate from "@/components/permissions/PermissionsGate";

import { can } from "@/lib/user-permission";

import axios from "@/lib/axios";
import swal from "sweetalert";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'dashboard_config'
});

const I18N_SCOPE = "views.users.edit.company_form.ads_options";

const ReporteiSwitchAvailability = ({ slug, label }) => {
  const hasPermission = can(PERMISSION_SCOPE);
  const [integrationData, setIntegrationData] = useState(null);
  const timerRef = useRef(null);
  const popupRef = useRef(null);

  const getErrorMsg = (error) => {
    return error.response?.data?.error
      ? `${I18n.t(`${I18N_SCOPE}.modal.error_messages.error_integrating`)}: ${error.response.data.error}`
      : I18n.t(`${I18N_SCOPE}.modal.error_messages.unexpected_error`);
  };

  const fetchIntegrations = async () => {
    try {
      const response = await axios.get("/reportei_integration");
      setIntegrationData(response.data);
    } catch (error) {
      swal("", getErrorMsg(error), "error");
    }
  };

  const isIntegrationActive = () =>
    integrationData?.success?.some((item) => item.slug === slug) || false;

  useEffect(() => {
    fetchIntegrations();
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      window.removeEventListener("focus", handleFocusAfterPopupClose);
    };
  }, []);

  const handleFocusAfterPopupClose = async () => {
    await fetchIntegrations();
  };

  const checkPopupClosed = () => {
    const intervalId = setInterval(() => {
      if (popupRef.current?.closed) {
        clearInterval(intervalId);
        if (document.hasFocus()) {
          handleFocusAfterPopupClose();
        } else {
          window.addEventListener("focus", handleFocusAfterPopupClose, { once: true });
        }
      }
    }, 500);
    timerRef.current = intervalId;
  };

  const handleIntegrate = async () => {
    try {
      const { data } = await axios.post("/reportei_integration");

      if (data.session_link) {
        const popupWidth = 1200;
        const popupHeight = 800;

        const dualScreenLeft = window.screenLeft ?? window.screenX;
        const dualScreenTop = window.screenTop ?? window.screenY;

        const width = window.innerWidth || document.documentElement.clientWidth || screen.width;
        const height = window.innerHeight || document.documentElement.clientHeight || screen.height;

        const left = width / 2 - popupWidth / 2 + dualScreenLeft;
        const top = height / 2 - popupHeight / 2 + dualScreenTop;

        const popup = window.open(
          data.session_link,
          "ReporteiIntegration",
          `scrollbars=yes, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`
        );

        if (!popup) {
          swal("", I18n.t(`${I18N_SCOPE}.modal.error_messages.error_popup_blocked`), "error");
          return;
        }

        popupRef.current = popup;
        popup.focus?.();
        checkPopupClosed();
      } else {
        swal("", I18n.t(`${I18N_SCOPE}.modal.error_messages.invalid_session_link`), "error");
      }
    } catch (error) {
      swal("", getErrorMsg(error), "error");
    }
  };

  const handleToggle = async () => {
    if (!hasPermission) return;

    if (isIntegrationActive()) {
      const matchingIntegration = integrationData.success.find((item) => item.slug === slug);
      try {
        await axios.delete(`/reportei_integration/${matchingIntegration.uuid}`);
        await fetchIntegrations();
      } catch (error) {
        swal(I18n.t(`${I18N_SCOPE}.modal.error_messages.title`), I18n.t(`${I18N_SCOPE}.modal.error_messages.default_message`), "success");
      }
    } else {
      try {
        await handleIntegrate();
      } catch (error) {
        swal(I18n.t(`${I18N_SCOPE}.modal.error_messages.title`), I18n.t(`${I18N_SCOPE}.modal.error_messages.error_integrating`), "success");
      }
    }
  };

  return (
    <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
      <Form.Check
        type="switch"
        id={`switch-${slug}`}
        label={label}
        checked={isIntegrationActive()}
        onChange={handleToggle}
      />
    </PermissionsGate>

  );
};

ReporteiSwitchAvailability.propTypes = {
  slug: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ReporteiSwitchAvailability;
